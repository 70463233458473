<template>
  <div id="app">
    <div class="page-wrapper">
      <div class="page-inner">
        <Sidebar />
        <div class="page-content-wrapper">
          <Header />
          <main class="page-content">
            <div>
              <div class="subheader">
                <h1 class="display-4">Создание отчёта</h1>
              </div>
              <div class="form-group mt-5">
                <h3 class="mt-5">
                  Выберите период за который необходимо создать отчёт
                </h3>
                <date-picker
                  v-model="report_period"
                  format="DD-MM-YYYY"
                  type="date"
                  range
                  :disabled-date="disabledAfterToday"
                  :lang="lang"
                  valueType="format"
                ></date-picker>
              </div>
              <div class="form-group mt-5">
                <h3 class="mt-5">Выберите тип отчёта</h3>
                <multiselect
                  v-model="reportType"
                  track-by="value"
                  label="name"
                  :options="reportTypes"
                  :show-labels="false"
                  :searchable="false"
                  :allow-empty="false"
                  placeholder="Выберите тип отчёта"
                  @input="selectType"
                >
                  <template slot-scope="{ option }">{{ option }} </template>
                </multiselect>
              </div>
              <div v-if="!hideProductSelect" class="form-group mt-5">
                <h3 class="mt-5">Быстрый поиск по каталогу продуктов</h3>
                <multiselect
                  v-model="product"
                  track-by="name"
                  label="name"
                  :options="products"
                  :show-labels="false"
                  :searchable="true"
                  :allow-empty="true"
                  :loading="isLoading"
                  placeholder="Введите наименование продукта"
                  @search-change="searchProduct"
                  @select="selectProduct"
                >
                  <template slot-scope="{ option }">{{ option }} </template>
                  <span slot="noResult"
                    >По вашему запросу ничего не найдено.</span
                  >
                </multiselect>
              </div>
              <h3 v-if="!hideProductSelect" class="mt-5">Каталог продуктов</h3>
              <div v-if="!hideProductSelect" class="d-flex">
                <div class="col-4">
                  <div>
                    <vue-tree-list
                      @click="onClick"
                      :model="data"
                      v-bind:default-expanded="false"
                    >
                      <template v-slot:leafNameDisplay="slotProps">
                        <span
                          :class="
                            slotProps.model.id === activeCategory
                              ? 'activeCategory'
                              : ''
                          "
                        >
                          {{ slotProps.model.name }}
                        </span>
                      </template>
                    </vue-tree-list>
                  </div>
                </div>
                <div class="col-8">
                  <table
                    v-if="showTable"
                    id="dt-basic-example"
                    class="table table-bordered table-hover table-striped w-100 dataTable dtr-inline"
                    role="grid"
                    aria-describedby="dt-basic-example_info"
                  >
                    <thead class="bg-primary-600">
                      <tr role="row">
                        <th class="sorting_asc">ID #</th>
                        <th class="sorting_asc">Наименование</th>
                        <th class="sorting_asc">Управление</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="odd"
                        v-for="(item, index) in productsList"
                        :key="index"
                      >
                        <td class="align-middle">{{ item.id }}</td>
                        <td class="align-middle">{{ item.name }}</td>
                        <td class="point align-middle">
                          <button
                            type="button"
                            @click="selectProduct(item)"
                            class="btn btn-sm btn-outline-success waves-effect waves-themed"
                          >
                            <span class="fal fa-plus mr-1"></span>
                            Выбрать продукт
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div
                    v-if="showLoader"
                    class="fixed d-flex justify-content-center"
                  >
                    <button
                      class="btn btn-info waves-effect waves-themed"
                      type="button"
                      disabled=""
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Загрузка...
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="showDownloadButton" class="mt-5 text-center">
                <p class="mb-3">
                  {{ downloadText }}
                  <span class="fw-500">{{
                    '"' + activeCategoryName + activeProductName + '"'
                  }}</span>
                  за период с {{ report_period[0] }} по {{ report_period[1] }}
                </p>
                <button
                  type="button"
                  @click="createReport"
                  class="btn btn-sm btn-outline-primary waves-effect waves-themed ml-1"
                >
                  <span class="fal fa-file-spreadsheet mr-1"></span>
                  Создать отчёт
                </button>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
    <Modals />
    <RejectFastForm />
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import { VueTreeList, Tree } from "vue-tree-list";
import Sidebar from "@/components/Common/Sidebar";
import Header from "@/components/Common/Header";
import Modals from "@/components/Common/Modals";
import RejectFastForm from "@/components/Common/Modals/RejectFastForm";
import DatePicker from "vue2-datepicker";
//import dateFormat from "dateformat"

import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";
import fileDownload from "js-file-download";

export default {
  name: "Reports",
  components: {
    Multiselect,
    VueTreeList,
    Sidebar,
    Header,
    Modals,
    RejectFastForm,
    DatePicker,
  },
  data() {
    return {
      request: [],
      isLoading: false,
      showLoader: false,
      showTable: false,
      searchName: null,
      product: null,
      products: [],
      categoryList: {},
      productsList: {},
      /*newTree: {},*/
      data: new Tree([]),
      activeCategory: null,
      currentNodeParent: null,
      activeProductId: null,
      activeProductName: "",
      activeCategoryName: "",
      downloadText: "",
      showDownloadButton: false,
      hideProductSelect: false,
      report_period: ['',''],
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      reportType: {
        name: "Стандартный",
        value: "standart",
      },
      reportTypes: [
        {
          name: "Стандартный",
          value: "standart",
        },
        {
          name: "По быстрым заказам",
          value: "fast",
        },
      ],
    };
  },
  methods: {
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    searchProduct(text) {
      this.isLoading = true;
      if (text.length > 3) {
        axios
          .post(`api/auth/orders/product/search/`, {
            text: text,
          })
          .then((res) => {
            this.isLoading = false;
            console.log(res.data);
            this.products = res.data;
          });
      }
    },
    selectProduct(product) {
      this.activeProductId = product.id;
      this.activeProductName = product.name;
      this.activeCategoryName = "";
      this.downloadText = "Будет создан отчёт по продукту ";
      this.showDownloadButton = true;
    },
    onClick(params) {
      this.getCategoryProducts(params.id);
      this.activeCategory = params.id;
      this.activeCategoryName = params.name;
      this.activeProductName = "";
      this.downloadText = "Будет создан отчёт по категории ";
      this.showDownloadButton = true;
    },
    selectType() {
      if (this.reportType.value == "fast") {
        this.downloadText = "Будет создан отчёт по ";
        this.activeCategoryName = "быстрым заказам";
        this.hideProductSelect = true;
        this.showDownloadButton = true;
        this.activeCategory = null;
      } else {
        this.hideProductSelect = false;
        this.showDownloadButton = false;
      }
    },
    getCategoryProducts(id) {
      this.showLoader = true;
      this.showTable = false;
      axios.get(`api/auth/product/published/${id}`).then((res) => {
        if (res.status == 200) {
          this.productsList = res.data;
          console.log(res.data);
        } else {
          console.log(res);
        }
        this.showLoader = false;
        this.showTable = true;
      });
    },
    getCategoriesList() {
      axios.get("api/auth/category").then((res) => {
        if (res.status == 200) {
          this.categoryList = res.data;
          this.recursionSort(res.data);
          this.data = new Tree(res.data);
        } else {
          console.log(res);
        }
      });
    },
    recursionSort(obj) {
      obj.sort(this.compareValues("name"));
      for (let key in obj) {
        obj[key].dragDisabled = true;
        obj[key].addTreeNodeDisabled = true;
        obj[key].addLeafNodeDisabled = true;
        obj[key].editNodeDisabled = true;
        obj[key].delNodeDisabled = true;
        if (obj[key].children.length) {
          this.recursionSort(obj[key].children);
        }
      }
    },
    compareValues(key, order = "asc") {
      return function innerSort(a, b) {
        // eslint-disable-next-line no-prototype-builtins
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0;
        }

        const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return order === "desc" ? comparison * -1 : comparison;
      };
    },
    // getReport() {
    //   console.log(this.report_period[0], this.report_period[1], this.activeCategoryName, this.activeCategory, this.activeProductId);
    // },
    createReport() {
      let rpt = {
        type: this.reportType.value,
        date_start: this.report_period[0],
        date_end: this.report_period[1],
        category: Number.parseInt(this.activeCategory) ? Number.parseInt(this.activeCategory) : 0,
        product: Number.parseInt(this.activeProductId) ? Number.parseInt(this.activeProductId) : 0,
      }
      console.log(rpt);
      axios.get('api/auth/statistic/report/' + rpt.type + '/' + rpt.category + '/' + rpt.product + '/' + rpt.date_start + '/' + rpt.date_end, {
        responseType: 'blob',
        headers: {
          Accept: 'application/msexcel'
        }
      })
      .then(res => {
        fileDownload(
            res.data,
            "report.xls"
        );
      })
    },
  },
  mounted() {
    this.getCategoriesList();
  },
};
</script>

<style lang="less" rel="stylesheet/less">
.vtl {
  font-size: 16px;

  .vtl-icon {
    margin-right: 8px;
    cursor: pointer;
  }

  .vtl-icon-plus {
    display: none;
  }
  .vtl-node-content {
    cursor: pointer;
  }
}

.activeCategory {
  color: #2198f3;
}
</style>
