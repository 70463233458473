<template>
  <div id="test">
    <div
      v-if="showWorkspaceModal"
      class="modal fade show d-block"
      id="chooseCurrentWorkspace"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Выберите подразделение в котором собираетесь работать
            </h4>
          </div>
          <div class="modal-body">
            <multiselect
              v-model="division"
              track-by="name"
              label="name"
              @input="selectDivision"
              :options="divisions"
              :show-labels="false"
              :searchable="true"
              :allow-empty="false"
              placeholder=""
            >
              <template slot-scope="{ option }">{{ option }}</template>
            </multiselect>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" v-if="showBackdrop"></div>
    <div class="shortcut-menu">
      <a
        href="#"
        v-scroll-to="{
          el: '#app',
          duration: -100,
          easing: 'linear',
        }"
        class="menu-item btn waves-effect waves-themed"
        title="Наверх"
      >
        <i class="fal fa-arrow-up"></i>
      </a>
    </div>
    <div
      class="modal modal-alert fade show d-block"
      id="example-modal-alert"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      v-if="showBurningModal"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <i class="fal fa-bolt mr-3"></i> Поступил новый горящий заказ
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              @click="closeBurningModal"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body"></div>
          <div class="modal-footer">
            <button
            @click="toBurningList"
              type="button"
              class="btn btn-primary waves-effect waves-themed"
            >
              Перейти к горящим заказам
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade show d-block pr-15"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      v-if="showRejectedModal"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              Появился новый отклоненный заказ
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="closeRejectedModal"
            >
              <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body"></div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary waves-effect waves-themed"
              @click="closeRejectedModal"
            >
              Ок
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import main from "../../store/index";
import router from "@/router";
export default {
  name: "Modals",
  components: {
    Multiselect,
  },
  data() {
    return {
      division: {},
      divisions: [],
      showBackdrop: false,
      showWorkspaceModal: false,
      showBurningModal: false,
      showRejectedModal: false
    };
  },
  methods: {
    selectDivision() {
      console.log(this.division);
      localStorage.setItem("user_division", JSON.stringify(this.division));
      main.state.workspace = this.division;
      this.showBackdrop = false;
      this.showWorkspaceModal = false;
    },
    closeBurningModal() {
      this.showBackdrop = false;
      this.showBurningModal = false;
    },
    closeRejectedModal() {
      this.showBackdrop = false;
      this.showRejectedModal = false;
    },
    toBurningList(){
      this.showBackdrop = false;
      this.showBurningModal = false;
      if(this.$router.currentRoute.name !== 'orderList'){
        router.push({name: 'orderList', params: {activeTab: 'burning'}})
      }else{
        main.state.push.orderListTab = ''
        main.state.push.orderListTab = 'burning'
      }
    },
     sendNotification(title, options) {
      // Проверим, поддерживает ли браузер HTML5 Notifications
      if (!("Notification" in window)) {
        alert('Ваш браузер не поддерживает HTML Notifications, его необходимо обновить.');
      }

      // Проверим, есть ли права на отправку уведомлений
      else if (Notification.permission === "granted") {
        // Если права есть, отправим уведомление
        let notification = new Notification(title, options);

        notification.onclick = () => {console.log('Пользователь кликнул на уведомление');};
      }

      // Если прав нет, пытаемся их получить
      else if (Notification.permission !== 'denied') {
        Notification.requestPermission(function (permission) {
        // Если права успешно получены, отправляем уведомление
        if (permission === "granted") {
          new Notification(title, options);

        } else {
          alert('Вы запретили показывать уведомления'); // Юзер отклонил наш запрос на показ уведомлений
        }
        });
      } else {
        Notification.requestPermission(function (permission) {
          if (permission === "granted") {
            new Notification(title, options);
          }
        })
      }
    },
  },
  computed: {
    getBurningPush() {
      return main.state.push.burningId
    },
    getRejectedPush() {
      return main.state.push.rejectedId
    }
  },
  watch:{
    getBurningPush(push){
      let currentDivision = JSON.parse(localStorage.getItem("user_division"));
      if (currentDivision.id == main.state.push.division_id) {
        this.showBackdrop = true;
        this.showBurningModal = true;
        this.sendNotification('Новый горящий заказ!', {
          body: 'Поступил новый горящий заказ! Номер: ' + main.state.push.orderNumber,
          icon: '/favicon.ico',
          dir: 'auto',
          requireInteraction : true
        });
        console.log(push);
      }
    },
    getRejectedPush(push){
      const currentDivision = JSON.parse(localStorage.getItem("user_division"));
      console.log(currentDivision.id)
      if (currentDivision.id === 51) {
        this.showRejectedModal = true;
        this.showBackdrop = true;
        this.sendNotification('Горящий заказ был отклонён!', {
          body: 'Ранее созданный горящий заказ был отклонён! Номер: ' + main.state.push.orderNumber,
          icon: '/favicon.ico',
          dir: 'auto',
          requireInteraction : true
        });
      }

      console.log(push);
    }
  },
  mounted() {
    axios.get("api/auth/divisions").then((res) => {
      this.divisions = res.data;
    });
    let currentDivision = localStorage.getItem("user_division");
    if (!currentDivision) {
      this.showBackdrop = true;
      this.showWorkspaceModal = true;
    }
    // console.log(localStorage.getItem('user_division'))
  },
};
</script>
<style lang="scss" rel="stylesheet/scss">
.shortcut-menu {
  bottom: 8rem;
}
</style>
