<template>
    <div>
        <h1 class="display-4 mb-5">Каталог
                продуктов</h1>
        <div class="panel-container show">
            <div class="d-flex">
                <div class="col-4">
                    <div>
                        <button @click="addNode" type="button"
                                class="btn btn-lg btn-primary waves-effect waves-themed mb-5">
                            <span class="fal fa-plus mr-1"></span>
                            Создать категорию
                        </button>
                        <vue-tree-list
                                @click="onClick"
                                @change-name="onChangeName"
                                @delete-node="onDel"
                                @add-node="onAddNode"
                                @drop="dropNode"
                                @drop-before="dropNode"
                                @drop-after="dropNode"
                                :model="data"
                                default-tree-node-name="Новая категория"
                                default-leaf-node-name="new leaf"
                                v-bind:default-expanded="false"
                        >
                            <template v-slot:leafNameDisplay="slotProps">
                                <span :class="slotProps.model.id === activeCategory ? 'activeCategory' : '' ">
                                  {{ slotProps.model.name }}
                                </span>
                            </template>
                            <template v-slot:addTreeNodeIcon="">
                                <span title="Создать подкатегорию">
                                    <i class="vtl-icon vtl-icon-folder-plus-e"></i>
                                </span>
                            </template>
                            <template v-slot:editNodeIcon="">
                                <span title="Редактировать категорию">
                                    <i class="vtl-icon vtl-icon-edit"></i>
                                </span>
                            </template>
                            <template v-slot:delNodeIcon="">
                                 <span title="Удалить категорию">
                                    <i class="vtl-icon vtl-icon-trash"></i>
                                </span>
                            </template>

                        </vue-tree-list>
                        <!--<button @click="getNewTree">Get new tree</button>-->
                        <pre>
      <!--{{newTree}}-->
    </pre>
                    </div>
                </div>
                <div class="col-8">
                    <table id="dt-basic-example"
                           class="table table-bordered table-hover table-striped w-100 dataTable dtr-inline" role="grid"
                           aria-describedby="dt-basic-example_info" style="width: 1475px;">
                        <thead class="bg-primary-600">
                        <tr role="row">
                            <th class="sorting_asc">ID #</th>
                            <th class="sorting_asc">Наименование</th>
                            <th class="sorting_asc">Статус</th>
                            <th class="sorting_asc">Управление</th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr class="odd" v-for="(item, index) in productsList" :key="index">
                            <td class="align-middle">{{item.id}}</td>
                            <td class="align-middle">{{item.name}}</td>
                            <td class="align-middle" :class="item.published ? '' : 'color-warning-300'">{{item.published
                                ? 'Опубликован' : 'Черновик'}}
                            </td>
                            <td class="point align-middle">
                                <button title="Редактировать продукт" type="button"
                                        @click.prevent="editProduct(item.id)"
                                        class="btn  btn-md btn-icon waves-effect waves-themed mr-3">
                                    <span aria-hidden="true"><i
                                            class="fal fa-edit"></i></span>
                                </button>
                                <button title="Копировать продукт" type="button" @click.prevent="copyProduct(item.id)"
                                        class="btn  btn-md btn-icon waves-effect waves-themed mr-3">
                                    <span aria-hidden="true"><i
                                            class="fal fa-copy"></i></span>
                                </button>
                                <button title="Удалить продукт" type="button" @click.prevent="deleteProduct(item.id)"
                                        class="btn  btn-md btn-icon waves-effect waves-themed">
                                    <span aria-hidden="true"><i class="fal fa-trash-alt"></i></span>
                                </button>

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
    import axios from 'axios'
    import {VueTreeList, Tree} from 'vue-tree-list'
    import router from "../../router";

    export default {
        name: "CatalogProducts",
        data() {
            return {
                categoryList: {},
                productsList: {},
                /*newTree: {},*/
                data: new Tree([]),
                activeCategory: null,
                currentNodeParent: null
            }
        },
        components: {
            VueTreeList
        },
        methods: {
            getCategoriesList() {
                axios.get('api/auth/category')
                    .then(res => {
                        if (res.status == 200) {
                            this.categoryList = res.data
                            this.recursionSort(res.data);
                            this.data = new Tree(res.data)
                        } else {
                            console.log(res);
                        }
                    })
            },
            getCategoryProducts(id) {
                axios.get(`api/auth/category-products/${id}`)
                    .then(res => {
                        if (res.status == 200) {
                            this.productsList = res.data
                        } else {
                            console.log(res);
                        }
                    })
            },
            deleteProduct(id) {
                if (confirm("Удалить продукт?")) {
                    axios.delete(`api/auth/product/${id}`)
                        .then(() => {
                            this.getCategoryProducts(this.activeCategory)
                        })
                        .catch(error => {
                            console.log(error.response.data)
                        })
                } else {
                    return false
                }
            },
            copyProduct(id) {
                    axios.post(`api/auth/product/copy/${id}`)
                        .then(() => {
                            this.getCategoryProducts(this.activeCategory)
                        })
                        .catch(error => {
                            console.log(error.response.data)
                        })
            },
            editProduct(id) {
                router.replace(`/products/product/${id}`)
            },
            dropNode(result) {
                console.log('drop',result);
                if (result.node.parent.id === 0) {
                    result.node.parent.id = null
                }
                axios.put(`api/auth/category/${result.node.id}`, {
                    category_id: result.node.parent.id,
                    name: result.node.name
                })
                    .then(() => {
                        this.getCategoriesList()
                    })
            },
            onDel(node) {
                if (confirm("Удалить категорию?")) {
                    axios.delete(`api/auth/category/${node.id}`)
                        .then(() => {
                            this.getCategoriesList()
                            node.remove()
                        })
                } else {
                    return false
                }

            },
            onChangeName(result) {
                if (result.eventType && result.eventType === 'blur') {
                    axios.put(`api/auth/category/${result.id}`, {
                        category_id: this.currentNodeParent,
                        name: result.newName
                    })
                } else {
                    if (result.node.parent.id === 0) {
                        this.currentNodeParent = null
                    } else {
                        this.currentNodeParent = result.node.parent.id
                    }
                }
            },

            onAddNode(params) {
                axios.post('api/auth/category/store', {
                    name: 'Новая категория',
                    category_id: params.parent.id
                })
                    .then(res => {
                        if (res.status == 201) {
                            this.getCategoriesList()
                        } else {
                            console.log(res.data);
                        }
                    })
            },

            onClick(params) {
                this.getCategoryProducts(params.id)
                this.activeCategory = params.id
            },

            addNode() {
                axios.post('api/auth/category/store', {
                    name: 'Новая категория',
                    category_id: null
                })
                    .then(res => {
                        if (res.status == 201) {
                            this.getCategoriesList()
                        } else {
                            console.log(res.data);
                        }
                    })
            },
            recursionSort(obj){
                obj.sort(this.compareValues('name'))
                for (let key in obj){
                    if (obj[key].children.length){
                        this.recursionSort(obj[key].children)
                    }
                }
            },
            compareValues(key, order = 'asc') {
                return function innerSort(a, b) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                        // property doesn't exist on either object
                        return 0;
                    }

                    const varA = (typeof a[key] === 'string')
                        ? a[key].toUpperCase() : a[key];
                    const varB = (typeof b[key] === 'string')
                        ? b[key].toUpperCase() : b[key];

                    let comparison = 0;
                    if (varA > varB) {
                        comparison = 1;
                    } else if (varA < varB) {
                        comparison = -1;
                    }
                    return (
                        (order === 'desc') ? (comparison * -1) : comparison
                    );
                };
            }


        },
        mounted() {
            this.getCategoriesList()
        }
    }
</script>

<style lang="less" rel="stylesheet/less">
    .vtl {
        font-size: 16px;

        .vtl-icon {
            margin-right: 8px;
            cursor: pointer;
        }

        .vtl-icon-plus {
            display: none;
        }
    }

    .activeCategory {
        color: #2198F3;
    }
</style>


