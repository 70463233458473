<template>
    <div>
        <h3>Ввведите уведомление</h3>
        <textarea 
            class="form-control" 
            type="text"
            v-model="notification.value"
        >
        </textarea>
        <div
          class="form-group custom-control custom-checkbox custom-control-inline mt-3"
        >
          <input
            name="active"
            id="active"
            v-model="notification.active"
            type="checkbox"
            class="custom-control-input"
          />
          <label
            class="custom-control-label"
            for="active"
          >Активировать уведомление</label>
        </div>
        <div>
            <h3>Цвет уведомления</h3>
            <multiselect
                v-model="notification.color"
                :options="colors"
                :show-labels="false"
                :searchable="true"
                :allow-empty="false"
                track-by="text"
                label="text"
            >
                <template slot="option" slot-scope="props">
                    <div class="d-flex align-items-center">
                        <span :class="`color bg-${props.option.color}-300`"></span>
                        <span>{{ props.option.text }}</span>
                    </div>
                </template>
            </multiselect>
        </div>
        <br />
        <button
            type="button"
            @click="saveNotification"
            class="btn btn-primary waves-effect waves-themed mt-3"
        >
        Сохранить
        </button>
    </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
 
export default {
    name: "Order",
    data() {
        return {
            notification: {
                id: 1,
                value: '',
                active: false,
                color: {text: 'primary', color: 'primary'},
            },
            colors: [
                {text: 'primary', color: 'primary'}, 
                {text: 'secondary', color: 'secondary'}, 
                {text: 'warning', color: 'warning'}, 
                {text: 'info', color: 'info'},
                {text: 'success', color: 'success'}, 
                {text: 'danger', color: 'danger'}
            ]
        }
    },
    components: {
        Multiselect
    },
    methods: {
        saveNotification() {
            console.log(this.notification.value.length)/* 
            if(this.notification.value.length > 75) {
                alert('Слишком большой размер уведомления')
                return false
            } */
            this.notification.color = this.notification.color.text;
            axios.post('api/auth/notification/save', {headerNotification: this.notification}).then(() => {
                alert('Уведомление обновлено')
                this.$router.go()
            }).catch((error) => {
                console.log(error)
            })
            this.notification.color = {text: this.notification.color, color: this.notification.color}
        }
    },
    mounted() {
        axios.get('api/auth/notification/show').then((res) => {
            if(res.data == "") {
                return false
            }
            ({...this.notification} = res.data)
            this.notification.color = {text: this.notification.color, color: this.notification.color}
        })
    },
}
</script>

<style lang="scss" scoped>
    .color {
        border-radius: 2px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
        height: 24px;
        margin-right: 8px;
        width: 24px;
    }
</style>