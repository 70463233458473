import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Home from "./views/Home"
import axios from "axios"
import Vuelidate from "vuelidate/src"
import VueBreadcrumbs from 'vue-breadcrumbs'
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueScrollTo from 'vue-scrollto';
import Pusher from "pusher-js";
import NProgress from 'nprogress';
import '../node_modules/nprogress/nprogress.css'
import middlewarePipeline from '@/router/middlewarePipeline'

Vue.use(VueBreadcrumbs, {
  template: '<ol class="breadcrumb page-breadcrumb"> ' +
      '<router-link class="breadcrumb-item" v-for="(crumb, key) in $breadcrumbs" :to="linkProp(crumb)" v-slot="{ href, route, navigate, isActive, isExactActive }" :key="key"><li class="breadcrumb-item" :class="[isActive && \'active\', isExactActive && \'active\']"><a :href="href" @click="navigate">{{ crumb | crumbText }}</a></li></router-link> ' +
      '</ol>'
});

Vue.use( CKEditor )
Vue.use(Vuelidate)
Vue.use(VueScrollTo, {
  container: "body",
  duration: 200,
  easing: "linear",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})


Vue.config.productionTip = false

//Vue.config.errorHandler = function(err, vm, info) {
//  console.log(`Error: ${err.toString()}\nInfo: ${info}`);
//}
//Pusher.logToConsole = true

const pusher = new Pusher('777d61ba29218ec8a92b', {
  cluster: 'eu'
})

const channel = pusher.subscribe('my-channel')
// console.log(channel)
channel.bind('my-event', function(data) {
  console.log(data);
  store.state.push.burningId = data.message.id
  store.state.push.division_id = data.message.division_id
  store.state.push.orderNumber = data.message.order_number
  console.log(store.state.push.division_id)
})
channel.bind('reject-fast-order', function () {
  store.state.push.rejectedId = 51;
  // alert(JSON.stringify(data));
})
require('./store/subscriber')
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'Access-Control-Allow-Origin'
router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
      return next()
  }
  const middleware = to.meta.middleware
  const context = {
      to,
      from,
      next,
      store
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})
router.beforeResolve((to, from, next) => {
  if (to.name) {
      NProgress.start()
  }
  next()
})
router.afterEach(() => {
  NProgress.done()
})

store.dispatch('setHideFromLocal')

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
    data: {
      messages: []
    },
    components: {
      Home,
    },
    created() {

    }
  }).$mount('#app')
})
