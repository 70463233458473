<template>
    <div id="app">
        <div class="page-wrapper">
            <div class="page-inner">
                <Sidebar />
                <div class="page-content-wrapper">
                    <Header />
                    <main id="main" class="page-content">
                        <breadcrumbs />
                        <router-view :key="$route.fullPath" />
                    </main>
                </div>
            </div>
        </div>
        <Modals />
    </div>
</template>

<script>
    import Sidebar from "../components/Common/Sidebar";
    import Header from '../components/Common/Header';
    import Modals from '../components/Common/Modals'
    //import product from '../store/products';

    export default {
        name: "Product",
        data() {
            return {
            }
        },
        components:{
            Sidebar,
            Header,
            Modals
        },
        // mounted() {
        //     // console.log(process.env.BASE_URL)
        // },
        watch:{
            /*$route (to){
               if(to.name == 'productEdit'){
                   location.reload()
               }
                if(to.name == 'createProduct'){
                    location.reload()
                }
            }*/
        }
    }
</script>
