<template>
  <div
      class="modal modal-alert fade show d-block"
      id="example-modal-alert"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      v-if="showBurningModal"
  >
    <div
        class="modal-dialog modal-dialog-centered"
        role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <i class="fal fa-bolt mr-3"></i> Укажите причину отклонения заказа
          </h5>

          <button
              type="button"
              class="close"
              data-dismiss="modal"
              @click="closeBurningModal"
              aria-label="Close"
          >
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <textarea cols="80" rows="10" v-model="logText">

          </textarea>
        </div>
        <div class="modal-footer">
          <button
              @click="toBurningList"
              type="button"
              class="btn btn-primary waves-effect waves-themed"
          >
            Отклонить заявку
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import main from "@/store";

export default {
  name: "RejectFastForm",
  props: ['show'],
  data() {
    return {
      showBurningModal: this.show,
      logText: ''
    }
  },
  methods: {
    closeBurningModal() {
      this.showBackdrop = false;
      this.showBurningModal = false;
    },
    toBurningList(){
      this.showBackdrop = false;
      this.showBurningModal = false;
      if(this.$router.currentRoute.name !== 'orderList'){
        router.push({name: 'orderList', params: {activeTab: 'burning'}})
      }else{
        main.state.push.orderListTab = ''
        main.state.push.orderListTab = 'burning'
      }
    },
    rejectedText() {
      this.$emit('logText', {
        logText: this.logText
      })
    }
  }
}
</script>

<style scoped>

</style>