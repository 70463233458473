var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{class:_vm.hidePanel ? 'page-sidebar mod-nav-link d-none' : 'page-sidebar mod-nav-link'},[_vm._m(0),_c('nav',{staticClass:"primary-nav",attrs:{"id":"js-primary-nav","role":"navigation"}},[_c('div',{staticClass:"info-card"},[_c('img',{staticClass:"profile-image rounded-circle",attrs:{"src":_vm.authUser.avatar ? _vm.authUser.avatar : '/img/demo/avatars/avatar-m.png',"alt":_vm.authUser.name}}),_c('div',{staticClass:"info-card-text"},[_c('span',{staticClass:"d-flex align-items-center text-white"},[_c('span',{staticClass:"text-truncate text-truncate-sm d-inline-block"},[_vm._v(" "+_vm._s(_vm.authUser.name)+" ")])]),_c('span',{staticClass:"d-inline-block mt-3"},[_vm._v(" "+_vm._s(_vm.division)+" ")]),_c('router-link',{attrs:{"to":"/settings"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate }){return [_c('a',{staticClass:"d-flex align-items-center text-white mt-3",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"text-truncate text-truncate-sm d-block"},[_c('i',{staticClass:"fal fa-cog mr-2"}),_vm._v("Настройки ")])])]}}])})],1),_c('router-link',{staticClass:"calc-icon",attrs:{"to":"/calculators/maket","href":"/calculators/maket"}},[_c('i',{staticClass:"fal fa-2x fa-calculator"})]),_c('img',{staticClass:"cover",attrs:{"src":"/img/card-backgrounds/cover-2-lg.png","alt":"cover"}})],1),_c('ul',{staticClass:"nav-menu",attrs:{"id":"js-nav-menu"}},[(_vm.userGroups.users.includes(_vm.user.role))?_c('li',{staticClass:"active open"},[_vm._m(1),_c('ul',[_c('router-link',{attrs:{"to":"/users/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Список пользователей")])])]}}],null,false,3844202918)}),_c('router-link',{attrs:{"to":"/users/create"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Создать пользователя")])])]}}],null,false,1775760324)})],1)]):_vm._e(),(_vm.userGroups.divisions.includes(_vm.user.role))?_c('li',{staticClass:"active open"},[_vm._m(2),_c('ul',[_c('router-link',{attrs:{"to":"/divisions/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Список подразделений")])])]}}],null,false,1538149050)}),_c('router-link',{attrs:{"to":"/divisions/create"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Создать подразделение")])])]}}],null,false,1026724631)})],1)]):_vm._e(),(_vm.userGroups.products.includes(_vm.user.role))?_c('li',{staticClass:"active open"},[_vm._m(3),_c('ul',[_c('router-link',{attrs:{"to":"/products/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Каталог продуктов")])])]}}],null,false,3339606804)}),_c('router-link',{attrs:{"to":"/products/create"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Создать продукт")])])]}}],null,false,2178601620)})],1)]):_vm._e(),(_vm.userGroups.order.includes(_vm.user.role))?_c('li',{staticClass:"active open"},[_vm._m(4),_c('ul',[_c('router-link',{attrs:{"to":"/order/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Список заказов")])])]}}],null,false,4188699309)}),_c('router-link',{attrs:{"to":"/order/create"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Создать заказ")])])]}}],null,false,2596916192)})],1)]):_vm._e(),(_vm.userGroups.notifications.includes(_vm.user.role))?_c('li',{staticClass:"active open"},[_vm._m(5),_c('ul',[_c('router-link',{attrs:{"to":"/notifications/create"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Создать уведомление")])])]}}],null,false,857865590)})],1)]):_vm._e(),(_vm.userGroups.manufacture.includes(_vm.user.role) || _vm.user.division === 63)?_c('li',{staticClass:"active open"},[_vm._m(6),_c('ul',[_c('router-link',{attrs:{"to":"/manufacture/find"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Задания на изготовление")])])]}}],null,false,1199249678)}),_c('router-link',{attrs:{"to":"/mobile/manufacture/search"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Печать стикеров")])])]}}],null,false,4279477587)}),_c('router-link',{attrs:{"to":"/mobile/manufacture/open"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Поиск по штрих-коду")])])]}}],null,false,3988624402)})],1)]):_vm._e(),_c('li',{staticClass:"active open"},[_vm._m(7),_c('ul',[(_vm.userGroups.polk.includes(_vm.user.role))?_c('router-link',{attrs:{"to":"/polk/list/standard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Штендер стандарт")])])]}}],null,false,2798844339)}):_vm._e(),(_vm.userGroups.polk.includes(_vm.user.role))?_c('router-link',{attrs:{"to":"/polk/list/eco"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Штендер эконом")])])]}}],null,false,3943155853)}):_vm._e(),(_vm.userGroups.polk.includes(_vm.user.role))?_c('router-link',{attrs:{"to":"/polk/download"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Скачать макет")])])]}}],null,false,1287055819)}):_vm._e(),(_vm.userGroups.polk.includes(_vm.user.role))?_c('router-link',{attrs:{"to":"/polk/edit"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Редактировать макет")])])]}}],null,false,2668061594)}):_vm._e(),(_vm.userGroups.polk.includes(_vm.user.role))?_c('router-link',{attrs:{"to":"/polk/orders"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Список заказов")])])]}}],null,false,4188699309)}):_vm._e(),_c('router-link',{attrs:{"to":"/polk/manufacture"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Задания на изготовление")])])]}}])}),(_vm.userGroups.polk.includes(_vm.user.role))?_c('router-link',{attrs:{"to":"/polk/my-orders"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{staticClass:"pt-1 pb-1",attrs:{"href":href},on:{"click":navigate}},[_vm._v("Мои заказы")])])]}}],null,false,3771159923)}):_vm._e()],1)]),(_vm.userGroups.statistic.includes(_vm.user.role))?_c('li',{staticClass:"active open"},[_vm._m(8),_c('ul',[_c('router-link',{attrs:{"to":"/reports"},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate, isActive, isExactActive }){return [_c('li',{class:[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v("Создать отчёт")])])]}}],null,false,2058349796)})],1)]):_vm._e()]),_c('div',{staticClass:"filter-message js-filter-message bg-success-600"})]),_c('div',{staticClass:"nav-footer shadow-top"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-logo"},[_c('a',{attrs:{"href":"/dashboard"}},[_c('span',{staticClass:"page-logo-text mr-1"},[_vm._v("OQplus")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#","title":"Category","data-filter-tags":"category"}},[_c('i',{staticClass:"fal fa-user"}),_c('span',{staticClass:"nav-link-text",attrs:{"data-i18n":"nav.category"}},[_vm._v("Пользователи")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#","title":"Category","data-filter-tags":"category"}},[_c('i',{staticClass:"fal fa-users-class"}),_c('span',{staticClass:"nav-link-text",attrs:{"data-i18n":"nav.category"}},[_vm._v("Подразделения")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#","title":"Category","data-filter-tags":"category"}},[_c('i',{staticClass:"fal fa-book"}),_c('span',{staticClass:"nav-link-text",attrs:{"data-i18n":"nav.category"}},[_vm._v("Продукты")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#","title":"Category","data-filter-tags":"category"}},[_c('i',{staticClass:"fal fa-ruble-sign"}),_c('span',{staticClass:"nav-link-text",attrs:{"data-i18n":"nav.category"}},[_vm._v("Заказ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#","title":"Category","data-filter-tags":"category"}},[_c('i',{staticClass:"fal fa-bell"}),_c('span',{staticClass:"nav-link-text",attrs:{"data-i18n":"nav.category"}},[_vm._v("Уведомления")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#","title":"Category","data-filter-tags":"category"}},[_c('i',{staticClass:"fal fa-print"}),_c('span',{staticClass:"nav-link-text",attrs:{"data-i18n":"nav.category"}},[_vm._v("Производство")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#","title":"Category","data-filter-tags":"category"}},[_c('i',{staticClass:"fal fa-award"}),_c('span',{staticClass:"nav-link-text",attrs:{"data-i18n":"nav.category"}},[_vm._v("Бессмертный полк")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#","title":"Уведомления","data-filter-tags":"category"}},[_c('i',{staticClass:"fal fa-bell"}),_c('span',{staticClass:"nav-link-text",attrs:{"data-i18n":"nav.category"}},[_vm._v("Отчётность")])])
}]

export { render, staticRenderFns }