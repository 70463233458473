<template>
    <!-- BEGIN Page Header -->
    <header class="page-header" role="banner">
        <!-- DOC: mobile button appears during mobile width -->
        <!-- <div class="hidden-lg-up">
            <a href="#" class="header-btn btn press-scale-down" data-action="toggle" data-class="mobile-nav-on">
                <i class="ni ni-menu"></i>
            </a>
        </div> -->
        <div>
            <button :class="hidePanel ? 'btn btn-primary' : 'btn btn-primary btn-sideHide'" @click="sidePanelHide">
                {{hidePanel ? 'Показать меню' : 'Скрыть меню'}}
            </button>
        </div>
        <div class="justify-content-center d-flex align-items-center" style="flex: auto" v-if="headerNotification.active">
            <div :class="`ml-3 p-1 px-2 rounded bg-${headerNotification.color}-300`">
                {{ headerNotification.value }}
            </div>
        </div>
        <div class="ml-auto d-flex align-items-center">
            <!-- app message -->
            <a href="#" @click.prevent="signOut" title="Выйти" class="header-icon header-icon_link" data-toggle="modal">
                <i class="fal fa-sign-out-alt"></i>
            </a>
            <!-- app user menu -->
            <div>
                <div data-toggle="dropdown" v-bind:title="authUser.email"
                     class="header-icon d-flex align-items-center justify-content-center ml-2">
                    <img :src="authUser.avatar ? authUser.avatar : '/img/demo/avatars/avatar-m.png'"
                         class="profile-image rounded-circle" :alt="authUser.name">

                </div>

            </div>
        </div>

    </header>
    <!-- END Page Header -->
</template>

<script>
    import store from '../../store'
    import axios from 'axios'
    //import Multiselect from 'vue-multiselect'
    import main from "../../store/index";
    import { mapMutations } from 'vuex';

    export default {
        name: "Header",
        components: {
           // Multiselect,
        },
        data() {
            return {
                authUser: store.getters['auth/authenticated'],
                hidePanel: store.state.hidePanel,
                headerNotification: {
                    id: 1,
                    value: '',
                    active: false,
                    color: ''
                },
                // division: null,
                // divisions: []

            }
        },
        methods: {
            ...mapMutations(['SET_HIDE_PANEL']),
            signOut() {
                console.log('exit')
                axios.post('api/auth/logout')
                    .then(res => {
                        localStorage.removeItem('user_division')
                        main.state.workspace = null
                        console.log(res.data)
                        location.reload()
                    })
                    .catch(err => {
                        console.error(err)
                    })
            },
            sidePanelHide() {
                this.hidePanel = !this.hidePanel
                this.SET_HIDE_PANEL();
            }
            // selectDivision() {
            //     localStorage.setItem('user_division', JSON.stringify(this.division))
            //     console.log(main.state.workspace)
            // }

        },
        computed: {
            // getCurrentWorkspace() {
            //     return main.state.workspace
            // }
        },
        mounted() {
            if (Notification.permission !== 'denied' && Notification.permission !== "granted") {
                 Notification.requestPermission(function (permission) {
            // Если права успешно получены, отправляем уведомление
                if (permission === "granted") {
                    console.log('Нотификации разрешены')
                } else {
                    alert('Для корректной работы разрешите уведомления'); // Юзер отклонил наш запрос на показ уведомлений
                }
                });
            }
           axios.get('api/auth/notification/show').then((res) => {
               ({...this.headerNotification} = res.data)
           })

            // axios.get('api/auth/divisions')
            //     .then(res => {
            //         this.divisions = res.data
            //         if (localStorage.getItem('user_division')) {
            //             this.division = JSON.parse(localStorage.getItem('user_division'))
            //         }
            //     })
        },
        watch: {
            // getCurrentWorkspace(workspace) {
            //     console.log('getWorkspace')
            //     this.division = workspace
            // }
        }
    }
</script>

<style scoped>
    /* @media(min-width: 1000px) {
       .btn-sideHide {
           display: none;
       } 
    } */
</style>