<template>
  <div class="wrapper wrapper-content animated fadeInRight article">
    <div class="subheader">
      <h1 class="display-4 mb-0">Редактировать макет штендера</h1>
    </div>
    <div class="row">
      <div class="form-group mb-2">
        <p class="fw-700 mt-3 mb-1">Введите номер задания на изготовление</p>
        <input v-model="productId" type="text" class="form-control" />
        <button id="finish" @click="getTemplate" class="btn btn-primary mt-3 mb-3">
          Редактировать
        </button>
      </div>
    </div>
    <div
      class="row justify-content-md-center"
      :class="hideConstructor ? 'd-none' : ''"
    >
      <div class="col-lg-10">
        <div class="ibox">
          <div class="ibox-content">
            <iframe
              id="editorFrame"
              class="border-0"
              width="100%"
              height="1000px"
            ></iframe>
          </div>
          <div class="ibox-content text-right">
            <button id="finish" @click="finishDesign" class="btn btn-primary">
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" v-if="showLoader"></div>
    <div class="modal fade show d-block" v-if="showLoader">
      <div
        class="fixed modal-dialog modal-dialog-centered justify-content-center"
      >
        <button
          class="btn btn-info waves-effect waves-themed"
          type="button"
          disabled=""
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Загрузка...
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Constructor",
  components: {},
  data() {
    return {
      productId: 0,
      editor: null,
      template: [],
      userId: 0,
      stateId: 0,
      isVertical: 1,
      resultText: "",
      quatro: null,
      CustomersCanvas: window.CustomersCanvas,
      showLoader: false,
      hideConstructor: true,
      printInfo: {
        number: "",
        date: "",
        price: 0,
        name: "",
        text: "",
        phone: "",
        address: "",
        paymentType: "",
      },
    };
  },
  mounted() {},
  methods: {
    getTemplate() {
      this.hideConstructor = false;
      this.showLoader = true;
      axios.get(`api/auth/polk/get/order/${this.productId}`).then((res) => {
        this.template = res.data[1].file_url.split(":");
        this.stateId = this.template[0];
        this.userId = this.template[1];
        let ccScript = document.createElement("script");
        ccScript.setAttribute(
          "src",
          "https://gen.copy.spb.ru/prod/DE/SimplePolygraphy/resources/generated/iframeapi.js"
        );
        ccScript.setAttribute("id", "CcIframeApiScript");
        document.head.appendChild(ccScript);
        ccScript.onload = () => {
          this.loadEditor();
        };
      });
    },
    getUrlVars() {
      var vars = [],
        hash;

      var hashes = window.location.href
        .slice(window.location.href.indexOf("?") + 1)
        .split("&");

      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");

        vars.push(hash[0]);

        vars[hash[0]] = hash[1];
      }

      return vars;
    },
    loadEditor() {
      let productDefinition = this.stateId;

      var iframe = document.getElementById("editorFrame");

      //var frameParent = iframe.parent();
      //Loading the product into the editor.
      //var editor = null;
      //frameParent.mask("Загрузка...");
      //Loading the editor.
      let vm = this;
      window.CustomersCanvas.IframeApi.loadEditor(iframe, productDefinition, {
        //autoLoadUserInfo: true,
        //userInfo: {"txt_brand": "OQ print"},
        defaultLanguage: "ru",
        rendering: {
          proofImageMockupEnabled: false,
          hiResOutputDpi: 200,
        },
        userId: vm.userId,
        initialMode: "Advanced",
        preloader: {
          firstTimeMessage: "Подготовка макета. Пожалуйста, подождите...",
        },
        widgets: {
          FinishButton: {
            mode: "Disabled",
          },
          LeftToolbar: {
            qrCodeButtonEnabled: false,
            linearBarcodeButtonEnabled: false,
          },
          TopToolbar: {
            opacitySliderEnabled: false,
            borderColorButtonEnabled: false,
            fillColorButtonEnabled: false,
            fontSize: {
              min: 1.5,
            },
          },
          BottomToolbar: {
            surfaceSwitch: {
              showThumbnails: false,
            },
          },
        },
      })
        //If the editor has been successfully loaded.
        .then(function (e) {
          vm.editor = e;
          vm.showLoader = false;
          /* if (edit != 1) {
                        editor.loadUserInfo(userInfo);
                    }*/
        })
        //If there was an error thrown when loading the editor.
        .catch(function (error) {
          console.error("The editor failed to load with an exception: ", error);
        });

      /*document.getElementById('clear').addEventListener('click', function(e) {
                localStorage.removeItem("globalCart");
            })*/
    },
    finishDesign() {
      let vm = this;
      this.editor
        .finishProductDesign({
          proofMaxHeight: 750,
          proofMaxWidth: 750,
        })
        .then(function (result) {
          axios
            .post(
              `api/auth/orders/order/product/file_url`,
              {
                file_url: result.stateId+':'+result.userId,
                id: vm.productId
              }
            )
            .then(() => {
              alert('Макет успешно сохранен!')
            }).catch((error) => {
              console.log(error)
            });
        });
    },
  },
};
</script>
<style>
.white {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: #ffffff;
  width: 100%;
  height: 100%;
}
.sk-spinner {
  top: 50%;
}
</style>
