import { render, staticRenderFns } from "./CatalogProducts.vue?vue&type=template&id=4d93e8de&"
import script from "./CatalogProducts.vue?vue&type=script&lang=js&"
export * from "./CatalogProducts.vue?vue&type=script&lang=js&"
import style0 from "./CatalogProducts.vue?vue&type=style&index=0&id=4d93e8de&prod&lang=less&rel=stylesheet%2Fless&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports