<template>
  <main id="sectionId" data-id="10748" data-size="24" class="wrapper wrapper-content animated fadeInUp" data-section="shtender_st">
    <h1 class="m-b-xl">Штендеры стандарт</h1>
    <div
      class="row"
    >
      <div class="col-lg-2">
        <div class="o-filter">
          <div class="o-filter__holder">
            <h2 class="mb-3">Фильтры</h2>
            <div class="hr-line-dashed"></div>

            <h4 class="mb-3">Тематика</h4>
            <div class="form-group" v-for="(filter, key, index) in checkbox " :key="key">
              <div class="i-checks">
                <label :for="key" class="mb-0 d-flex align-items-center">
                  <input
                    type="checkbox"
                    name="confirm"
                    v-model="form.checkbox[index]"
                    @change="addCondition"
                    :true-value="filter.value"
                    :id="key"
                    class="mr-1"
                  />
                  <i></i>{{ filter.value }}
                </label>
              </div>
            </div>
            <span class="btn btn-default mb-4" @click="clearCheck('theme')"
              >Сбросить</span
            >
            <div class="hr-line-dashed"></div>

            <h4 class="mb-3">Количество фото</h4>
            <div
              class="form-group"
              v-for=" (filterNumber, key, index) in photos"
              :key="key"
            >
              <div class="i-checks">
                <label :for="key" class="mb-0 d-flex align-items-center">
                  <input
                    type="checkbox"
                    name="confirm"
                    :id="key"
                    v-model="form.photo[index]"
                    @change="addCondition"
                    :true-value="filterNumber.photosText"
                    class="mr-1"
                  />
                  <i></i>
                  {{ filterNumber.photosText }}
                </label>
              </div>
            </div>
            <span class="btn btn-default mb-4" @click="clearCheck('photo')"
              >Сбросить</span
            >

            <div class="hr-line-dashed"></div>
            <h4 class="m-b-lg">Вид</h4>
            <div class="form-group">
              <div class="m-confirm d-flex align-items-center mb-2">
                <input
                  type="radio"
                  v-model="form.view"
                  id="form1"
                  value="v"
                  ng-change="showFullList()"
                  class="m-confirmation__input mr-1"
                />
                <label for="form1" class="m-confirmation__label mb-0"
                  >Вертикальные</label
                >
              </div>
              <div class="m-confirm d-flex align-items-center mb-2">
                <input
                  type="radio"
                  v-model="form.view"
                  id="form2"
                  value="h"
                  class="m-confirmation__input mr-1"
                />
                <label for="form2" class="m-confirmation__label mb-0"
                  >Горизонтальные</label
                >
              </div>
              <div class="m-confirm d-flex align-items-center mb-2">
                <input
                  type="radio"
                  v-model="form.view"
                  id="form3"
                  value="-1"
                  class="m-confirmation__input mr-1"
                />
                <label for="form3" class="m-confirmation__label mb-0">Неважно</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-10 m-b-lg">
        <h4>
          {{Object.keys(getShtenders).length > 0 ? 'Найдено '+ countOnPageAll : 'Макеты с такими параметрами не найдены.' }}
        </h4>
        <div class="row">
          <div
            v-for="(item, key) in getShtenders"
            :key="key+'shtender'"
            class="col-lg-2 m-b-md"
          >
            <a :href="'/polk/constructor?surface=' + item.id">
              <img
                class="img-thumbnail"
                :src="`https://copy.spb.ru/assets/images/constructors/previews/shtender/${item.file}.png`"
              />
              <p class="text-center m-t-sm">
                {{item.name}}{{ item.version == 1 ? '' : '-'+item.version }}
                <br />({{item.photo == 'n' ? 'Без' : item.photo}} фото)
              </p>
            </a>
          </div>
        </div>

        <button
          class="btn btn-block btn-outline btn-primary"
          @click="showMore()"
          v-if="countPageItems < countOnPageAll"
        >
          Показать ещё шаблоны
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios'

export default {
  name: "PolkStandard",
  components: {
    //
  },
  data() {
    return {
      checkbox: {
        checkbox1: { check: false, value: 'Универсальный' },
        checkbox2: { check: false, value: 'Танковые войска' },
        checkbox3: { check: false, value: 'Артиллерийские войска' },
        checkbox4: { check: false, value: 'Сухопутные войска' },
        checkbox5: { check: false, value: 'Военно-воздушные силы' },
        checkbox6: { check: false, value: 'Военно-морской флот' }
      },
      photos: {
        photo0: { check: false, value: 'n', photosText: 'Без фото' },
        photo1: { check: false, value: '1', photosText: '1 фото' },
        photo2: { check: false, value: '2', photosText: '2 фото' },
        photo3: { check: false, value: '3', photosText: '3 фото' },
        photo4: { check: false, value: '4', photosText: '4 фото' },
      },
      shtenders: {},
      condCheck: [],
      condPhoto: [],
      condView: [],
      form: {checkbox: [], photo: [], view: '-1'},
      otherPart: {},
      countPageItems: 30,
      countOnPageAll: null,
    };
  },
  methods: {
    addCondition() {
      this.condCheck = []
      this.condPhoto = []
      this.countPageItems = 30

      this.form.checkbox.forEach((check) => {
        if(check) this.condCheck.push(check)
      })

      this.form.photo.forEach((check) => {
        if(check) this.condPhoto.push(check)
      })
    },

    clearCheck(param) {
      if(param == 'theme') {
        this.condCheck = []
        this.form.checkbox = []
      }
      if(param == 'photo') {
        this.condPhoto = []
        this.form.photo = []
      }
    },

    showMore() {
      this.countPageItems += 30
    },

    cutIt(thObj) {
      let cutted = {}
      this.countOnPageAll = Object.keys(thObj).length
      if(Object.keys(thObj).length > this.countPageItems) {
        for(let i = 0; i < this.countPageItems; i++) {
          cutted[Object.keys(thObj)[i]] = thObj[Object.keys(thObj)[i]]
        }
        for(let i = this.countPageItems; i < Object.keys(thObj).length; i++) {
          this.otherPart[Object.keys(thObj)[i]] = thObj[Object.keys(thObj)[i]]
        }
      }
      else {
        cutted = thObj
      }

      return cutted
    }
  },
  mounted() {
    if(this.$store.state.hidePanel == false) {
      document.getElementsByClassName('btn-sideHide')[0].click()
    }
    var id = document.getElementById('sectionId').dataset.id;
    axios.post('api/auth/polk/get/eco', {category: id, template: 'shtender'}).then(res => {
      this.shtenders = res.data
    })
  },
  computed: {
    getShtenders() {
      let keys = Object.keys(this.shtenders)
      let obj = {}

      keys.forEach((key) => {
        if(this.condCheck.includes(this.shtenders[key].style)) {
          obj[key] = this.shtenders[key]
        }
      });
      if(this.condCheck.length === 0) obj = this.shtenders

      let secKeys = Object.keys(obj)
      let secObj = {}

      secKeys.forEach((key) => {
        if(this.shtenders[key].photo == 'n') {
          this.shtenders[key].photo = 'Без'
        }

        if(this.condPhoto.includes(obj[key].photo + ' фото')) {
          secObj[key] = obj[key]
        }
      });
      if(this.condPhoto.length === 0) secObj = obj

      let thKeys = Object.keys(secObj)
      let thObj = {}

      thKeys.forEach((key) => {
        if(this.form.view === secObj[key].orientation) {
          thObj[key] = secObj[key]
        }
      });
      if(this.form.view === '-1') thObj = secObj

      return this.cutIt(thObj)
    }
  },
};
</script>
