<template>
    <div class="d-flex-column p-4">
      <div :class="isPrint ? 'd-none' : ''">
        <div>
          <button
            @click="goBack"
            name="reset"
            class="btn-lg btn-primary btn-md btn-block waves-effect waves-themed"
          >
            Вернуться к поиску
          </button>
        </div>
        <div class="d-flex-column my-3 alert alert-secondary">
          <h3 class="text-center mt-4 mb-3">Количество мест</h3>
          <div class="input-group input-group-lg">
            <div class="d-flex-column w-100">
              <input
                v-model.number="countPlace"
                type="number"
                class="form-control shadow-inset-2"
                placeholder="Введите количество мест"
              />
              <div class="d-flex justify-content-around mt-4">
                <button class="btn-lg btn-primary" @click="changeNumber(1)">1</button>
                <button class="btn-lg btn-primary" @click="changeNumber(2)">2</button>
                <button class="btn-lg btn-primary" @click="changeNumber(3)">3</button>
                <button class="btn-lg btn-primary" @click="changeNumber(4)">4</button>
                <button class="btn-lg btn-primary" @click="changeNumber(5)">5</button>
              </div>
            </div>
            <button
              @click="goPrint"
              name="reset"
              class="btn-lg mt-5 btn-success btn-md btn-block waves-effect waves-themed"
            >
              Печать стикера
            </button>
          </div>
        </div>
        <div class="d-flex mt-3">
          <div class="w-100 alert alert-primary">
            <div class="panel-hdr bg-primary-700 bg-success-gradient">
              <h3 class="my-0">Инфо о продукте</h3>
            </div>
            <h4 class="mt-3">Комментарий:</h4>
            <ul v-if="productAndOrder.comment">
              <li>{{ productAndOrder.comment }}</li>
            </ul>
            <h4>Макет:</h4>
            <ul v-if="productAndOrder.layout_url">
              <li>{{ productAndOrder.layout_url }}</li>
            </ul>
            <h4>Продукт:</h4>
            <ul>
              <li>Имя: {{ productAndOrder.product_name }}</li>
              <li v-if="productAndOrder.runs == 3">Тираж: {{ productAndOrder.run_quantity }}</li>
              <li>Количество: {{ productAndOrder.product_quantity }}</li>
            </ul>
            <h4>Вариант продукта (Печать):</h4>
            <ul>
              <li><span v-html="productAndOrder.variant"></span></li>
              <li v-if="productAndOrder.runs == 4">Ширина: {{ productAndOrder.width }}</li>
              <li v-if="productAndOrder.runs == 4">Высота: {{ productAndOrder.height }}</li>
            </ul>
          </div>
          <div class="ml-3 w-100 alert alert-primary alert-dismissible">
            <div class="panel-hdr bg-primary-700 bg-success-gradient">
              <h3 class="my-0">Инфо о заказе</h3>
            </div>
            <h4 class="mt-3">Номер заказа:</h4>
            <ul>
              <li>
                {{ productAndOrder.custom_number || productAndOrder.order_number}}
                ({{ productAndOrder.position_number }} из {{ productAndOrder.count_products }})
              </li>
            </ul>
            <h4>Дата готовности</h4>
            <ul>
              <li v-if="productAndOrder.delivery_type.id === 0">
                {{ productAndOrder.delivery_datetime }}
              </li>
              <li v-else>
                {{ productAndOrder.ready_date }}, {{ productAndOrder.deliver_after }}
              </li>
            </ul>
            <h4>Дата выдачи</h4>
            <ul>
              <li>{{ productAndOrder.delivery_datetime }}</li>
            </ul>
            <h4>Адрес выдачи:</h4>
            <ul>
              <li>{{ productAndOrder.delivery_type.name }}</li>
              <li v-if="productAndOrder.delivery_type.id === 0">
                {{ JSON.parse(productAndOrder.shipment_address).name }}
              </li>
              <li v-else-if="productAndOrder.shipment_address !== ''">
                {{ JSON.parse(productAndOrder.shipment_address).address[0].address }}
              </li>
            </ul>
            <h4>Создано</h4>
            <ul>
              <li>{{ productAndOrder.department }}</li>
              <li>{{ productAndOrder.user }}</li>
            </ul>
            <h4>Данные клиента</h4>
            <ul>
              <li>Имя: {{ productAndOrder.customer_name }}</li>
              <li>Телефон: {{ productAndOrder.customer_phone }}</li>
              <li>Email: {{ productAndOrder.customer_email }}</li>
            </ul>
            <h4>Оплата</h4>
            <ul>
              <li>Доплата: {{productAndOrder.postpaid}}</li>
              <li>Итоговая стоимость: {{productAndOrder.order_cost}}</li>
              <li>Предоплата: {{productAndOrder.prepaid}}</li>
              <li>Тип оплаты: {{productAndOrder.payment_type}}</li>
            </ul>
          </div>
        </div>
      </div>
      <section :class="isPrint ? 'd-print-block' : 'd-none d-print-block'" style="page-break-after:always" v-for="i in countPlace" :key="i">
          <table width="355" style="border:0; background-color: #fff; font-family: sans-serif;">
              <tr>
                  <td colspan="3"><img src="/img/stiker.png" align="center"></td>
              </tr>
              <tr style="border-bottom: 1px dotted #000">
                  <td width="110">Номер заказа:</td>
                  <td width="155"><span style="font-size:1.25rem; font-weight: 700; color: #333">{{productAndOrder.custom_number || productAndOrder.order_number}} ({{productAndOrder.position_number}} из {{productAndOrder.count_products}})</span></td>
                  <td width="90">Место: <span style="font-size:1.25rem; font-weight: 700; color: #333">{{i}} из {{countPlace}}</span></td>
              </tr>
          </table>
          <table width="355" style="border:0; background-color: #fff; font-family: sans-serif;">
              <tr>
                  <td width="110" style="border-bottom: 1px dotted #000">Доплата:</td>
                  <td style="border-bottom: 1px dotted #000"><span style="font-size:1.25rem; font-weight: 700; color: #333">{{productAndOrder.postpaid}}</span></td>
              </tr>
              <tr>
                  <td width="110" style="border-bottom: 1px dotted #000">Тип оплаты:</td>
                  <td style="border-bottom: 1px dotted #000"><span style="font-size:1.25rem; font-weight: 700; color: #333">{{productAndOrder.payment_type}}</span></td>
              </tr>
          </table>
          <table width="355" style="border:0; background-color: #fff; font-family: sans-serif;">
              <tr>
                  <td width="30"><span style="font-size:1.25rem; font-weight: 700; color: #333"><i class="zmdi zmdi-phone zmdi-hc-fw"></i></span></td>
                  <td>
                      <table class="text-center">
                          <tr><td>___</td><td>___</td><td>___</td><td>___</td><td>___</td></tr>
                          <tr style="font-size:0.75rem; color: #333"><td>1</td><td>2</td><td>3</td><td>4</td><td>5</td></tr>
                      </table>
                  </td>
              </tr>
          </table>
          <table width="355" style="border:0; background-color: #fff; font-family: sans-serif;">
              <tr style="border-bottom: 1px dotted #000">
                  <td width="110">Телефон:</td>
                  <td><span style="font-size:1.25rem; font-weight: 700; color: #333">{{productAndOrder.customer_phone}}</span></td>
              </tr>
              <tr style="border-bottom: 1px dotted #000">
                  <td>Имя:</td>
                  <td><span style="font-size:1.25rem; font-weight: 700; color: #333">{{productAndOrder.customer_name}}</span></td>
              </tr>
              <tr style="border-bottom: 1px dotted #000">
                  <td>Дата получения:</td>
                  <td><span style="font-size:1.25rem; font-weight: 700; color: #333">{{productAndOrder.delivery_datetime}}</span></td>
              </tr>
              <tr style="border-bottom: 2px dotted #000">
                  <td>&nbsp;</td>
                  <td></td>
              </tr>
              <tr style="border-bottom: 2px dotted #000">
                  <td>&nbsp;</td>
                  <td></td>
              </tr>
              <tr>
                  <td>Адрес доставки:</td>
                  <td>
                    <span style="font-size:1.5rem; font-weight: 700; color: #333"
                          v-if="productAndOrder.delivery_type.id === 0">
                      {{JSON.parse(productAndOrder.shipment_address).name}}
                    </span>
                    <span style="font-size:1.5rem; font-weight: 700; color: #333"
                          v-else-if="productAndOrder.shipment_address !== ''">
                      {{JSON.parse(productAndOrder.shipment_address).address[0].address}}
                    </span>
                  </td>
              </tr>
          </table>
      </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from "axios";

export default {
    name: "ManufactureInfo",
    data() {
        return {
          order: '',
          product_id: null,
          isPrint: false,
          productAndOrder: {
            id : null,
            product_name : '',
            comment : '',
            preview : '',
            product_quantity : '',
            variant : '',
            services : [],
            custom_number : null,
            delivery_datetime : null,
            delivery_datetime_qr : null,
            ready_date : null,
            department : '',
            user : '',
            shipment_address : '',
            price : '',
            order_cost : '',
            postpaid : null,
            prepaid : null,
            customer_name : '',
            customer_phone : null,
            customer_email : '',
            deliver_after : '',
            created_at : '',
            category : '',
            type : '',
            product_comment : '',
            current_product : '',
            delivery_type : {},
            layout_url : '',
            runs : null,
            width : null,
            height : null,
            run_quantity : null,
            payment_type : null,
            shipment_type : '',
            position_number : null,
            count_products : null,
            order_number : null,
          },
          countPlace: 1,
        }
    },
    components: {

    },
    methods: {
        ...mapGetters(['getManufactureProductId']),

        changeNumber(num) {
          this.countPlace = num;
          this.goPrint();
        },

        getProductAndOrder() {
          /* axios.get('api/auth/manufacture/mobile/order/' + 9926).then(res : {
            this.order = res.data;
          }) */
          console.log(this.product_id);
          axios.get('api/auth/manufacture/mobile/product/' + this.product_id).then(res => {
            console.log(res);
            this.productAndOrder = res.data;
            this.isPrint = true
            this.$nextTick(() => {
              this.goPrint();
              this.isPrint = false
            })
          }).catch(() => {
            alert('Продукт не найден')
            this.$router.push('/mobile/manufacture/search');
          })
        },

        goPrint() {
          console.log(this.countPlace);
          this.isPrint = true
          this.$nextTick(() => {
            window.print()
            this.isPrint = false
          })
        },

        goBack() {
          this.$router.push('/mobile/manufacture/search');
        }
    },
    beforeMount() {
      this.product_id = this.getManufactureProductId()
      if(this.product_id == 0) {
        alert('Продукт не задан')
        this.$router.push('/mobile/manufacture/search');
      }
      else this.getProductAndOrder()
    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped>
    li {
      font-size: 1.2rem;
    }
    h4 {
      font-size: 1.3rem;
    }
</style>
