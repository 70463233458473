<template>
  <form
    action=""
    enctype="multipart/form-data"
    method="post"
    @submit.prevent="storeVariant"
  >
    <div class="form-group mt-5">
      <h4 class="mt-4 mb-3">Наименование варианта продукта</h4>
      <input type="text" class="form-control" required v-model="variant.name" />
    </div>
    <div class="form-group mt-5 col-2 pl-0 ml-0">
      <h4 class="mt-4 mb-3">Сортировка</h4>
      <input type="number" class="form-control" v-model.number="variant.sortable" />
    </div>
    <div class="form-group">
      <h4 class="mt-4 mb-3">Является родительским вариантом продукта</h4>
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          v-model="variant.is_parent"
          class="custom-control-input"
          id="is_parent"
        />
        <label class="custom-control-label" for="is_parent"></label>
      </div>
    </div>
    <div class="form-group" v-if="!variant.is_parent && parents.length > 0">
      <h4 class="mt-4 mb-3">Родительский вариант</h4>
      <multiselect
        v-model="variant.parent"
        track-by="name"
        label="name"
        :options="parents"
        :show-labels="false"
        :searchable="true"
        :allow-empty="false"
        placeholder=""
      >
        <template slot-scope="{ option }">{{ option }}</template>
      </multiselect>
    </div>
    <div class="form-group" v-if="!variant.is_parent">
      <h4 class="mt-4 mb-3">Стоимость варианта продукта</h4>
      <div class="d-flex">
        <div
          class="col-1 mr-3 pl-0"
          v-for="(item, index) in variant.price"
          :key="index"
        >
          <label
            class="form-label text-center d-block"
            :for="'priceInput' + index"
            >{{ variant.price[index].quantity }}</label
          >
          <input
            v-model="variant.price[index].price"
            value="0"
            :id="'priceInput' + index"
            type="number"
            step="0.01"
            class="form-control text-center"
          />
        </div>
      </div>
    </div>
    <div class="d-flex flex-row-reverse">
      <input
        type="submit"
        class="btn btn-primary btn-md btn-block waves-effect waves-themed col-1"
        value="Сохранить"
      />
      <button
        @click.prevent="backToProduct"
        name="reset"
        class="btn btn-default btn-md btn-block waves-effect waves-themed col-1 mt-0 mr-3"
      >
        Отменить
      </button>
    </div>
  </form>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
export default {
  name: "CreateProductVariants",
  components: {
    Multiselect,
  },
  data() {
    return {
      variant: {
        name: null,
        is_parent: false,
        product_id: null,
        parent: {},
        price: [],
        sortable: 0
      },
      parents: [],
    };
  },
  methods: {
    storeVariant() {
      axios
        .post("api/auth/variants/store", this.variant)
        .then(() => {
          console.log(this.variant);
          alert("Вариант успешно добавлен!");
          this.$router.push(`/products/product/${this.variant.product_id}`);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            alert("Что-то пошло не так, смотри консоль");
          }
        });
    },
    backToProduct() {
      this.$router.push(`/products/product/${this.variant.product_id}`);
    },
  },
  created() {
    let productData = JSON.parse(localStorage.getItem("product"));
    console.log(productData);
    this.variant.product_id = productData.id;
    let runs = productData.runs_range;
    this.parents = productData.parent_variants;
    for (let i in runs) {
      let item = runs[i];
      this.variant.price.push({
        quantity: item,
        price: 0,
      });
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 14px;
}
</style>

