export default {
    state: {
        id: null,
        product: {},
        categories: [],
        productErrors: {},
        variants: {},
        showVariantForm: false,
        clearVariantForm: false,
        parent: [],
        productStatus: 'NotCreated',
        variant: {},
        service: {},
        isEdit: false,
        services: [],
        showServiceForm: false,
        clearServiceForm: false,
        showServices: true,
        isProductEdit: false,
        activeTab: 'tab-params'
    }
}