<template>
    <div>
        <div class="subheader">
            <h1 class="subheader-title" style="border-left: 4px solid #000000;padding-left: 20px;">Просмотр категории </h1>
        </div>
        <h3 class="mt-5">Список продуктов</h3>
        <div class="panel-container show">
            <table id="dt-basic-example" class="table table-bordered table-hover table-striped w-100 dataTable dtr-inline" role="grid" aria-describedby="dt-basic-example_info" style="width: 1475px;">
                <thead class="bg-primary-600">
                <tr role="row">
                    <th class="sorting_asc">ID #</th>
                    <th class="sorting_asc">Наименование</th>
                    <th class="sorting_asc">Редактировать</th>
                    <th class="sorting_asc">Удалить</th>
                </tr>
                </thead>
                <tbody>
                <tr class="odd" v-for="(item, index) in productsList" :key="index">
                    <td>{{item.id}}</td>
                    <td>{{item.name}}</td>
                    <td class="point" @click="editProduct(item.id)">Редактировать продукт</td>
                    <td @click="deleteProduct(item.id)" class="point">Удалить продукт</td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>

</template>

<script>
    import axios from 'axios'
    //import { mapActions } from 'vuex'
    import router from "../../router";

    export default {
        name: "CategoryView",
        data() {
            return {
                id: this.$attrs.id,
                productsList: {}
            }
        },
        methods: {
            deleteProduct(id){
                console.log(id);
                axios.delete(`api/auth/product/${id}`)
                .then(res => {
                    console.log(res.data)
                })
                .catch(error => {
                    console.log(error.response.data)
                })
            },
            editProduct(id) {
                router.replace(`/products/product/${id}`)
            }
        },
        beforeMount() {
            axios.get(`api/auth/category-products/${this.id}`)
                .then(res => {
                    if (res.status == 200) {
                        this.productsList = res.data
                        console.log(res.data);
                    } else {
                        console.log(res);
                    }
                })
        }
    }
</script>

<style scoped lang="scss">
    .point {
        cursor: pointer;
    }
</style>