<template>
  <div class="pb-100">
    <div class="subheader mb-3">
      <h1>
        Редактирование продукта "{{ product.name }}" <sup>({{position_number}} из {{orderInfo.productsNum}})</sup> в заказе
      </h1>
    </div>
    <div class="d-flex">
      <div class="col-8">
        <!-- <div
          v-if="this.errors.length !== 0"
          class="alert alert-danger"
          role="alert"
        >
          <h1><strong>Пожалуйста, исправьте ошибки:</strong></h1>
          <ul>
            <li v-for="error in this.errors" :key="error[0]">{{ error[0] }}</li>
          </ul>
        </div> -->
        <div
      v-if="this.errors.length !== 0"
      class="alert alert-danger"
      role="alert"
    >
      <h1><strong>Пожалуйста, исправьте ошибки:</strong></h1>
      <ul>
        <li v-for="error in this.errors" :key="error[0]">{{ error[0] }}</li>
      </ul>
    </div>
        <div class="form-group">
          <div v-if="showParents">
            <h4 class="mb-3">
              {{
                product.parent_title
                  ? product.parent_title
                  : "Родительский вариант продукта"
              }}
            </h4>
            <multiselect
              v-model="current_product.parent"
              track-by="name"
              label="name"
              :options="product.parent_variants"
              :show-labels="false"
              :searchable="false"
              :allow-empty="false"
              placeholder=""
              @select="getVariants"
            >
              <template slot-scope="{ option }">{{ option }} </template>
            </multiselect>
          </div>


          <div v-if="showVariants">
            <h4 class="mb-3">
              {{
                product.variant_title
                  ? product.variant_title
                  : "Вариант продукта"
              }}
            </h4>
            <multiselect
              :value="current_product.variant"
              @select="changePrice"
              track-by="name"
              label="name"
              :options="variants"
              :show-labels="false"
              :searchable="false"
              :allow-empty="false"
              placeholder=""
            >
              <template slot-scope="{ option }">{{ option }} </template>
            </multiselect>
          </div>
        </div>

        <h5 class="mb-3 mt-3 d-flex align-items-center">
          <span>Услуги по умолчанию</span>
        </h5>

        <div
          v-for="service in servicePreset"
          :key="service.id"
          class="panel"
        >
          <div class="panel-hdr">
            <h2>
              {{ service.name }}
            </h2>
            <div class="panel-toolbar">
              <button
                v-if="service.is_multiple"
                title="Копировать услугу"
                @click="cloneService(service)"
                type="button"
                class="btn btn-md btn-icon waves-effect waves-themed mr-3"
              >
                <span aria-hidden="true"><i class="fal fa-copy"></i></span>
              </button>
              <div
                class="custom-control custom-checkbox custom-control-inline"
              >
                <input
                  name="service.id"
                  type="checkbox"
                  :value="service"
                  v-model="current_product.services"
                  @change="switchServiceInProduct(service.id, $event)"
                  class="custom-control-input"
                  :disabled="inExceptions(service.id)"
                  :id="'service' + service.id"
                  :ref="'check' + service.id"
                />
                <label
                  class="custom-control-label"
                  :for="'service' + service.id"
                ></label>
              </div>
            </div>
          </div>
          <div  class="panel-content">
            <div class="frame-wrap p-3">
              <div class="d-flex">
                <!-- <div class="col-2 pl-0">
                  <label class="form-label">Количество</label>
                  <input
                    type="number"
                    @keyup="
                      changeUnitServiceQuantity(service, $event);
                      validateQuantity($event);
                    "
                    @change="
                      changeUnitServiceQuantity(service, $event);
                      validateQuantity($event);
                    "
                    class="form-control"
                    step="0.01"
                    value="0"
                  />
                </div> -->


                <div v-if="service.variants && service.variants.length" class="col-4 pl-0">
                  <label class="form-label">Варианты</label>
                  <multiselect
                    :preselect-first="true"
                    :options="service.variants"
                    v-model="service.current_variant"
                    :show-labels="false"
                    :searchable="false"
                    :allow-empty="false"
                    placeholder=""
                  >
                    <template slot-scope="{ option }"
                      >{{ option }}
                    </template>
                  </multiselect>
                </div>
                <div class="col-6 pl-0">
                  <label class="form-label">Комментарий к услуге</label>
                  <input type="text" class="form-control" v-model="serviceComments[service.id]" />
                </div>
              </div>
              <div class="col-2 pl-0 mt-2" v-if="service.is_counted">
                <label class="form-label">Количество</label>
                <input type="number" class="form-control" v-model.number="service.custom_quantity" />
              </div>
            </div>
          </div>
          <div v-if="service.price_type === '4'" class="panel-content">
            <div class="frame-wrap p-3">
              <div class="col-2 pl-0">
                <label class="form-label">Цена </label>
                <input
                  v-model="service.price.price[0].price"
                  type="number"
                  @keyup="
                    changePersonalServicePrice(service, $event);
                    validateQuantity($event);
                  "
                  @change="
                    changePersonalServicePrice(service, $event);
                    validateQuantity($event);
                  "
                  class="form-control"
                  step="0.01"
                  min="0"
                  value="0"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="this.countOfNotPresets !== 0">
          <h5 class="mb-3 mt d-flex align-items-center">
            <span>Список дополнительных услуг</span
            ><span class="ml-3 cursor-pointer" @click="switchServices"
              ><i
                class="fal"
                :class="
                  showServices
                    ? 'fa-chevron-square-up'
                    : 'fa-chevron-square-down'
                "
              ></i
            ></span>
          </h5>
          <div v-if="showServices">
            <div
              v-for="service in serviceNotPreset"
              :key="service.id"
              class="panel"
            >
              <div class="panel-hdr">
                <h2>
                  {{ service.name }}
                </h2>
                <div class="panel-toolbar">
                  <button
                    v-if="service.is_multiple"
                    title="Копировать услугу"
                    @click="cloneService(service)"
                    type="button"
                    class="btn btn-md btn-icon waves-effect waves-themed mr-3"
                  >
                    <span aria-hidden="true"><i class="fal fa-copy"></i></span>
                  </button>
                  <div
                    class="custom-control custom-checkbox custom-control-inline"
                  >
                    <input
                      name="service.id"
                      type="checkbox"
                      :value="service"
                      v-model="current_product.services"
                      @change="switchServiceInProduct(service.id, $event)"
                      class="custom-control-input"
                      :disabled="inExceptions(service.id)"
                      :id="'service' + service.id"
                      :ref="'service' + service.id"
                    />
                    <label
                      class="custom-control-label"
                      :for="'service' + service.id"
                    ></label>
                  </div>
                </div>
              </div>
              <div class="panel-content">
                <div class="frame-wrap p-3">
                  <div class="d-flex">
                    <!-- <div class="col-2 pl-0">
                      <label class="form-label">Количество</label>
                      <input
                        v-model="service.current_quantity"
                        type="number"
                        @keyup="
                          changeUnitServiceQuantity(service, $event);
                          validateQuantity($event);
                        "
                        @change="
                          changeUnitServiceQuantity(service, $event);
                          validateQuantity($event);
                        "
                        class="form-control"
                        step="0.01"
                        value="0"
                      />
                    </div> -->
                    <div v-if="service.variants && service.variants.length"  class="col-4 pl-0">
                      <label class="form-label"
                        >Варианты </label
                      >
                      <multiselect
                      :preselect-first="true"
                        :id="'ms-' + service.id"
                        :options="service.variants"
                        v-model="service.current_variant"
                        :show-labels="false"
                        :searchable="false"
                        :allow-empty="true"
                        placeholder=""
                      >
                      </multiselect>
                    </div>
                    <div class="col-6 pl-0">
                      <label class="form-label">Комментарий к услуге</label>
                      <input type="text" class="form-control" v-model="serviceComments[service.id]" />
                    </div>
                  </div>
                  <div class="col-2 pl-0 mt-2" v-if="service.is_counted">
                    <label class="form-label">Количество</label>
                    <input type="number" class="form-control" v-model.number="service.custom_quantity" />
                  </div>
                </div>
              </div>
              <div v-if="service.price_type === '4'" class="panel-content">
                <div class="frame-wrap p-3">
                  <div class="col-2 pl-0">
                    <label class="form-label">Цена </label>
                    <input
                      v-model="service.individual_price"
                      type="number"
                      @keyup="
                        changePersonalServicePrice(service, $event);
                        validateQuantity($event);
                      "
                      @change="
                        changePersonalServicePrice(service, $event);
                        validateQuantity($event);
                      "
                      class="form-control"
                      step="0.01"
                      min="0"
                      value="0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr v-if="current_product.runs == 4 || current_product.runs == 5" class="mt-4 mb-g" />
        <div v-if="current_product.runs == 4 || current_product.runs == 5" class="form-group">
          <h4 class="mt-4 mb-3" v-if="current_product.runs == 4">Площадь</h4>
          <h4 class="mt-4 mb-3" v-if="current_product.runs == 5">Периметр</h4>
          <div class="form-group">
            <label for="" class="form-label">Ширина, мм</label>
            <input
              type="number"
              class="form-control"
              step="1"
              min="0"
              v-model.number="current_product.width"
            />
          </div>
          <div class="form-group">
            <label for="" class="form-label">Высота, мм</label>
            <input
              type="number"
              class="form-control"
              step="1"
              min="0"
              v-model.number="current_product.height"
            />
          </div>
        </div>
        <hr class="mt-4 mb-g" />
        <div class="form-group">
          <h5 class="mt-3 mb-2">Макет</h5>
          <input
            type="text"
            class="form-control"
            v-model="file_url"
          />
        </div>
        <hr class="mt-4 mb-g" />
        <h5 class="mb-2">Дополнительные параметры</h5>
         <div class="form-group mt-3">
           <label for="" class="form-label"
            >Выберите место изготовления продукта</label
          >
          <multiselect
              v-model="current_manufacture"
              track-by="id"
              label="name"
              :options="manufactures"
              :show-labels="false"
              :searchable="false"
              :allow-empty="false"
              placeholder=""
              @input="getManufactureId"
            >
              <template slot-scope="{ option }">{{ option }} </template>
            </multiselect>
        </div>
        <div class="form-group">
          <label for="" class="form-label"
            >Стоимость работы дизайнера, руб.</label
          >
          <input
            type="number"
            class="form-control"
            step="0.01"
            v-model="current_product.design_price"
          />
        </div>
        <div class="form-group">
          <label for="" class="form-label"
            >Корректировка цены продукта, руб.</label
          >
          <input
            type="number"
            class="form-control"
            step="0.01"
            v-model="current_product.discount"
          />
        </div>
        <div
          class="form-group custom-control custom-checkbox custom-control-inline"
        >
          <input
            name="isManual"
            id="isManual"
            v-model="current_product.isManual"
            type="checkbox"
            class="custom-control-input"
          />
          <label
            class="custom-control-label"
            for="isManual"
          >Ручной ввод цены продукта</label>
        </div>
        <div class="form-group">
          <label for="" class="form-label">Комментарий к продукту</label>
          <textarea
            class="form-control"
            rows="5"
            v-model="current_product.comment"
          ></textarea>
        </div>
        <hr class="mt-4 mb-g" />
        <h5 class="mb-2">Превью</h5>
        <div v-if="current_product.preview.length" class="card mb-g mt-xl-5">
          <div class="row">
            <div class="col-12">
              <div class="p-3">
                <h2 class="mb-0 fs-xl">Загруженные превью</h2>
              </div>
            </div>
            <div
              v-for="(image, i) in current_product.preview"
              v-bind:key="i"
              class="col-4 position-relative mt-3 p-5"
            >
              <span
                class="position-absolute pos-top pos-right width-25 pr-5 height-5"
              >
                <button
                  @click="removeImage(image.id)"
                  type="button"
                  class="btn btn-xs btn-danger waves-effect waves-themed"
                >
                  Удалить
                </button>
              </span>
              <img :src="image.url" class="w-100" alt="" />
            </div>
          </div>
        </div>
        <file-pond
          name="filepond"
          ref="pond"
          class-name="my-pond"
          label-idle="Загрузите превью, кликнув сюда, либо перетащив на данную область файлы. Если вы скопировали файл в буфер обмена, то нажмите ctrl-v"
          labelFileProcessing="Загрузка..."
          labelFileProcessingComplete="Загрузка завершена"
          labelTapToCancel="нажмите для отмены"
          allow-multiple="true"
          accepted-file-types="image/jpeg, image/png"
        />
        <!-- <input
          type="file"
          name="preview"
          ref="preview"
          accept="image/jpeg,image/png,image/gif"
          multiple
          @change="uploadImage"
        /> -->
      </div>
      <div class="col-4">
        <div class="panel-tag border-primary">
          <span class="fw-500">Номер заказа:</span> {{orderInfo.number}}<br />
          <span class="fw-500">Клиент:</span> {{orderInfo.name}}<br />
          <span class="fw-500">Телефон:</span> {{orderInfo.phone}}<br />
          <span class="fw-500">Email:</span> {{orderInfo.email}}
        </div>
        <div v-html="product.comments" class="panel-tag"></div>
      </div>
    </div>
    <div class="float-footer">
      <div class="fixed-content d-flex align-items-center border-top" :style="hidePanel ? 'width: 100%' : ''">
        <div class="col-2">
          <label class="form-label">Количество</label>
          <input
            v-model.number="current_product.variant.price.quantity"
            @keyup="
              changeServicesQuantity;
              validateQuantity($event);
            "
            @change="
              changeServicesQuantity;
              validateQuantity($event);
            "
            v-if="product.runs !== 3"
            type="number"
            class="form-control"
            step="0.01"
            min="0"
            value="0"
          />

          <multiselect
            v-if="product.runs === 3"
            v-model="current_product.variant.price.price[0]"
            @select="changeServicesQuantity"
            track-by="quantity"
            label="quantity"
            :options="currentVariant.price"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            placeholder=""
          >
            <template slot-scope="{ option }">{{ option }} </template>
          </multiselect>
        </div>

        <div class="col-7 d-flex">
          <div
            v-for="(item, urgency) in calculatedPrice"
            @click="selectTimelineToOrder(calculatedPrice[item['type']])"
            :key="urgency"
            class="btn waves-effect waves-themed text-left p-2 mr-3 col-2"
            :class="selectedButton == item['type'] ? 'btn-primary' : 'btn-light'"

          >
            <span
              class="badge bg-transparent p-0"
              :class="
                selectedButton == item['type'] ? 'badge-primary' : 'badge-light'
              "
              >{{ calculatedPrice[item['type']]["date"][1] }}
              <span
                :class="
                  selectedButton == item['type'] ? 'color-white' : 'color-fusion-50'
                "
                >{{ product.deliver_after }}</span
              ></span
            >
            <span class="h4 d-block m-0"
              >
              {{
                !current_product.isManual
                  ? Math.floor(calculatedPrice[item['type']]["price"]["total"])
                  : ''
              }}
              <input
                type="number"
                :class="selectedButton == urgency ? 'input_calculated-primary' : 'input_calculated-light'"
                v-if="current_product.isManual"
                v-model.number="manualValue[urgency]['price']['total']" />
              <i class="fal fa-ruble-sign"></i
            ></span>
          </div>

          <!--<div class="btn btn-primary waves-effect waves-themed text-left p-2 mr-3 col-2">
            <span class="badge badge-primary bg-transparent p-0">12.03.2020</span>
            <span class="h4 d-block m-0">630 <i class="fal fa-ruble-sign"></i></span>
          </div>-->
        </div>

        <div class="col-3 d-flex flex-row-reverse">
          <input
            type="button"
            @click="storeProductOrder"
            value="Обновить"
            class="btn btn-primary btn-md btn-block waves-effect waves-themed col-6"
          />
          <button
            @click="backToOrder"
            name="reset"
            class="btn btn-default btn-md btn-block waves-effect waves-themed col-6 mt-0 mr-3"
          >
            Отменить
          </button>
        </div>
      </div>
    </div>

    <div class="modal-backdrop fade show" v-if="showLoader"></div>
    <div class="modal fade show d-block" v-if="showLoader">
      <div
        class="fixed modal-dialog modal-dialog-centered justify-content-center"
      >
        <button
          class="btn btn-info waves-effect waves-themed"
          type="button"
          disabled=""
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Загрузка...
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
/*import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'*/
import router from "@/router";
import store from "../../store";

import vueFilePond, { setOptions } from "vue-filepond";

// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";

// Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Create FilePond component
const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
  name: "ProductAdd",
  components: {
    Multiselect,
    FilePond,
  },
  data() {
    return {
      showLoader: true,
      product: {
        parent_variants: [],
        variant: [],
        service: {},
        runs: 1,
        runs_range: { 0: 0 },
      },
      current_product: {
        id: null,
        runs: 1,
        production: 1,
        preview: [],
        parent: {},
        variant: {
          price: {
            quantity: 0,
            price: [],
          },
        },
        countedPrice: {
          shipment_date: null,
          quantity: null,
          price: null,
        },
        totalPrice: {},
        services: [],
        file_url: null,
        design_price: null,
        comment: null,
        width: 0,
        height: 0,
        discount: 0,

      },
      manufactures: [],
      manufacture_id: 0,
      current_manufacture: null,
      position_number: 0,
      calculatedPrice: [],
      variants: [],
      currentVariant: {},
      services: [],
      timings: {},
      showParents: false,
      showVariants: true,
      showServices: true,
      showSquare: false,
      servicesExceptions: [],
      selectedButton: null,
      countOfNotPresets: 0,
      manualValue: [],
      hidePanel: store.state.hidePanel,
      serviceComments: [],
      errors: [],
      file_url: null,
    };
  },
  methods: {
    getManufactureId(){
      this.manufacture_id = this.current_manufacture.id;
    },
    async uploadImage() {
      let files = this.$refs.preview.files;

      for (let i = 0; i < files.length; i++) {
        let formData = new FormData();
        formData.append("filepond", files[i]);
        await axios
          .post(
            `api/auth/orders/order/save-media/${
              JSON.parse(localStorage.getItem("order")).id
            }`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            let url = res.data;
            let resArr = res.data.split("/");
            let id = resArr[resArr.length - 2];
            this.current_product.preview.push({
              id: id,
              url: url,
            });
          })
          .catch(function () {
            console.log("Ошибка загрузки!!");
          });
      }
      this.$refs.preview.value = "";
    },
    removeImage(id) {
      axios
        .delete(`api/auth/orders/order/delete-media/${id}`)
        .then(() => {
          let removeIndex = this.current_product.preview
            .map(function (item) {
              return item.id;
            })
            .indexOf(id);
          this.current_product.preview.splice(removeIndex, 1);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },
    getVariants(parent) {
      this.variants = [];
      console.log(this.product.variant);
      console.log(parent);
      for (let k in this.product.variant) {
        if (this.product.variant[k].parent_variant_id === parent.id) {
          this.variants.push(this.product.variant[k]);
        }
      }
      let currentPrice = JSON.parse(
        JSON.stringify(this.current_product.variant.price)
      );
      if (currentPrice.price.length) {
        this.current_product.variant = JSON.parse(
          JSON.stringify(this.variants[0])
        );
        this.currentVariant = JSON.parse(JSON.stringify(this.variants[0]));
        this.current_product.variant.price = currentPrice;
        if (this.product.runs === 3) {
          for (let price in this.currentVariant.price) {
            if (
              currentPrice.price[0].quantity ===
              this.currentVariant.price[price].quantity
            ) {
              this.current_product.variant.price.price[0] = this.currentVariant.price[
                price
              ];
            }
          }
        } else {
          this.current_product.variant.price.price = this.currentVariant.price;
        }
      } else {
        this.current_product.variant = JSON.parse(
          JSON.stringify(this.variants[0])
        );
        this.currentVariant = JSON.parse(JSON.stringify(this.variants[0]));
        this.current_product.variant.price = {
          quantity: 0,
          price: [],
        };
        if (this.product.runs !== 3) {
          this.current_product.variant.price.quantity = Object.keys(
            this.product.runs_range
          )[0];
          this.current_product.variant.price.price = this.currentVariant.price;
        } else {
          this.current_product.variant.price.price[0] = this.currentVariant.price[0];
        }
      }
    },
    inExceptions(id) {
      return this.servicesExceptions.indexOf(id) !== -1;
    },
    switchServices() {
      this.showServices = !this.showServices;
    },
    cloneService(service) {
      let newService = JSON.parse(JSON.stringify(service));
      if (!newService.originalId) {
        newService.originalId = newService.id;
      }
      newService.id = new Date().getTime();
      this.product.service.push(newService);
    },
    validateQuantity(e) {
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
    },
    changeServicesQuantity(e) {
      if (this.current_product.services.length) {
        for (let i in this.current_product.services) {
          if (this.current_product.services[i].price_type !== "3") {
            if (this.product.runs === 3) {
              this.current_product.services[i].price.quantity = e.quantity;
            } else {
              this.current_product.services[i].price.quantity = +e.target.value;
            }
          }
        }
      }
    },
    changePersonalServicePrice(service, event) {
      service.price.price[0].price = +event.target.value;
    },
    changeUnitServiceQuantity(service, event) {
      service.price.quantity = +event.target.value;
    },
    switchServiceInProduct(id, event) {
      let itemId = event.target.id;
      let element = document.getElementById(itemId);
      let service = this.product.service.filter(function (el) {
        return el.id === id;
      })[0];

      if (element.checked) {
        if (service.price_type !== "3") {
          if (this.product.runs === 3) {
            service.price.quantity = this.current_product.variant.price.price[0].quantity;
          } else {
            service.price.quantity = +this.current_product.variant.price
              .quantity;
          }
        }
      }

      for (let i in service.exceptions) {
        if (element.checked) {
          let removeIndex = this.current_product.services
            .map(function (item) {
              return item.id;
            })
            .indexOf(service.exceptions[i].id);
          if (removeIndex > -1) {
            let removeServiceExceptions = this.current_product.services[
              removeIndex
            ].exceptions;
            for (let e in removeServiceExceptions) {
              let removeExceptionIndex = this.servicesExceptions
                .map(function (id) {
                  return id;
                })
                .indexOf(removeServiceExceptions[e].id);
              this.servicesExceptions.splice(removeExceptionIndex, 1);
            }
            this.current_product.services.splice(removeIndex, 1);
          }
          this.servicesExceptions.push(service.exceptions[i].id);
        } else {
          let removeIndex = this.servicesExceptions
            .map(function (id) {
              return id;
            })
            .indexOf(service.exceptions[i].id);
          this.servicesExceptions.splice(removeIndex, 1);
        }
      }
    },
    changePrice(e) {
      this.currentVariant = e;

      this.current_product.variant.id = e.id;
      this.current_product.variant.is_parent = e.is_parent;
      this.current_product.variant.name = e.name;
      this.current_product.variant.parent_variant_id = e.parent_variant_id;
      this.current_product.variant.product_id = e.product_id;
      this.current_product.variant.type = e.type;
      console.log("Current Product ", this.current_product);
      if (this.product.runs === 3) {
        if (this.current_product.variant.price.price[0]) {
          for (let price in this.currentVariant.price) {
            if (
              this.current_product.variant.price.price[0].quantity ===
              this.currentVariant.price[price].quantity
            ) {
              this.current_product.variant.price.price[0] = this.currentVariant.price[
                price
              ];
            }
          }
        } else {
          this.current_product.variant.price.price[0] = this.currentVariant.price[0];
        }
      } else {
        this.current_product.variant.price.price = this.currentVariant.price;
      }
    },
    calculateVariant(value, setDefault) {
      axios
        .post("api/auth/orders/calculate/variant", value)
        .then((res) => {
          if(this.calculatedPrice.length === 0) {
            for(let key in res.data) {
              this.manualValue[key] = res.data[key]
            }
          }
          this.calculatedPrice = res.data;

          if(this.current_product.isManual) {
            this.calculatedPrice[this.selectedButton]['price']['total'] = this.current_product.totalPrice.price.total;
          }
          if (setDefault) {
            this.selectTimelineToOrder(this.calculatedPrice[setDefault]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectTimelineToOrder(data) {
      console.log("Выбранное значение цены и периода: ", data);
      this.selectedButton = data.type;
      this.current_product.totalPrice = data;
    },
    storeProductOrder() {
      this.errors = [];
      this.current_product.file_url = this.file_url
      this.manufacture_id === 0 && this.errors.push(['Необходимо выбрать место изготовления продукта'])
      !this.current_product.file_url && this.errors.push(['Поле макет обязательно для заполнения'])
      if (this.product.has_preview) {
        this.current_product.preview.length <= 0 && this.errors.push(['Поле превью обязательно для заполнения'])
      }

      if(this.errors.length){
        window.scrollTo(0,0);
        return false;
      }
      if(this.current_product.isManual) {
        let manualValue = this.manualValue[this.selectedButton]['price']['total'] || 0;
        this.current_product.totalPrice.price.total = manualValue
        this.calculatedPrice[this.selectedButton]["price"]["total"] = manualValue
      }
      this.serviceComments.forEach((comment, index) => {
        this.current_product.services.forEach(service => {
          if(service.id === index) service.comment = comment
        })
      });
      let timeline = this.selectedButton;
      if(!Number.isInteger(this.current_product.height) ||
         !Number.isInteger(this.current_product.width)) {
        alert('В поля высота и ширина можно вводить только целые значения')
        return false
      }
      let variant = {
        product_id: this.current_product.id,
        variant_id: this.current_product.variant.id,
        variant_price: this.current_product.variant.price.price,
        variant_quantity: this.current_product.variant.price.quantity,
        variant_cost: 0,
        variant_date: "",
        preview: this.current_product.preview,
        comment: this.current_product.comment,
        width: this.current_product.width,
        height: this.current_product.height,
        layout_url: this.current_product.file_url,
        design_price: this.current_product.design_price,
        services: JSON.stringify(this.current_product.services),
        current_product: JSON.stringify(this.current_product),
        discount: this.current_product.discount,
        is_manual: this.current_product.isManual,
        manufacture_id: this.manufacture_id
      };
      console.log(variant);
      let order = {
        id: JSON.parse(localStorage.getItem("order")).id,
        division_id: JSON.parse(localStorage.getItem("order")).division.id,
        customer_id: JSON.parse(localStorage.getItem("order")).customer.id,
        payment_type_id: JSON.parse(localStorage.getItem("order")).payment_type
          .id,
        order_cost: 0,
        order_number: JSON.parse(localStorage.getItem("order")).order_number,
      };
      // let a = this.current_product.totalPrice
      let a = timeline;
      let ob = this.calculatedPrice;
      console.log(timeline);
      console.log("CALC PRICE: ", this.calculatedPrice);
      console.log(ob[a]);

      console.log(a, " A");
      Object.keys(ob[a]).map(function (key) {
        let result = key.match(/discount/);
        let date = key.match(/date/);
        variant.timeline = timeline;
        if (result) {
          variant.variant_price = ob[a]["price"]["price"];
          // variant.variant_quantity = ob[a]["price"]["quantity"];
          variant.variant_cost = ob[a]["price"]["total"];
          order.order_cost = ob[a]["price"]["total"];
        }
        if (date) {
          variant.variant_date = new Date(ob[a][date.input][0]);
          order.shipment_date = new Date(ob[a][date.input][0]);
        }
      });
      console.log(variant, order);
      axios
        .put(`api/auth/orders/product/edit/${this.$route.params.id}`, {
          variant: variant,
          order: order,
        })
        .then((res) => {
          console.log(res.data);
          router.push({ name: "orderEdit", params: { id: res.data.id } });
        });
    },
    convertTotalPrice(key) {
      key.match("/discount/");
    },
    backToOrder() {
      router.push({
        name: "orderEdit",
        params: { id: JSON.parse(localStorage.getItem("order")).id },
      });
    },
    async getOrderProduct(loaded_product) {
      let productShow = await axios.get(`api/auth/product/show/${loaded_product}`);
      let current_product = productShow.data;
      console.log("CURRENT_PRODUCT ", productShow.data);
      let product_id = current_product.product_id;

      let res = await axios.get(`api/auth/orders/product/show/${product_id}`)
      console.log("original_product: ", res.data);
      this.product = res.data;
      this.current_product.id = res.data.id;
      this.current_product.runs = this.product.runs;
      this.current_product.production = this.product.production;
      this.current_product.preview = current_product.preview;
      this.current_product.width = this.product.width;
      this.current_product.height = this.product.height;

      this.position_number = current_product.position_number

      if (this.product.parent_variants.length) {
        this.current_product.parent = this.product.parent_variants[0];
        this.getVariants(this.current_product.parent);
        this.showParents = true;

        //this.servicesExceptions
      } else {
        this.current_product.variant = JSON.parse(
          JSON.stringify(this.product.variant[0])
        );
        this.currentVariant = JSON.parse(
          JSON.stringify(this.product.variant[0])
        );
        this.variants = this.product.variant;
      }
      if (this.product.is_only_services) {
        this.showParents = false;
        this.showVariants = false;
      }

      for (let i in this.product.service) {
        if(this.product.service[i].is_preset === false) {
          this.countOfNotPresets++
        }
        this.services["service" + this.product.service[i].id] = false;
        let price = JSON.parse(JSON.stringify(this.product.service[i].price));
        this.product.service[i].price = {
          quantity: 0,
          price: price,
        };
      }

      this.product.runs_range = res.data.runs_range;
      this.current_product.variant.price = {
        quantity: 0,
        price: [],
      };

      if (this.product.runs !== 3) {
        this.current_product.variant.price.quantity = Object.keys(
          this.product.runs_range
        )[0];
        this.current_product.variant.price.price = this.currentVariant.price;
      } else {
        this.current_product.variant.price.price[0] = this.currentVariant.price[0];
      }
      this.current_product = JSON.parse(
        JSON.stringify(current_product.current_product)
      );
      this.current_product.services = [];

      this.calculateVariant(this.current_product, current_product.timeline);
      this.selectedButton = current_product.timeline;
      this.showLoader = false;
      this.file_url = this.current_product.file_url
      return {
        current_product: current_product
      }
    }
  },
  computed: {
    calculateProductVariant() {
      return this.current_product;
    },
    servicePreset() {
      let newService = [];
      for (let i in this.product.service) {
        let service = this.product.service[i];
        if(service.is_preset == true) {
          newService.push(service);
        }
      }
      return newService;
    },
    serviceNotPreset() {
      let newService = [];
      for (let i in this.product.service) {
        let service = this.product.service[i];
        if(service.is_preset != true) {
          newService.push(service);
        }
      }
      return newService;
    },
    getHidePanel() {
      return store.state.hidePanel;
    },
  },
  watch: {
    current_product: {
      deep: true,
      handler(oldValue, newValue) {
        console.log(newValue);
        console.log("WATCHED");
        if(!this.isManual) {
          this.calculateVariant(newValue);
        }
      },
    },
    getHidePanel(hidePanel) {
      this.hidePanel = hidePanel;
    }
  },
  created() {
    let vm = this;
    let orderData = JSON.parse(localStorage.getItem("order"));
    this.orderInfo = {
      number: orderData.custom_number ? orderData.custom_number : orderData.order_number,
      name: orderData.customer.name,
      phone: orderData.customer.phone,
      email: orderData.customer.email,
      productsNum: orderData.product_orders.length
    }
    setOptions({
      allowRevert: false,
      allowRemove: false,
      labelFileLoading: "Загрузка...",
      labelFileLoadError: "Ошибка загрузки",
      maxParallelUploads: 1,
      server: {
        url: process.env.VUE_APP_BASE_URL,
        timeout: 7000,
        process: {
          url:
            "api/auth/orders/order/save-media/" +
            JSON.parse(localStorage.getItem("order")).id,
          method: "POST",
          headers: {
            Authorization: "Bearer " + store.state.auth.token,
            Accept: "application/json",
          },
          onload: (response) => {
            let url = response;
            let resArr = response.split("/");
            let id = resArr[resArr.length - 2];
            vm.current_product.preview.push({
              id: id,
              url: url,
            });
          },
          onerror: (response) => response.data,
          // ondata: (formData) => {
          //     formData.append('Hello', 'World');
          //     return formData;
          // }
        },
        revert: (uniqueFileId, load, error) => {
          console.log("revertId ", uniqueFileId);
          // Should remove the earlier created temp file here
          // ...

          // Can call the error method if something is wrong, should exit after
          error("oh my goodness");

          // Should call the load method when done, no parameters required
          load();
        },
        // revert: './revert',
        // restore: './restore/',
        // load: './load/',
        // fetch: './fetch/'
      },
    });

    let loaded_product = this.$route.params.id;
    let orderProduct = this.getOrderProduct(loaded_product);
    let promises = [];
    promises.push(orderProduct)
    promises.push(axios.get(`api/auth/manufacture/list`))
    Promise.all(promises).then((res) => {
      this.manufactures = res[1].data;
      let current_product = res[0].current_product;
      this.manufacture_id = current_product.manufacture_id
      this.current_manufacture = this.manufactures.filter(item => item.id === this.manufacture_id)

      for (let i in current_product.current_product.services) {
        let service = current_product.current_product.services[i];
        if (!service.originalId) {
          let serviceIndex = this.product.service
            .map(function (item) {
              return item.id;
            })
            .indexOf(service.id);
            for(let j in service) {
              this.product.service[serviceIndex][j] = service[j]
            }
            this.serviceComments[service.id] = service.comment
          // if (service.price_type === "3") {
          //   this.product.service[serviceIndex].current_quantity =
          //     service.price.quantity;
          //   this.product.service[serviceIndex].price.quantity =
          //     service.price.quantity;

          //   console.log("CV", this.product.service);
          // }
          // if (service.price_type === "4") {
          //   this.product.service[serviceIndex].price.price[0].price =
          //     service.price.price[0].price;
          //   this.product.service[serviceIndex].individual_price =
          //     service.price.price[0].price;
          // }

          // this.product.service[serviceIndex].current_variant =
          //   service.current_variant;
          this.$nextTick(()=>{
            if(!service.is_preset) {
              this.$refs["service" + service.id][0].click();
            }
            else {
              this.$refs['check'+service.id][0].click();
              this.$refs['check'+service.id][0].disabled = true;
            }
          });
        } else {
          let serviceIndex = this.product.service
            .map(function (item) {
              return item.id;
            })
            .indexOf(service.originalId);
          let newService = JSON.parse(
            JSON.stringify(this.product.service[serviceIndex])
          );
          newService.id =
            new Date().getTime() +
            Math.floor(Math.random() * Math.floor(10000));
          newService.current_quantity = service.price.quantity;
          newService.price.quantity = service.price.quantity;
          newService.current_variant = service.current_variant;
          this.product.service.push(newService);
          this.$nextTick(()=>{
            if(!service.is_preset) {
              this.$refs["service" + newService.id][0].click();
            }
          });
        }
      }
    })

  }
};
</script>

<style lang="scss" rel="stylesheet/scss">
.float-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;

  & .fixed-content {
    position: fixed;
    bottom: 0;
    width: calc(100% - 16.875rem);
    height: 100px;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.3);
  }
}

.pb-100 {
  padding-bottom: 100px;
}
.input-calculated {
  padding-left: 0;
  width: 50px;
  background: transparent;
  border: none;
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.57;
  border-bottom: 2px solid white;
}

.input_calculated-primary {
  @extend .input-calculated;
  color:white
}

.input_calculated-light {
  @extend .input-calculated;
  border-bottom: none;
  color:black
}
</style>
