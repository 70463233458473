<template>
    <div>
        <h4 class="h1-calced mb-6">Калькулятор раскладки макетов на печатном листе</h4>
        <div class="d-flex ml-6">
            <h5 class="h1-calced">Настройки</h5>
            <div class="d-flex-column ml-4">
                <div class="form-group d-flex align-items-center mb-2">
                    <div class="span-size">
                        <span class="option">Размер печатного листа:</span>
                    </div>
                    <!-- <multiselect
                    v-model="initialData.pageFormat"
                    :options="initialData.pageOptions"
                    :show-labels="false"
                    track-by="name"
                    label="name"
                    class="ml-4"
                    >
                    </multiselect> -->
                    <select v-model="initialData.pageFormat" class="ml-4">
                        <option :value="{name: 'SRA3', width: 320, height: 450}">SRA3</option>
                        <option :value="{name: 'A3', width: 297, height: 420}">A3</option>
                        <option :value="{name: 'A4', width: 210, height: 297}">A4</option>
                        <option :value="{name: 'A5', width: 148, height: 210}">A5</option>
                        <option :value="{name: 'A6', width: 105, height: 148}">A6</option>
                        <option :value="{name: 'A7', width: 74, height: 105}">A7</option>
                        <option :value="{name: 'A8', width: 52, height: 74}">A8</option>
                        <option :value="{name: 'A9', width: 37, height: 52}">A9</option>
                        <option :value="{name: 'A10', width: 26, height: 37}">A10</option>
                    </select>
                </div><hr />
                <div class="form-group d-flex align-items-center mt-1 mb-2">
                    <div class="span-size">
                        <span class="option">Обрезной размер макета (мм):</span>
                    </div>
                    <input type="number" class="form-control ml-4 col-2" v-model.number="initialData.cutLength.height" />
                    <span class="ml-2">X</span>
                    <input type="number" class="form-control ml-2 col-2" v-model.number="initialData.cutLength.width" />
                </div>
                <div class="form-group d-flex align-items-center mt-1 mb-2">
                    <div class="span-size">
                        <span class="option">Расстояние между макетами (мм):</span>
                    </div>
                    <input type="number" class="form-control col-2 ml-4" v-model.number="initialData.maketDistance" />
                </div>
                <div class="form-group d-flex align-items-center mt-1 mb-2">
                    <div class="span-size">
                        <span class="option">Размер вылетов (мм):</span>
                    </div>
                    <input type="number" class="form-control col-2 ml-4" v-model.number="initialData.radius" />
                </div>
                <div class="mt-2 d-flex">
                    <div class="span-size">
                        <span class="option">Метки реза:</span>
                    </div>
                    <div class="ml-4">
                        <div class="input-group d-flex align-items-center mb-1">
                            <input name="cutLabels" type="radio" id="yesLab" :value="true" v-model="initialData.hasCutLabels">
                            <label for="yesLab" class="mb-0 ml-2 h1-calced">Учитывать</label>
                        </div>
                        <div class="input-group d-flex align-items-center">
                            <input name="cutLabels" type="radio" id="noLab" :value="false" v-model="initialData.hasCutLabels">
                            <label for="noLab" class="mb-0 ml-2 h1-calced">Не учитывать</label>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="form-group d-flex align-items-center my-2">
                    <div class="span-size">
                        <span class="option">Тираж:</span>
                    </div>
                    <input type="number" class="form-control col-2 ml-4" v-model.number="initialData.circulation" />
                </div>
                <hr />
            </div>
        </div>

        <div class="d-flex ml-6">
            <h5 class="h1-calced">Итого</h5>
            <div class="d-flex-column ml-4">
                <div class="form-group d-flex align-items-center mb-2 mt-0">
                    <div class="span-size">
                        <span class="option">Макетов на листе:</span>
                    </div>
                    <h1 class="h1-calced pm-0 pm-2 mb-0 ml-3">{{ calculatedData.countOnPage }}</h1>
                </div>
                <div class="form-group d-flex align-items-center my-0">
                    <div class="span-size">
                        <span class="option">Печатных листов:</span>
                    </div>
                    <h1 class="h1-calced pt-0 pt-2 mb-0 ml-3">{{ calculatedData.countPrintPages }}</h1>
                </div>
                <div class="form-group d-flex align-items-center my-0">
                    <div class="span-size">
                        <span class="option option-grey">Сверх тиража: {{ calculatedData.overCirc }} шт.</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <table class="table">
            <thead class="thead-dark">
                <tr><hr />
                    <th scope="col">Формат</th>
                    <th scope="col">Размеры</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">SRA3</th>
                    <td>320 x 450</td>
                </tr>
                <tr>
                    <th scope="row">A3</th>
                    <td>297 x 420</td>
                </tr>
                <tr>
                    <th scope="row">A4</th>
                    <td>210 x 297</td>
                </tr>
                <tr>
                    <th scope="row">A5</th>
                    <td>148 x 210</td>
                </tr>
                <tr>
                    <th scope="row">A6</th>
                    <td>105 x 148</td>
                </tr>
                <tr>
                    <th scope="row">A7</th>
                    <td>74 x 105</td>
                </tr>
                <tr>
                    <th scope="row">A8</th>
                    <td>52 x 74</td>
                </tr>
                <tr>
                    <th scope="row">A9</th>
                    <td>37 x 52</td>
                </tr>
                <tr>
                    <th scope="row">A10</th>
                    <td>26 x 37</td>
                </tr>
            </tbody>
        </table> -->
    </div>
</template>

<script>
//import Multiselect from "vue-multiselect";

export default {
  name: "MaketCalculator",
  data() {
    return {
        initialData: {
            pageFormat: {name: 'SRA3', width: 320, height: 450},
            /* pageOptions: [
                {name: 'SRA3', width: 320, height: 450},
                {name: 'A3', width: 297, height: 420},
                {name: 'A4', width: 210, height: 297},
                {name: 'A5', width: 148, height: 210},
                {name: 'A6', width: 105, height: 148},
                {name: 'A7', width: 74, height: 105},
                {name: 'A8', width: 52, height: 74},
                {name: 'A9', width: 37, height: 52},
                {name: 'A10', width: 26, height: 37},
            ], */
            maketDistance: 2,
            cutLength: {
                width: 0,
                height: 0
            },
            radius: 2,
            hasCutLabels: true,
            circulation: 0,
        },
        calculatedData: {
            countOnPage: 0,
            countPrintPages: 0,
            overCirc: 0,

            totalSize: {
                width: 0,
                height: 0,
            },
        }
    }
  },
  components: {
    
  },
  watch: {
      initialData: {
        deep: true,
        handler() {
            this.calculateSizes()
            this.calculate()
        }
      }
  },
  methods: {
      calculateSizes() {
            this.calculatedData.totalSize.width = this.initialData.pageFormat.width - 10 - this.initialData.radius * 2
            this.calculatedData.totalSize.height = this.initialData.pageFormat.height - 10 - this.initialData.radius * 2
            if(this.initialData.hasCutLabels) {
                this.calculatedData.totalSize.width -= 8
                this.calculatedData.totalSize.height -= 8
            }
      },
      calculateCountOnPageRow(totalWidth, cutWidth) {
          return Math.floor((totalWidth + this.initialData.maketDistance) / (cutWidth + this.initialData.maketDistance))
      },
      calculateCountOnPageCol(totalHeight, cutHeight) {
          return Math.floor((totalHeight + this.initialData.maketDistance) / (cutHeight + this.initialData.maketDistance))
      },
      calculate() {
          let cutWidth = this.initialData.cutLength.width;
          let totalWidth = this.calculatedData.totalSize.width;
          let cutHeight = this.initialData.cutLength.height;
          let totalHeight = this.calculatedData.totalSize.height;
          let firstVar = this.calculateCountOnPageRow(totalWidth, cutWidth) * this.calculateCountOnPageCol(totalHeight, cutHeight)
          let secondVar = this.calculateCountOnPageRow(totalWidth, cutHeight) * this.calculateCountOnPageCol(totalHeight, cutWidth)
          /* console.log(this.calculateCountOnPageRow(totalWidth, cutWidth) , ' ', this.calculateCountOnPageCol(totalHeight, cutHeight))
          console.log(this.calculateCountOnPageRow(totalWidth, cutHeight) , ' ', this.calculateCountOnPageCol(totalHeight, cutWidth)) */
          let countOnPage = firstVar > secondVar ? firstVar : secondVar
          this.calculatedData.countOnPage = countOnPage
          this.calculatedData.countPrintPages = Math.ceil(this.initialData.circulation / countOnPage)
          this.calculatedData.overCirc = this.calculatedData.countPrintPages * countOnPage - this.initialData.circulation
      }
  },
  mounted() {
      this.calculateSizes();
  }
}
</script>

<style scoped>
.multiselect {
    width: auto;
}
input {
    width: auto;
    text-align: center;
}
input[type="number"] {
    height: 27px;
    width: 40px;
    color: black;
}
.h1-calced {
    color: black;
}
hr {
    border-bottom: 1px solid rgb(212, 212, 212);
}
.option-grey {
    color: #a3a3a3;
}
.span-size {
    display: inline-block;
    width: 250px;
    text-align: right;
}
.form-group {
    min-width: 500px;
}
select {
    width: 65px;
    height: 27px;
    background: white;
    border-radius: 3px;
    border: 1px solid rgb(230 230 230);
    padding-left: 4px;
}
</style>