<template>
    <div id="app">
        <div class="page-wrapper">
            <div class="page-inner">
                <Sidebar />
                <div class="page-content-wrapper">
                    <Header />
                    <main class="page-content">
                        <router-view />
                    </main>
                </div>
            </div>
        </div>
        <Modals />
      <RejectFastForm />
    </div>
</template>

<script>
    import Sidebar from "../Common/Sidebar"
    import Header from "../Common/Header"
    import Modals from '../Common/Modals'
    import RejectFastForm from "@/components/Common/Modals/RejectFastForm";
    export default {
        name: "Order",
        components: {
            Sidebar,
            Header,
            Modals,
            RejectFastForm
        }
    }
</script>

