<template>
  <div>
    <h1 class="display-4 mb-3">Создание пользователя</h1>
    <div class="alert alert-dismissible fade show"
         v-bind:class="{ 'd-none': alertTextHide, 'alert-danger': alertDanger, 'alert-success': alertSuccess}"
         role="alert">
      <button type="button" class="close" data-dismiss="alert" @click="hideAlert" aria-label="Close">
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
      </button>
      {{ alertText }}
    </div>
    <form action="" enctype="multipart/form-data" method="post" @submit.prevent="storeUser">
      <div class="form-group">
        <label for="" class="form-label">Имя</label>
        <input type="text" v-model="user.name" class="form-control" required>
      </div>
      <div class="form-group">
        <label for="" class="form-label">Email</label>
        <input type="email" v-model="user.email" class="form-control" required>
      </div>
      <div class="form-group">
        <label for="" class="form-label">Пароль</label>
        <input type="password" v-model="user.password" class="form-control" required>
      </div>
      <div class="form-group">
        <label for="" class="form-label">Повтор пароля</label>
        <input type="password" v-model="user.confirmPassword" class="form-control" required>
      </div>
      <div class="form-group" v-if="avatar">
        <img :src="avatar" alt="" width="200px" height="200px">
      </div>
      <div class="form-group">
        <label for="" class="form-label">Аватар пользователя</label>
        <input type="file" @change="changeImg" id="avatar" class="form-control">
      </div>
      <div class="form-group">
        <label for="" class="form-label">Роль</label>
        <select type="text" class="form-control" v-model="user.role" required>
          <option v-for="(role, index) in roles" v-bind:key="index" :value="role.id">{{ role.name }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="" class="form-label">Подразделение к которому относится сотрудник</label>
        <select type="text" class="form-control" v-model="user.division" required>
          <option v-for="item in divisions" v-bind:key="item.id" :selected="item.id == 'user.division ? true : false'"
                  :value="item.id">{{ item.name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <input type="submit" class="btn btn-outline-info mb-g waves-effect waves-themed" value="Сохранить">
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "CreateUser",
  data() {
    return {
      user: {
        name: null,
        email: null,
        password: null,
        confirmPassword: null,
        role: null,
        avatar: false,
        division: null
      },
      avatar: null,
      data: null,
      roles: {},
      divisions: {},
      alertText: null,
      alertTextHide: true,
      alertDanger: false,
      alertSuccess: false,
    }
  },
  methods: {
    changeImg(event) {
      this.data = this.avatar = event.target.files[0]
      this.avatar = URL.createObjectURL(this.data)
    },
    getRoles() {
      axios.get('api/auth/user/roles')
          .then(res => {
            console.log(res.data)
            this.roles = res.data.roles
          })
    },
    storeUser() {
      const fd = new FormData()
      fd.append('avatar', this.data)
      fd.append('name', this.user.name)
      fd.append('email', this.user.email)
      fd.append('password', this.user.password)
      fd.append('role', this.user.role)
      fd.append('division_id', this.user.division)
      axios.post('api/auth/user/store', fd)
          .then(res => {
            console.log(res)
            if (res.status == 200) {
              this.alertText = 'Пользователь успешно сохранён';
              this.alertTextHide = false;
              setTimeout(this.hideAlert, 5000);
              this.alertDanger = false;
              this.alertSuccess = true;
            } else {
              this.alertText = 'Ошибка сохранения пользователя';
              this.alertTextHide = false;
              this.alertDanger = true;
              this.alertSuccess = false;
            }
          })
    },
    hideAlert() {
      this.alertTextHide = true;
    }
  },
  beforeMount() {
    this.getRoles()
  },
  mounted() {
    axios.get('api/auth/divisions')
        .then(res => {
          console.log(res.data)
          this.divisions = res.data
        })
  }
}
</script>
