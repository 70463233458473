<template>
    <div>
        <h1 class="display-4 mb-3">Просмотр данных по подразделению: {{ division.name }}</h1>
        <div class="frame-wrap p-3">
            <table class="table table-striped m-0">
                <thead class="bg-primary-600">
                <tr role="row">
                    <th class="sorting_asc">ID #</th>
                    <th class="sorting_asc">Имя</th>
                    <th class="sorting_asc">Email</th>
                    <th class="sorting_asc">Дата последних изменений</th>
                    <th class="sorting_asc">Аватар пользователя</th>
                </tr>
                </thead>
                <tbody>
                <tr class="odd" v-for="user in division.user" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td><router-link :to="{ name: 'userEdit', params: { id: user.id } }" >Просмотр: {{user.name}}</router-link></td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.updated_at }}</td>
                    <td><img :src="user.avatar" alt="" width="100" height="100"></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ManageUsers",
        data() {
            return {
                division: {}
            }
        },
        methods:{

        },
        mounted() {
            if (this.$route.params.id != undefined) {
                axios.get(`api/auth/divisions/showWithUser/${this.$route.params.id}`)
                    .then(res => {
                        console.log(res.data)
                        this.division = res.data
                    })
            }
        }
    }
</script>

<style scoped>

</style>