import axios from "axios";

export default {
    namespaced: true,
    state: {
        token: null,
        user: null
    },

    mutations: {
        SET_TOKEN(state, token){
            state.token = token
        },
        SET_USER(state, data){
            state.user = data
        }
    },

    getters: {
        authenticated(state){
            return state.token && state.user
        },

        user(state){
            return state.user
        }
    },

    actions: {
        async login ({ dispatch }, credentials) {
            try {
                let response = await axios.post('api/auth/login', credentials)
                return dispatch('attempt', response.data.token)
            } catch(e){
                if(e.response.status == 401){
                    return 'Incorrect login';
                }else{
                    return 'Auth error';
                }
            }

        },

        async attempt ({ commit, state }, token){
            if (token){
                commit('SET_TOKEN', token)

                try {
                    let response = await axios.get('api/auth/me')
                    // console.log(response.data)
                    commit('SET_USER', response.data)
                } catch (e) {
                    commit('SET_TOKEN', null)
                    commit('SET_USER', null)
                }
            }

            if (!state.token){
                return
            }

        }
    },
}
