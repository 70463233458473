<template>
  <div id="app">
    <div class="page-wrapper">
      <div class="page-inner">
        <Sidebar />
        <div class="page-content-wrapper">
          <Header />
          <main class="page-content">
            <div class="subheader">
              <h1
                class="subheader-title"
                style="border-left: 4px solid #000000; padding-left: 20px"
              >
                Настройки
              </h1>
            </div>
            <h3 class="mt-5">Выбор подразделения</h3>
            <div class="panel-container show">
              <div class="d-flex align-items-center">
                <label class="form-label mr-3" for="chooseWorkspaceTop"
                  ><nobr>Текущее подразделение:</nobr></label
                >
                <multiselect
                  id="chooseWorkspaceTop"
                  v-model="division"
                  track-by="name"
                  label="name"
                  @input="selectDivision"
                  :options="divisions"
                  :show-labels="false"
                  :searchable="true"
                  :allow-empty="false"
                  placeholder="Текущее подразделение"
                >
                  <template slot-scope="{ option }">{{ option }}</template>
                </multiselect>
              </div>
            </div>
            <h3 class="mt-5">Загрузить аватар</h3>
            <file-pond
              name="filepond"
              ref="pond"
              class-name="my-pond"
              label-idle="Загрузите аватар, кликнув сюда, либо перетащив на данную область файлы. Если вы скопировали файл в буфер обмена, то нажмите ctrl-v"
              labelFileProcessing="Загрузка..."
              labelFileProcessingComplete="Загрузка завершена"
              labelTapToCancel="нажмите для отмены"
              allow-multiple="true"
              accepted-file-types="image/jpeg, image/png"
            />
          </main>
        </div>
      </div>
    </div>
    <Modals />
  </div>
</template>

<script>
//import store from "../../store";
import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import Modals from "../Common/Modals";
import axios from "axios";
import Multiselect from "vue-multiselect";
import main from "../../store/index";
import store from "../../store";

import vueFilePond, { setOptions } from "vue-filepond";

// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";

// Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
);

export default {
  name: "Settings",
  components: {
    Sidebar,
    Header,
    Modals,
    Multiselect,
    FilePond,
  },
  data() {
    return {
      division: null,
      divisions: [],
      id: this.$attrs.id
    };
  },
  methods: {
    selectDivision() {
      localStorage.setItem("user_division", JSON.stringify(this.division));
      main.state.workspace = this.division;
    },
  },
  beforeMount() {
    axios.get("api/auth/divisions").then((res) => {
      this.divisions = res.data;
      if (localStorage.getItem("user_division")) {
        this.division = JSON.parse(localStorage.getItem("user_division"));
      }
    });
  },
  mounted() {
    setOptions({
      allowRevert: false,
      allowRemove: false,
      labelFileLoading: "Загрузка...",
      labelFileLoadError: "Ошибка загрузки",
      server: {
        url: process.env.VUE_APP_BASE_URL,
        timeout: 7000,
        process: {
          url:
            "api/auth/user/update/save-media/" +
            store.getters["auth/authenticated"].id,
          method: "POST",
          headers: {
            Authorization: "Bearer " + store.state.auth.token,
            Accept: "application/json",
          },
          onload: () => {
            this.$router.go()
          },
          onerror: (response) => response.data,
        },
        revert: (uniqueFileId, load, error) => {
          console.log("revertId ", uniqueFileId);
          // Should remove the earlier created temp file here
          // ...

          // Can call the error method if something is wrong, should exit after
          error("oh my goodness");

          // Should call the load method when done, no parameters required
          load();
        },
      },
    });
  },
};
</script>

<style scoped lang="scss">
.point {
  cursor: pointer;
}
</style>
