import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import products from "./products"
import push from "./push"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    workspace: null,
    hidePanel: false,
    manufacture: { product_id: 0 }
  },
  mutations: {
    SET_HIDE_PANEL(state) {
      state.hidePanel = !state.hidePanel;
      let user = localStorage.getItem('user');
      if(user) {
        user = JSON.parse(user);
        user.hidePanel = state.hidePanel;
      }
      else user = {hidePanel: 'false'}
      //Убрать метод после полноценного получения user в main
      localStorage.setItem('user', JSON.stringify(user));
    },
    SET_LOCAL_PANEL(state) {
      if(JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).hidePanel){
        state.hidePanel = JSON.parse(localStorage.getItem('user')).hidePanel
      }else{
        state.hidePanel = false
      }
      //state.hidePanel = JSON.parse(localStorage.getItem('user')).hidePanel || false
    },
    SET_MANUFACTURE_PRODUCT(state, id) {
      state.manufacture.product_id = id;
    },
  },
  actions: {
    setHideFromLocal(context) {
      context.commit('SET_LOCAL_PANEL')
    },
    setManufactureProduct({ commit }, id) {
      commit('SET_MANUFACTURE_PRODUCT', id)
    }
  },
  getters: {
    getManufactureProductId: state => {
      return state.manufacture.product_id;
    }
  },
  modules: {
    auth,
    products,
    push
  }
})
