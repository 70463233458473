<template>
  <div>
    <form
      @submit.prevent="searchOrder"
      class="input-group input-group-lg mb-5 shadow-1 rounded"
    >
      <input
        v-model="search"
        type="text"
        class="form-control shadow-inset-2"
        id="filter-icon"
        aria-label="type 2 or more letters"
        placeholder="Поиск заказов (введите номер заказа, email или телефон клиента)"
        required
        minlength="3"
        value
      />
      <div class="input-group-append">
        <button
          class="btn btn-primary hidden-sm-down waves-effect waves-themed"
          type="submit"
        >
          <i class="fal fa-search mr-lg-2"></i>
          <span class="hidden-md-down">Найти</span>
        </button>
      </div>
    </form>
    <div v-if="showSearchResults" class="mb-5">
      <table
        v-if="searchResults.length > 0"
        class="table table-bordered table-hover table-striped w-100 dataTable dtr-inline"
      >
        <thead class="bg-primary-600">
          <tr role="row">
            <th class="sorting_asc">Номер</th>
            <th class="sorting_asc" v-if="showName">Название</th>
            <th class="sorting_asc">Имя клиента</th>
            <th class="sorting_asc">Телефон</th>
            <th class="sorting_asc">Email</th>
            <th class="sorting_asc">Содержимое заказа</th>
            <th class="sorting_asc">Дата выдачи</th>
            <th class="sorting_asc">Статус</th>
            <th class="sorting_asc">Управление</th>
          </tr>
        </thead>
        <tbody>
          <tr class="odd" v-for="(item, index) in searchResults" :key="index" :class="item.status == 'rejected' ? 'color-danger-300' : ''">
            <td class="align-middle">
              {{ item.custom_number ? item.custom_number : item.order_number }}
            </td>
            <td class="align-middle" v-if="showName">
              {{ item.name ? item.name : "" }}
            </td>
            <td class="align-middle">
              {{ item.customer ? item.customer.name : "" }}
            </td>
            <td class="align-middle">
              {{ item.customer ? item.customer.phone : "" }}
            </td>
            <td class="align-middle">
              {{ item.customer ? item.customer.email : "" }}
            </td>
            <td>
              <p v-for="(product, index) in item.product_orders" :key="index">
                {{ product.name }}
              </p>
            </td>
            <td class="align-middle">{{ item.shipment_date }}</td>
            <td
              class="align-middle"
              :class="
              [
                (item.published == true || item.order_type == 'fast'
                ? getStatusColor(item.status)
                : 'color-warning-300'),
                (item.status == 'rejected' ? 'fw-500' : '')
                ]
            "
            >
              {{
                item.published == false && item.order_type !== "fast"
                  ? "Черновик"
                  : getStatusName(item.status)
              }}
            </td>
            <td class="point align-middle">
            <button
              title="Редактировать заказ"
              type="button"
              @click.prevent="editOrder(item.id)"
              class="btn btn-md btn-icon waves-effect waves-themed mr-3"
               v-if="!item.published || item.order_type == 'fast' || item.status == 'order_edit'"
            >
              <span aria-hidden="true">
                <i class="fal fa-edit"></i>
              </span>
            </button>
            <button
              title="Просмотреть заказ"
              type="button"
              @click.prevent="editOrder(item.id)"
              class="btn btn-md btn-icon waves-effect waves-themed mr-3"
              v-if="item.published"
            >
              <span aria-hidden="true">
                <i class="fal fa-eye"></i>
              </span>
            </button>
            <button
              title="Копировать заказ"
              type="button"
              @click.prevent="copyOrder(item.id)"
              class="btn btn-md btn-icon waves-effect waves-themed mr-3"

            >
              <span aria-hidden="true">
                <i class="fal fa-copy"></i>
              </span>
            </button>
            <button
              title="Удалить заказ"
              type="button"
              @click.prevent="removeOrder(item.id)"
              class="btn btn-md btn-icon waves-effect waves-themed"
               v-if="!item.published"
            >
              <span aria-hidden="true">
                <i class="fal fa-trash-alt"></i>
              </span>
            </button>
          </td>
          </tr>
        </tbody>
      </table>
      <p v-if="searchResults.length == 0">
        По вашему запросу ничего не найдено
      </p>
    </div>
    <div
      class="d-flex flex-wrap align-items-center pl-3 pr-1 px-sm-4 px-lg-5 border-faded border-top-0 border-left-0 border-right-0 mb-5"
    >
      <div class="flex-1 d-flex align-items-center">
        <ul
          id="demo_panel-tabs"
          class="nav nav-tabs border-bottom-0 nav-tabs-clean"
          role="tablist"
        >
          <li class="nav-item">
            <span
              @click="setActiveTab('published')"
              class="nav-link cursor-pointer"
              :class="activeTab == 'published' ? 'active' : ''"
              >Не завершенные</span
            >
          </li>
          <li class="nav-item">
            <span
              @click="setActiveTab('rejected')"
              class="nav-link cursor-pointer position-relative"
              :class="activeTab == 'rejected' ? 'active' : ''"
              >Отклоненные заказы
               <span
                v-if="statuses.rejected > 0"
                class="badge border border-light rounded-pill bg-danger-500 position-absolute pos-top pos-right"
                >{{ statuses.rejected }}</span>
              </span>

          </li>
          <li class="nav-item">
            <span
              @click="setActiveTab('draft')"
              class="nav-link cursor-pointer"
              :class="activeTab == 'draft' ? 'active' : ''"
              >Черновики</span
            >
          </li>
          <li class="nav-item">
            <span
              @click="setActiveTab('site')"
              class="nav-link cursor-pointer position-relative"
              :class="activeTab == 'site' ? 'active' : ''"
            >
              Заказы с сайта
              <span
                v-if="statuses.site > 0"
                class="badge border border-light rounded-pill bg-danger-500 position-absolute pos-top pos-right"
                >{{ statuses.site }}</span
              >
            </span>
          </li>
          <li class="nav-item">
            <span
              @click="setActiveTab('pass_to_customer')"
              class="nav-link cursor-pointer position-relative"
              :class="activeTab == 'pass_to_customer' ? 'active' : ''"
            >
              Выданные заказы
            </span>
          </li>
        </ul>
      </div>
      <div class="text-muted mr-1 mr-lg-3 ml-auto">
        {{ pageStart }} - {{ pageEnd }}
        <span class="hidden-lg-down">из {{ pagination.meta.total }}</span>
      </div>
      <div class="d-flex flex-wrap">
        <button
          v-if="pagination.links.prev"
          @click.prevent="getOrdersList(pagination.currentPage - 1)"
          class="btn btn-icon rounded-circle waves-effect waves-themed"
        >
          <i class="fal fa-chevron-left fs-md"></i>
        </button>
        <button
          v-if="pagination.links.next"
          @click.prevent="getOrdersList(pagination.currentPage + 1)"
          class="btn btn-icon rounded-circle waves-effect waves-themed"
        >
          <i class="fal fa-chevron-right fs-md"></i>
        </button>
      </div>
    </div>
    <table
      class="table table-bordered table-hover table-striped w-100 dataTable dtr-inline"
    >
      <thead class="bg-primary-600">
        <tr role="row">
          <th class="sorting_asc">Номер</th>
          <th class="sorting_asc" v-if="showName">Название</th>
          <th class="sorting_asc">Имя клиента</th>
          <th class="sorting_asc">Телефон</th>
          <th class="sorting_asc">Email</th>
          <th class="sorting_asc">Содержимое заказа</th>
          <th class="sorting_asc">Дата выдачи</th>
          <th class="sorting_asc">Статус</th>
          <th class="sorting_asc">Управление</th>
        </tr>
      </thead>
      <tbody>
        <tr class="odd" v-for="(item, index) in ordersList" :key="index" :class="item.status == 'rejected' ? 'color-danger-300' : ''" >

          <td class="align-middle">
            <i
              v-if="item.order_type == 'fast'"
              class="fal fa-bolt mr-3 color-danger-300"
            ></i>
            {{ item.custom_number ? item.custom_number : item.order_number }}
          </td>
          <td class="align-middle" v-if="showName">
            {{ item.name ? item.name : "" }}
          </td>
          <td class="align-middle">
            {{ item.customer ? item.customer.name : "" }}
          </td>
          <td class="align-middle">
            {{ item.customer ? item.customer.phone : "" }}
          </td>
          <td class="align-middle">
            {{ item.customer ? item.customer.email : "" }}
          </td>
          <td>
            <p v-for="(product, index) in item.product_orders" :key="index">
              {{ product.name }}
            </p>
          </td>
          <td class="align-middle">{{ item.shipment_date }}</td>
          <td
            class="align-middle"
            :class="
              [
                (item.published == true || item.order_type == 'fast'
                ? getStatusColor(item.status)
                : 'color-warning-300'),
                (item.status == 'rejected' ? 'fw-500' : '')
                ]
            "
          >
            {{
              item.published == false && item.order_type !== "fast"
                ? "Черновик"
                : getStatusName(item.status)
            }}
          </td>
          <td class="point align-middle">
            <button
              title="Редактировать заказ"
              type="button"
              @click.prevent="editOrder(item.id)"
              class="btn btn-md btn-icon waves-effect waves-themed mr-3"
               v-if="!item.published || item.order_type == 'fast' || item.status == 'order_edit'"
            >
              <span aria-hidden="true">
                <i class="fal fa-edit"></i>
              </span>
            </button>
            <button
              title="Просмотреть заказ"
              type="button"
              @click.prevent="editOrder(item.id)"
              class="btn btn-md btn-icon waves-effect waves-themed mr-3"
              v-if="item.published"
            >
              <span aria-hidden="true">
                <i class="fal fa-eye"></i>
              </span>
            </button>
            <button
              title="Копировать заказ"
              type="button"
              @click.prevent="copyOrder(item.id)"
              class="btn btn-md btn-icon waves-effect waves-themed mr-3"
            >
              <span aria-hidden="true">
                <i class="fal fa-copy"></i>
              </span>
            </button>
            <button
              title="Удалить заказ"
              type="button"
              @click.prevent="removeOrder(item.id)"
              class="btn btn-md btn-icon waves-effect waves-themed"
               v-if="!item.published"
            >
              <span aria-hidden="true">
                <i class="fal fa-trash-alt"></i>
              </span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <nav aria-label="pagination">
      <ul class="pagination">
        <li class="page-item" :class="!pagination.links.prev ? 'disabled' : ''">
          <a
            v-if="pagination.links.prev"
            @click.prevent="getOrdersList(pagination.currentPage-1)"
            class="page-link"
            href="#"
          >Назад</a>
          <span v-else class="page-link">Назад</span>
        </li>
        <li
          v-for="page in this.pagination.meta.last_page"
          :key="page"
          class="page-item"
          :class="page === pagination.currentPage ? 'active' : ''"
        >
          <a
            v-if="pagination.currentPage !== page"
            @click.prevent="getOrdersList(page)"
            class="page-link"
            href="#"
          >{{page}}</a>
          <span v-else class="page-link">
            {{page}}
            <span class="sr-only">(current)</span>
          </span>
        </li>
        <li class="page-item" :class="!pagination.links.next ? 'disabled' : ''">
          <a
            v-if="pagination.links.next"
            @click.prevent="getOrdersList(pagination.currentPage+1)"
            class="page-link"
            href="#"
          >Далее</a>
          <span v-else class="page-link">Далее</span>
        </li>
      </ul>
    </nav>-->
    <div class="modal-backdrop fade show" v-if="showLoader"></div>
    <div class="modal fade show d-block" v-if="showLoader">
    <div class="fixed modal-dialog modal-dialog-centered justify-content-center">
      <button
        class="btn btn-info waves-effect waves-themed"
        type="button"
        disabled=""
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Загрузка...
      </button>
    </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import main from "../../store/index";
export default {
  name: "OrdersList",
  data() {
    return {
      showName: false,
      showLoader: true,
      ordersList: [],
      pagination: {
        links: {},
        meta: {},
        currentPage: 1,
      },
      pageStart: 1,
      pageEnd: 20,
      activeTab: "published",
      search: "",
      showSearchResults: false,
      searchResults: [],
      statuses: {},
      division: {},
      status_list: [
        {
          value: "new",
          text: "Новый",
          color: "color-primary-300"
        },
        {
          value: "approved",
          text: "В работе",
          color: "color-info-300"
        },
        {
          value: "ready",
          text: "Готов",
          color: "color-success-200"
        },
        {
          value: "delivered",
          text: "Ожидает выдачи",
          color: "color-success-600"
        },
        {
          value: "pass_to_customer",
          text: "Выдан",
          color: "color-success-600"
        },
        {
          value: "rejected",
          text: "Отклонен",
          color: "color-danger-300"
        },
         {
          value: "order_edit",
          text: "Корректировка заказа",
          color: "color-danger-300"
        },
        {
          value: "not needed",
          text: "Не востребован",
          color: "color-secondary-300"
        },
        {
          value: "denied",
          text: "Отказ",
          color: "color-danger-300"
        },
      ],
    };
  },
  methods: {
    setActiveTab(activeTab) {
      this.activeTab = activeTab;
      this.getOrdersList(1);
      this.ordersStatuses();
      if (activeTab === 'burning') {
        this.showName = true;
      }
    },
    getStatusName(status) {
      for (let i in this.status_list) {
        if (this.status_list[i].value == status) {
          return this.status_list[i].text;
        }
      }
    },
    getStatusColor(status) {
      for (let i in this.status_list) {
        if (this.status_list[i].value == status) {
          return this.status_list[i].color;
        }
      }
    },
    getOrdersList(page) {
      let currentPage = page ? page : 1;
      this.pagination.currentPage = currentPage;
      this.showLoader = true
      axios
        .get(
          `api/auth/polk/get/orders?page=${currentPage}&status=${this.activeTab}&division_id=${this.division.id}`
        )
        .then((res) => {
          console.log(res)
          if (res.status == 200) {
            this.ordersList = res.data.data;
            this.pagination.links = res.data.links;
            this.pagination.meta = res.data.meta;
            this.pageStart =
              (res.data.meta.current_page - 1) * res.data.meta.per_page + 1;
            this.pageEnd =
              res.data.meta.current_page * res.data.meta.per_page <=
              res.data.meta.total
                ? res.data.meta.current_page * res.data.meta.per_page
                : res.data.meta.total;
          } else {
            console.log(res);
          }
          this.showLoader = false
        });
    },
    ordersStatuses() {
      axios
        .get(`api/auth/polk/orders/statuses?division_id=${this.division.id}`)
        .then((res) => {
          console.log('statuses', res.data);
          this.statuses = res.data;
        });
    },
    editOrder(id) {
      router.push({ name: "orderEdit", params: { id: id } });
    },
    removeOrder(id) {
      if (confirm("Удалить заказ?")) {
        axios.delete(`api/auth/orders/order/destroy/${id}`).then(() => {
          this.getOrdersList();
        });
      } else {
        return false;
      }
    },
    copyOrder(id) {
      axios.post(`api/auth/orders/order/copy/${id}`).then(() => {
        this.getOrdersList();
        alert('Заказ успешно скопирован и помещён в черновики.')
      });
    },
    searchOrder() {
      this.showLoader = true
      axios
        .post(`api/auth/polk/order/search/?division_id=${this.division.id}`, {
          text: this.search,
        })
        .then((res) => {
          console.log(res.data);
          this.showSearchResults = true;
          this.searchResults = res.data;
          this.showLoader = false
        });
    },
  },
  computed: {
    getOrderListTab() {
      return main.state.push.orderListTab;
    },
  },
  watch: {
    getOrderListTab(tab) {
      if (tab !== "") {
        this.setActiveTab(tab);
      }
    },
  },
  mounted() {
    this.division = JSON.parse(localStorage.getItem("user_division"));
    if (this.$route.params.activeTab) {
      this.setActiveTab(this.$route.params.activeTab);
    } else {
      this.setActiveTab("published");
    }
  },
};
</script>

<style scoped>
</style>
