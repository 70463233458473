import Order from "../components/Order/Order"
import OrdersList from "../components/Order/OrdersList"
import OrderCreate from "../components/Order/OrderCreate"
import OrderEdit from "../components/Order/OrderEdit"
import ProductSearch from "../components/Order/ProductSearch"
import ProductAdd from "../components/Order/ProductAdd"
import ProductEdit from "@/components/Order/ProductEdit";
import ProductView from "@/components/Order/ProductView";

import authMiddleware from './middleware/auth'
import orderMiddleware from './middleware/order'

export default [
    {
      path: '/order',
      name: 'order',
      component: Order,
      meta: {
        middleware: [
          authMiddleware, orderMiddleware
        ],
      },
      children: [
          {
              path: '/order/list',
              name: 'orderList',
              component: OrdersList,
              props: true,
              meta: {
                middleware: [
                  authMiddleware, orderMiddleware
                ],
              },
          },
          {
              path: '/order/create',
              name: 'orderCreate',
              component: OrderCreate,
              meta: {
                middleware: [
                  authMiddleware, orderMiddleware
                ],
              },
          },
          {
              path: '/order/edit/:id',
              name: 'orderEdit',
              component: OrderEdit,
              props: true,
              meta: {
                middleware: [
                  authMiddleware, orderMiddleware
                ],
              },
          },
          {
              path: '/product/edit/:id',
              name: 'orderProductEdit',
              component: ProductEdit,
              props: true,
              meta: {
                middleware: [
                  authMiddleware, orderMiddleware
                ],
              },
          },
          {
              path: '/product/view/:id',
              name: 'orderProductView',
              component: ProductView,
              props: true,
              meta: {
                middleware: [
                  authMiddleware, orderMiddleware
                ],
              },
          },
          {
              path: '/order/orders/order-product/edit/:id',
              name: 'productSearch',
              component: ProductSearch,
              meta: {
                middleware: [
                  authMiddleware, orderMiddleware
                ],
              },
          },
          {
              path: '/order/create/product/:id',
              name: 'productAdd',
              component: ProductAdd,
              props: true,
              meta: {
                middleware: [
                  authMiddleware, orderMiddleware
                ],
              },
          }
      ]
    }
]
