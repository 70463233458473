<template>
    <div>
        <h1 class="display-4 mb-3">Создание категории</h1>
        <div class="alert alert-dismissible fade show"
             v-bind:class="{ 'd-none': alertTextHide, 'alert-danger': alertDanger, 'alert-success': alertSuccess}"
             role="alert">
            <button type="button" class="close" data-dismiss="alert" @click="hideAlert" aria-label="Close">
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
            {{alertText}}
        </div>
        <form action="" enctype="multipart/form-data" method="post" @submit.prevent="save">
            <div class="form-group">
                <label for="" class="form-label">Родительская категория</label>
                <multiselect required v-model="categories" track-by="name" label="name" :options="allCategories"
                             :searchable="true" :show-labels="false" placeholder="">
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                </multiselect>
            </div>
            <div class="form-group">
                <label for="" class="form-label">Ниаменование</label>
                <input type="text" v-model="category" class="form-control" required>
            </div>
            <div class="form-group">
                <div class="d-flex flex-row-reverse">
                    <button type="submit" class="btn btn-primary btn-lg btn-block waves-effect waves-themed col-2">
                        Сохранить
                    </button>
                </div>

            </div>

        </form>
    </div>
</template>

<script>
    import axios from 'axios'
    //import { mapGetters } from 'vuex'
    import Multiselect from 'vue-multiselect'

    export default {
        name: "CreateCategory",
        components: {
            Multiselect
        },
        data() {
            return {
                categories: null,
                category: null,
                alertText: null,
                alertTextHide: true,
                alertDanger: false,
                alertSuccess: false,
                allCategories: []
            }
        },
        methods: {
            save() {
                axios.post('api/auth/category/store', {
                    name: this.category,
                    category_id: this.categories.id ? this.categories.id : 0
                })
                    .then(res => {
                        if (res.status == 201) {
                            this.category = '';
                            this.alertText = res.data;
                            this.alertTextHide = false;
                            setTimeout(this.hideAlert, 5000);
                            this.alertDanger = false;
                            this.alertSuccess = true;
                        } else {
                            this.alertText = res.data;
                            this.alertTextHide = false;
                            this.alertDanger = true;
                            this.alertSuccess = false;
                        }
                    })
            },
            hideAlert() {
                this.alertTextHide = true;
            }
        },
        mounted() {
            axios.get('api/auth/category')
                .then(res => {
                    if (res.status == 200) {
                        this.allCategories = res.data
                    } else {
                        console.log(res);
                    }
                })
        }
    }
</script>
