<template>
  <div>
    <div class="subheader">
      <h1 class="display-4 mb-0">Создание заказа</h1>
    </div>
    <div class="row">
      <div class="col-7">
        <div
          v-if="this.errors.length !== 0"
          class="alert alert-danger"
          role="alert"
        >
          <h1><strong>Пожалуйста, исправьте ошибки:</strong></h1>
          <ul>
            <li v-for="error in this.errors" :key="error[0]">{{ error[0] }}</li>
          </ul>
        </div>
        <div class="row">
           <div class="col-6">

              <h3 class="border-bottom mb-3">Информация о заказе</h3>
        <div class="form-group mb-2">
          <p class="fw-700 mt-3 mb-1">Номер заказа</p>
          <input v-model="order.custom_number" type="text" class="form-control" />
        </div>
        <form action="" enctype="multipart/form-data" method="post">
          <div class="form-group mb-2">
            <p class="fw-700 mt-1 mb-1">Дата выдачи</p>
            <date-picker
              v-model="order.shipment_date"
              format="DD-MM-YYYY HH:mm"
              type="datetime"
              :disabled-date="disabledBeforeToday"
              :lang="lang"
              valueType="format"
              :placeholder="order.datePlaceholder"
            ></date-picker>
          </div>
          <div class="form-group mb-2">
            <p class="fw-700 mt-1 mb-1">Место выдачи</p>
            <multiselect
              v-model="order.delivery_type"
              :options="delivery_types"
              @select="clearAddress"
              track-by="name"
              label="name"
              :show-labels="false"
              :searchable="true"
              :allow-empty="false"
              placeholder=""
            >
              <template slot-scope="{ option }">{{ option }}</template>
            </multiselect>
            <div v-if="order.delivery_type.id === 0">
              <p class="fw-700 mt-1 mb-1">Выберите копицентр</p>
              <multiselect
                v-model="order.delivery_division"
                track-by="name"
                label="name"
                :options="divisions"
                :show-labels="false"
                :searchable="true"
                :allow-empty="true"
                placeholder=""
                @select="setOrderAdress"
              >
                <template slot-scope="{ option }">{{ option }}</template>
              </multiselect>
            </div>
            <div v-else>
              <p class="fw-700 mt-1 mb-1">Введите адрес доставки</p>
              <input
                type="text"
                v-model="order.shipment_address.address[0].address"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group mb-2">
            <p class="fw-700 mt-1 mb-1">Способ оплаты</p>
            <multiselect
              v-model="order.payment_type"
              track-by="name"
              label="name"
              :options="payment_types"
              :show-labels="false"
              :searchable="true"
              :allow-empty="false"
              placeholder=""
            >
              <template slot-scope="{ option }">{{ option }}</template>
            </multiselect>
          </div>
          <div class="form-group mb-2">
            <p class="fw-700 mt-1 mb-1">Комментарий к заказу</p>
            <textarea v-model="order.comment" type="text" class="form-control"></textarea>
          </div>
        </form>
          </div>
          <div class="col-6">
            <h3 class="border-bottom mb-3">Информация о клиенте</h3>
            <form action="" enctype="multipart/form-data" method="post">
              <!--                    <div class="form-group mt-5">
                                  <input type="text" class="form-control" @input="searchCustomer" v-model="customer.search"
                                         placeholder="Поиск ...">
                                  <ul v-if="Object.keys(searchResult).length > 0">
                                      <li v-for="item in searchResult" :key="item.id">
                                          {{ item.name }}
                                      </li>
                                  </ul>
                              </div>-->
              <div class="form-group mb-2">
                <p class="fw-700 mt-3 mb-1">Фамилия Имя Отчество</p>
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="order.customer.name"
                />
              </div>
              <div class="form-group mb-2">
                <p class="fw-700 mt-1 mb-1">Email</p>
                <input
                  type="email"
                  class="form-control"
                  placeholder="mail@mail.ru"
                  required
                  v-model="order.customer.email"
                />
              </div>
              <div class="form-group mb-2">
                <p class="fw-700 mt-1 mb-1">Телефон</p>
                <masked-input
                  required
                  v-model="order.customer.phone"
                  class="form-control"
                  mask="\+1 (111) 111-11-11"
                  placeholder="+7 (999) 555-55-55"
                />
              </div>
            </form>
          </div>
         
        </div>



      </div>
      <div class="col-5 text-center">
        <button
          class="btn btn-md btn-info waves-effect waves-themed"
          @click="createCustomer"
        >
          <span class="fal fa-plus mr-1"></span>
          Добавить продукт в заказ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import MaskedInput from "vue-masked-input";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";
import dateFormat from "dateformat"

export default {
  name: "OrderCreate",
  data() {
    return {
      order: {
        custom_number: null,
        customer: {},
        user_id: this.$store.getters["auth/user"].id,
        division_id: JSON.parse(localStorage.getItem("user_division")).id,
        shipment_date: null,
        datePlaceholder: '',
        delivery_type: {
          id: 0,
          name: "Копицентр",
        },
        //delivery_division: null,
        shipment_address: {
          id: 0,
          address: [
            {
              address: "",
            },
          ],
        },
        payment_type: {
          id: 1,
          name: "наличные",
        },
        comment: null,
        promocode: null,
        prepaid: null,
        design_price: null,
        production_price: null,
        postpaid: null,
        discount_markup: null,
        total: null,
      },
      searchResult: {},
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      divisions: [],
      errors: [],
      delivery_types: [
        {
          id: 0,
          name: "Копицентр",
        },
        {
          id: 1,
          name: "Адрес",
        },
        {
          id: 2,
          name: "Главпункт",
        },
        {
          id: 3,
          name: "Pickpoint",
        },
        {
          id: 4,
          name: "Boxberry",
        },
      ],
      payment_types: [],
    };
  },
  components: {
    MaskedInput,
    DatePicker,
    Multiselect,
  },
  methods: {
    createCustomer() {
      // console.log();
      // this.$router.push('/order/create/search')
      axios
        .post(
          `api/auth/orders/create/${
            JSON.parse(localStorage.getItem("user_division")).id
          }`,
          this.order
        )
        .then((res) => {
          console.log(res.data); //Здесь получаем данные нового заказа, с id заказа
          localStorage.setItem("order", JSON.stringify(res.data));
          this.$router.push(`orders/order-product/edit/${res.data.id}`); //передаем информацию о вновь созданном заказе в продукт и задаем id заказа
        }).catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    searchCustomer() {
      axios.get(`api/auth/customer/search/${this.customer.search}`).then((res) => {
        console.log(res.data);
        this.searchResult = res.data;
      });
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    setOrderAdress(data) {
      console.log(data);
      this.order.shipment_address = data;
    },
    clearAddress() {
      this.order.shipment_address = {
        id: 0,
        address: [
          {
            address: "",
          },
        ],
      };
    },
  },
  mounted() {
    axios.get("api/auth/divisions").then((res) => {
      console.log(res.data);
      for (let k in res.data) {
        if (
          res.data[k].type === true &&
          (res.data[k].division_type == "Экспресс" ||
            res.data[k].division_type == "Обычный")
        ) {
          this.divisions.push(res.data[k]);
        }
        if (res.data[k].name == "ЦКО" || res.data[k].name == "Производство") {
          this.divisions.push(res.data[k]);
        }
      }
    });
    axios.get("api/auth/paymenttypes/payment-types").then((res) => {
      this.payment_types = res.data;
    });
    let date = new Date();
    date.setHours(18)
    date.setMinutes(0)
    date = dateFormat(date, "d-m-yyyy HH:MM")
    this.order.shipment_date = date
    console.log(date)
    console.log(typeof date)
  },
};
</script>

<style scoped lang="scss">
.mx-datepicker {
  width: 100%;
}
</style>
