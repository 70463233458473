<template>
    <div>
        <div class="subheader">
            <h1 class="subheader-title" style="border-left: 4px solid #000000;padding-left: 20px;">Каталог продуктов</h1>
        </div>
        <h3 class="mt-5">Список продуктов</h3>
        <div class="panel-container show">
            <table id="dt-basic-example" class="table table-bordered table-hover table-striped w-100 dataTable dtr-inline" role="grid" aria-describedby="dt-basic-example_info" style="width: 1475px;">
                <thead class="bg-primary-600">
                <tr role="row">
<!--                    <th class="sorting_asc">ID #</th>-->
                    <th class="sorting_asc">Наименование</th>
<!--                    <th class="sorting_asc">Редактировать</th>-->
<!--                    <th class="sorting_asc">Удалить</th>-->
                </tr>
                </thead>
                <tbody>
                <tr class="odd" v-for="category in categories" :key="category.id">
<!--                    <td>{{ category.id }}</td>-->
                    <td class="point" @click="getSubCategory(category.id)">{{ category.name }}
                        <ul v-if="subcategory.length != 0">
                            <li v-for="item in subcategory" :key="item.id">{{ item.name }}</li>
                        </ul>
                    </td>
<!--                    <td class="point">Редактировать продукт</td>-->
<!--                    <td class="point">Удалить продукт</td>-->
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "CategoryList",
        data() {
            return {
                categories: {},
                subcategory: {}
            }
        },
        methods: {
            getSubCategory(id) {
                axios.get(`api/auth/category/subcategory/${id}`)
                .then(res => {
                    this.subcategory = res.data
                })
            }
        },
        filters: {
          main() {

          }
        },
        mounted() {
            axios.get('api/auth/category')
            .then(res => {
                this.categories = res.data
            })
        }
    }
</script>

<style scoped lang="scss">
    .point {
        cursor: pointer;
    }
</style>