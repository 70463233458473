<template>
    <main class="auth">
        <div class="blankpage-form-field">
            <div class="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">

                <span class="page-logo-text mr-1">OQplus</span>
            </div>
            <div class="card p-4 border-top-left-radius-0 border-top-right-radius-0">
                <form v-bind:class="{ 'd-none': showAuthForm}" method="post" action="#" @submit.prevent="sendLoginInfo">
                    <div class="form-group">
                        <input type="email" v-model="user.email" id="email" class="form-control" placeholder="Ваш логин"
                               value="">
                    </div>
                    <div class="form-group">
                        <input type="password" v-model="user.password" id="password" class="form-control"
                               placeholder="Ваш пароль"
                               value="">

                    </div>
                    <div class="alert alert-danger" v-bind:class="{ 'd-none': showLoginError}" role="alert">
                        {{errorText}}
                    </div>
                    <button type="submit" class="btn btn-default float-right">Войти</button>

                </form>
                <form v-bind:class="{ 'd-none': showRequestForm}" method="post" @submit.prevent="sendRestoreEmail">
                    <div class="form-group">
                        <input type="email" id="lostaccount" class="form-control"
                               placeholder="Email для восстановления пароля" required v-model="email">
                        <div class="help-block">На этот email придёт ссылка для восстановления пароля</div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-md-4 ml-auto text-right">
                            <button id="js-login-btn" type="submit" class="btn btn-default">Отправить</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="blankpage-footer text-center">
                <a v-on:click="showRequestFormMethod" v-bind:class="{ 'd-none': showAuthForm}"
                   href="javascript:void(0)"><strong>Восстановить пароль</strong></a>
                <a v-on:click="showAuthFormMethod" v-bind:class="{ 'd-none': showRequestForm}"
                   href="javascript:void(0)"><strong>Вернуться к авторизации</strong></a>
            </div>
        </div>
    </main>
</template>

<script>
    // @ is an alias to /src
    import {mapActions} from 'vuex'
    import {required, minLength, email} from 'vuelidate/lib/validators'
    import axios from 'axios'

    export default {
        name: 'Home',
        components: {},
        data: function () {
            return {
                showAuthForm: false,
                showRequestForm: true,
                showLoginError: true,
                errorText: '',
                user: {
                    email: null,
                    password: null
                },
                division: {},
                divisions: [],
                email: null,
                has_error: false
            }
        },
        validations: {
            user: {
                email: {
                    required,
                    email: email
                },
                password: {
                    required,
                    minLength: minLength(8)
                }
            }
        },
        methods: {
            ...mapActions({
                login: 'auth/login'
            }),

            sendLoginInfo() {
                this.login(this.user)
                    .then((e) => {
                        if(e == 'Incorrect login'){
                            this.showLoginError = false;
                            this.errorText = 'Введен неверный Логин или Пароль'
                        }else if(e == 'Auth error'){
                            this.showLoginError = false;
                            this.errorText = 'Ошибка авторизации, попробуйте ещё раз'
                        }else{
                            localStorage.removeItem('user_division')
                            this.$router.replace({
                                name: 'dashboard'
                            })
                        }
                    })
            },

            showAuthFormMethod: function () {
                this.showAuthForm = false;
                this.showRequestForm = true;
            },
            showRequestFormMethod: function () {
                this.showAuthForm = true;
                this.showRequestForm = false;
            },

          sendRestoreEmail: function () {
              axios.post('api/auth/reset-password', {
                email: this.email
              })
            .then(res => {
              console.log(res.data)
              if (res.data.message == 'Password reset email sent.') {
                alert(`На почту ${this.email} отпралена ссылка для восстановления пароля`)
              }
            })
          }
        },
    }
</script>

<style scoped lang="scss">
    body, html, #app{
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    main.auth {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        background: #ffffff;

        .form-group {
            text-align: left;
        }

        .blankpage-form-field {
            width: 320px;
        }

    }


</style>
