<template>
  <aside :class="hidePanel ? 'page-sidebar mod-nav-link d-none' : 'page-sidebar mod-nav-link'">
    <div class="page-logo">
      <a href="/dashboard">
        <span class="page-logo-text mr-1">OQplus</span>
      </a>
    </div>
    <!-- BEGIN PRIMARY NAVIGATION -->
    <nav id="js-primary-nav" class="primary-nav" role="navigation">
      <div class="info-card">
        <img
          :src="
            authUser.avatar ? authUser.avatar : '/img/demo/avatars/avatar-m.png'
          "
          class="profile-image rounded-circle"
          :alt="authUser.name"
        />
        <div class="info-card-text">
          <span  class="d-flex align-items-center text-white">
            <span class="text-truncate text-truncate-sm d-inline-block">
              {{ authUser.name }}
            </span>
          </span>
          <span class="d-inline-block mt-3">
            {{ division }}
          </span>
          <router-link to="/settings" v-slot="{ href, route, navigate }">
            <a
              :href="href"
              @click="navigate"
              class="d-flex align-items-center text-white mt-3"
            >
              <span class="text-truncate text-truncate-sm d-block">
                <i class="fal fa-cog mr-2"></i>Настройки
              </span>
            </a>
          </router-link>
        </div>
        <router-link
              to="/calculators/maket" href="/calculators/maket" class="calc-icon">
          <i class="fal fa-2x fa-calculator"></i>
        </router-link>
        <img
          src="/img/card-backgrounds/cover-2-lg.png"
          class="cover"
          alt="cover"
        />
      </div>
      <!--
            TIP: The menu items are not auto translated. You must have a residing lang file associated with the menu saved inside dist/media/data with reference to each 'data-i18n' attribute.
            -->
      <ul id="js-nav-menu" class="nav-menu">
        <li v-if="userGroups.users.includes(user.role)" class="active open">
          <a href="#" title="Category" data-filter-tags="category">
            <i class="fal fa-user"></i>
            <span class="nav-link-text" data-i18n="nav.category"
              >Пользователи</span
            >
          </a>
          <ul>
            <router-link
              to="/users/list"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Список пользователей</a>
              </li>
            </router-link>
            <router-link
              to="/users/create"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Создать пользователя</a>
              </li>
            </router-link>
          </ul>
        </li>
        <!--                -->
        <li v-if="userGroups.divisions.includes(user.role)" class="active open">
          <a href="#" title="Category" data-filter-tags="category">
            <i class="fal fa-users-class"></i>
            <span class="nav-link-text" data-i18n="nav.category"
              >Подразделения</span
            >
          </a>
          <ul>
            <router-link
              to="/divisions/list"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Список подразделений</a>
              </li>
            </router-link>
            <router-link
              to="/divisions/create"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Создать подразделение</a>
              </li>
            </router-link>
          </ul>
        </li>
        <!--      Управление продуктом          -->
        <li v-if="userGroups.products.includes(user.role)" class="active open">
          <a href="#" title="Category" data-filter-tags="category">
            <i class="fal fa-book"></i>
            <span class="nav-link-text" data-i18n="nav.category">Продукты</span>
          </a>
          <ul>
            <router-link
              to="/products/list"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Каталог продуктов</a>
              </li>
            </router-link>
            <router-link
              to="/products/create"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Создать продукт</a>
              </li>
            </router-link>
            <!--            <router-link-->
            <!--                to="/products/test-create/"-->
            <!--                v-slot="{ href, route, navigate, isActive, isExactActive }"-->
            <!--            >-->
            <!--              <li-->
            <!--                  :class="[-->
            <!--                  isActive && 'active',-->
            <!--                  isExactActive && 'router-link-exact-active',-->
            <!--                ]"-->
            <!--              >-->
            <!--                <a :href="href" @click="navigate">Тест Создать продукт</a>-->
            <!--              </li>-->
            <!--            </router-link>-->
          </ul>
        </li>

        <!--     Заказ           -->

        <li v-if="userGroups.order.includes(user.role)" class="active open">
          <a href="#" title="Category" data-filter-tags="category">
            <i class="fal fa-ruble-sign"></i>
            <span class="nav-link-text" data-i18n="nav.category">Заказ</span>
          </a>
          <ul >
            <router-link
              to="/order/list"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Список заказов</a>
              </li>
            </router-link>
            <router-link
              to="/order/create"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Создать заказ</a>
              </li>
            </router-link>
          </ul>
        </li>

        <li v-if="userGroups.notifications.includes(user.role)" class="active open">
          <a href="#" title="Category" data-filter-tags="category">
            <i class="fal fa-bell"></i>
            <span class="nav-link-text" data-i18n="nav.category">Уведомления</span>
          </a>
          <ul>
            <router-link
              to="/notifications/create"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Создать уведомление</a>
              </li>
            </router-link>
          </ul>
        </li>

        <li v-if="userGroups.manufacture.includes(user.role) || user.division === 63" class="active open">
          <a href="#" title="Category" data-filter-tags="category">
            <i class="fal fa-print"></i>
            <span class="nav-link-text" data-i18n="nav.category">Производство</span>
          </a>
          <ul>
            <router-link
              to="/manufacture/find"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Задания на изготовление</a>
              </li>
            </router-link>
            <router-link
              to="/mobile/manufacture/search"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Печать стикеров</a>
              </li>
            </router-link>
            <router-link
              to="/mobile/manufacture/open"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Поиск по штрих-коду</a>
              </li>
            </router-link>
          </ul>
        </li>

        <li class="active open">
          <a href="#" title="Category" data-filter-tags="category">
            <i class="fal fa-award"></i>
            <span class="nav-link-text" data-i18n="nav.category">Бессмертный полк</span>
          </a>
          <ul>
            <router-link
              to="/polk/list/standard"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
              v-if="userGroups.polk.includes(user.role)"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Штендер стандарт</a>
              </li>
            </router-link>
            <router-link
              to="/polk/list/eco"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
              v-if="userGroups.polk.includes(user.role)"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Штендер эконом</a>
              </li>
            </router-link>
            <router-link
              to="/polk/download"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
              v-if="userGroups.polk.includes(user.role)"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Скачать макет</a>
              </li>
            </router-link>
            <router-link
              to="/polk/edit"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
              v-if="userGroups.polk.includes(user.role)"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Редактировать макет</a>
              </li>
            </router-link>
            <router-link
              to="/polk/orders"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
              v-if="userGroups.polk.includes(user.role)"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Список заказов</a>
              </li>
            </router-link>
            <router-link
              to="/polk/manufacture"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Задания на изготовление</a>
              </li>
            </router-link>
            <router-link
              to="/polk/my-orders"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
              v-if="userGroups.polk.includes(user.role)"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" class="pt-1 pb-1" @click="navigate">Мои заказы</a>
              </li>
            </router-link>
          </ul>
        </li>
        <li v-if="userGroups.statistic.includes(user.role)" class="active open">
          <a href="#" title="Уведомления" data-filter-tags="category">
            <i class="fal fa-bell"></i>
            <span class="nav-link-text" data-i18n="nav.category">Отчётность</span>
          </a>
          <ul>
            <router-link
              to="/reports"
              v-slot="{ href, route, navigate, isActive, isExactActive }"
            >
              <li
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <a :href="href" @click="navigate">Создать отчёт</a>
              </li>
            </router-link>
          </ul>
        </li>
        <!-- Example of open and active states -->
      </ul>
      <div class="filter-message js-filter-message bg-success-600"></div>
    </nav>
    <!-- END PRIMARY NAVIGATION -->
    <!-- NAV FOOTER -->
    <div class="nav-footer shadow-top"></div>
    <!-- END NAV FOOTER -->

    <!-- END PRIMARY NAVIGATION -->
  </aside>
</template>

<script>
import store from "../../store";
import main from "../../store/index";
import axios from "axios";

export default {
  name: "Sidebar",
  data() {
    return {
      authUser: store.getters["auth/authenticated"],
      user: store.getters["auth/user"],
      division: "Загрузка...",
      hidePanel: store.state.hidePanel,
      userGroups: {
        users: [3, 4],
        divisions: [3],
        products: [3, 5],
        order: [3, 5, 1, 4],
        notifications: [3],
        manufacture: [3, 5, 1, 6, 4, 7],
        polk: [3, 5, 4, 7],
        statistic: [3, 4],
      }
    };
  },
  computed: {
    getCurrentWorkspace() {
      return main.state.workspace;
    },
    getHidePanel() {
      return store.state.hidePanel;
    }
  },
  beforeMount() {
    axios.get("api/auth/divisions").then((res) => {
      this.divisions = res.data;
      if (localStorage.getItem("user_division")) {
        this.division = JSON.parse(localStorage.getItem("user_division")).name;
      }
    });
  },
  watch: {
    getCurrentWorkspace(workspace) {
      console.log("getWorkspace");
      this.division = workspace.name;
    },
    getHidePanel(hidePanel) {
      this.hidePanel = hidePanel;
    }
  },
};
</script>

<style scoped>
.calc-icon {
  position: absolute;
  left: 10px;
  bottom: 5px;
  z-index: 1;
  cursor: pointer;
}

.calc-icon i::before {
  color: #2595ea;
}
</style>
