<template>
  <div class="pb-100">
    <div class="subheader mb-3">
      <h1>
        Добавление продукта "{{ product.name }}" в заказ
      </h1>
    </div>
    <div class="d-flex">
      <div class="col-8">
         <div
      v-if="this.errors.length !== 0"
      class="alert alert-danger"
      role="alert"
    >
      <h1><strong>Пожалуйста, исправьте ошибки:</strong></h1>
      <ul>
        <li v-for="error in this.errors" :key="error[0]">{{ error[0] }}</li>
      </ul>
    </div>
        <div class="form-group">
          <div v-if="showParents">
            <h4 class="mb-3">
              {{
                product.parent_title
                  ? product.parent_title
                  : "Родительский вариант продукта"
              }}
            </h4>
            <multiselect
              v-model="current_product.parent"
              track-by="name"
              label="name"
              :options="product.parent_variants"
              :show-labels="false"
              :searchable="false"
              :allow-empty="false"
              placeholder=""
              @select="getVariants"
            >
              <template slot-scope="{ option }">{{ option }} </template>
            </multiselect>
          </div>


          <div v-if="showVariants">
            <h4 class="mb-3">
              {{
                product.variant_title
                  ? product.variant_title
                  : "Вариант продукта"
              }}
            </h4>
            <multiselect
              :value="current_product.variant"
              @select="changePrice"
              track-by="name"
              label="name"
              :options="variants"
              :show-labels="false"
              :searchable="false"
              :allow-empty="false"
              placeholder=""
            >
              <template slot-scope="{ option }">{{ option }} </template>
            </multiselect>
          </div>
        </div>
        <h5 class="mb-3 mt-3 d-flex align-items-center">
          <span>Услуги по умолчанию</span>
        </h5>

        <div
          v-for="service in servicePreset"
          :key="service.id"
          class="panel"
        >
          <div class="panel-hdr">
            <h2>
              {{ service.name }}
            </h2>
            <div class="panel-toolbar">
              <button
                v-if="service.is_multiple"
                title="Копировать услугу"
                @click="cloneService(service)"
                type="button"
                class="btn btn-md btn-icon waves-effect waves-themed mr-3"
              >
                <span aria-hidden="true"><i class="fal fa-copy"></i></span>
              </button>
              <div
                class="custom-control custom-checkbox custom-control-inline"
              >
                <input
                  name="service.id"
                  type="checkbox"
                  :value="service"
                  v-model="current_product.services"
                  @change="switchServiceInProduct(service.id, $event)"
                  class="custom-control-input"
                  :disabled="inExceptions(service.id)"
                  :id="'service' + service.id"
                  :ref="'check' + service.id"
                />
                <label
                  class="custom-control-label"
                  :for="'service' + service.id"
                ></label>
              </div>
            </div>
          </div>
          <div  class="panel-content">
            <div class="frame-wrap p-3">
              <div class="d-flex">
                <!-- <div class="col-2 pl-0">
                  <label class="form-label">Количество</label>
                  <input
                    type="number"
                    @keyup="
                      changeUnitServiceQuantity(service, $event);
                      validateQuantity($event);
                    "
                    @change="
                      changeUnitServiceQuantity(service, $event);
                      validateQuantity($event);
                    "
                    class="form-control"
                    step="0.01"
                    value="0"
                  />
                </div> -->
                <div  v-if="service.variants && service.variants.length"  class="col-4 pl-0">
                  <label class="form-label">Варианты</label>
                  <multiselect
                    :preselect-first="true"
                    :options="service.variants"
                    v-model="service.current_variant"
                    :show-labels="false"
                    :searchable="false"
                    :allow-empty="false"
                    placeholder=""
                  >
                    <template slot-scope="{ option }"
                      >{{ option }}
                    </template>
                  </multiselect>
                </div>
                <div class="col-6 pl-0">
                  <label class="form-label">Комментарий к услуге</label>
                  <input type="text" class="form-control" v-model="serviceComments[service.id]" />
                </div>
              </div>
              <div class="col-2 pl-0 mt-2" v-if="service.is_counted">
                <label class="form-label">Количество</label>
                <input type="number" class="form-control" v-model.number="service.custom_quantity" />
              </div>
            </div>
          </div>
          <div v-if="service.price_type === '4'" class="panel-content">
            <div class="frame-wrap p-3">
              <div class="col-2 pl-0">
                <label class="form-label">Цена </label>
                <input
                  type="number"
                  @keyup="
                    changePersonalServicePrice(service, $event);
                    validateQuantity($event);
                  "
                  @change="
                    changePersonalServicePrice(service, $event);
                    validateQuantity($event);
                  "
                  class="form-control"
                  step="0.01"
                  min="0"
                  value="0"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="this.countOfNotPresets !== 0">
          <h5 class="mb-3 mt-3 d-flex align-items-center">
            <span>Список дополнительных услуг</span
            ><span class="ml-3 cursor-pointer" @click="switchServices"
              ><i
                class="fal"
                :class="
                  showServices
                    ? 'fa-chevron-square-up'
                    : 'fa-chevron-square-down'
                "
              ></i
            ></span>
          </h5>
          <div v-if="showServices">
            <div
              v-for="service in serviceNotPreset"
              :key="service.id"
              class="panel"
            >
              <div class="panel-hdr">
                <h2>
                  {{ service.name }}
                </h2>
                <div class="panel-toolbar">
                  <button
                    v-if="service.is_multiple"
                    title="Копировать услугу"
                    @click="cloneService(service)"
                    type="button"
                    class="btn btn-md btn-icon waves-effect waves-themed mr-3"
                  >
                    <span aria-hidden="true"><i class="fal fa-copy"></i></span>
                  </button>
                  <div
                    class="custom-control custom-checkbox custom-control-inline"
                  >
                    <input
                      name="service.id"
                      type="checkbox"
                      :value="service"
                      v-model="current_product.services"
                      @change="switchServiceInProduct(service.id, $event)"
                      class="custom-control-input"
                      :disabled="inExceptions(service.id)"
                      :id="'service' + service.id"
                    />
                    <label
                      class="custom-control-label"
                      :for="'service' + service.id"
                    ></label>
                  </div>
                </div>
              </div>
              <div   class="panel-content">
                <div class="frame-wrap p-3">
                  <div class="d-flex">
                    <!-- <div class="col-2 pl-0">
                      <label class="form-label">Количество</label>
                      <input
                        type="number"
                        @keyup="
                          changeUnitServiceQuantity(service, $event);
                          validateQuantity($event);
                        "
                        @change="
                          changeUnitServiceQuantity(service, $event);
                          validateQuantity($event);
                        "
                        class="form-control"
                        step="0.01"
                        value="0"
                      />
                    </div> -->
                    <div  v-if="service.variants && service.variants.length" class="col-4 pl-0">
                      <label class="form-label">Варианты</label>
                      <multiselect
                        :preselect-first="true"
                        :options="service.variants"
                        v-model="service.current_variant"
                        :show-labels="false"
                        :searchable="false"
                        :allow-empty="false"
                        placeholder=""
                      >
                        <template slot-scope="{ option }"
                          >{{ option }}
                        </template>
                      </multiselect>
                    </div>
                    <div class="col-6 pl-0">
                      <label class="form-label">Комментарий к услуге</label>
                      <input type="text" class="form-control" v-model="serviceComments[service.id]" />
                    </div>
                  </div>
                  <div class="col-2 pl-0 mt-2" v-if="service.is_counted">
                    <label class="form-label">Количество</label>
                    <input type="number" class="form-control" v-model.number="service.custom_quantity" />
                  </div>
                </div>
              </div>
              <div v-if="service.price_type === '4'" class="panel-content">
                <div class="frame-wrap p-3">
                  <div class="col-2 pl-0">
                    <label class="form-label">Цена </label>
                    <input
                      type="number"
                      @keyup="
                        changePersonalServicePrice(service, $event);
                        validateQuantity($event);
                      "
                      @change="
                        changePersonalServicePrice(service, $event);
                        validateQuantity($event);
                      "
                      class="form-control"
                      step="0.01"
                      min="0"
                      value="0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr v-if="current_product.runs == 4 || current_product.runs == 5" class="mt-4 mb-g" />
        <div v-if="current_product.runs == 4 || current_product.runs == 5" class="form-group">
          <h4 class="mt-4 mb-3" v-if="current_product.runs == 4">Площадь</h4>
          <h4 class="mt-4 mb-3" v-if="current_product.runs == 5">Периметр</h4>
          <div class="form-group">
            <label for="" class="form-label">Ширина, мм</label>
            <input
              type="number"
              class="form-control"
              step="1"
              min="0"
              v-model.number="current_product.width"
            />
          </div>
          <div class="form-group">
            <label for="" class="form-label">Высота, мм</label>
            <input
              type="number"
              class="form-control"
              step="1"
              min="0"
              v-model.number="current_product.height"
            />
          </div>
        </div>
        <hr class="mt-4 mb-g" />
        <div class="form-group">
          <h5 class="mt-3 mb-2">Макет</h5>
          <input
            type="text"
            class="form-control"
            v-model="file_url"
          />
        </div>
        <hr class="mt-4 mb-g" />
        <h5 class="mb-2">Дополнительные параметры</h5>
        <div class="form-group mt-3">
           <label for="" class="form-label"
            >Выберите место изготовления продукта</label
          >
          <multiselect
              v-model="current_manufacture"
              track-by="id"
              label="name"
              :options="manufactures"
              :show-labels="false"
              :searchable="false"
              :allow-empty="false"
              placeholder=""
              @input="getManufactureId"
            >
              <template slot-scope="{ option }">{{ option }} </template>
            </multiselect>
        </div>
        <div class="form-group">
          <label for="" class="form-label"
            >Стоимость работы дизайнера, руб.</label
          >
          <input
            type="number"
            class="form-control"
            step="0.01"
            v-model="current_product.design_price"
          />
        </div>
        <div class="form-group">
          <label for="" class="form-label"
            >Корректировка цены продукта, руб.</label
          >
          <input
            type="number"
            class="form-control"
            step="0.01"
            v-model="current_product.discount"
          />
        </div>
        <div
          class="form-group custom-control custom-checkbox custom-control-inline"
        >
          <input
            name="isManual"
            id="isManual"
            v-model="current_product.isManual"
            type="checkbox"
            class="custom-control-input"
          />
          <label
            class="custom-control-label"
            for="isManual"
          >Ручной ввод цены продукта</label>
        </div>
        <div class="form-group">
          <label for="" class="form-label">Комментарий к продукту</label>
          <textarea
            class="form-control"
            rows="5"
            v-model="current_product.comment"
          ></textarea>
        </div>
        <hr class="mt-4 mb-g" />
        <h5 class="mb-2">Превью</h5>
         <div v-if="current_product.preview.length" class="card mb-g mt-xl-5">
          <div class="row">
            <div class="col-12">
              <div class="p-3">
                <h2 class="mb-0 fs-xl">Загруженные превью</h2>
              </div>
            </div>
            <div
              v-for="(image, i) in current_product.preview"
              v-bind:key="i"
              class="col-4 position-relative mt-3 p-5"
            >
              <span
                class="position-absolute pos-top pos-right width-25 pr-5 height-5"
              >
                <button
                  @click="removeImage(image.id)"
                  type="button"
                  class="btn btn-xs btn-danger waves-effect waves-themed"
                >
                  Удалить
                </button>
              </span>
              <img :src="image.url" class="w-100" alt="" />
            </div>
          </div>
        </div>
        <file-pond
          name="filepond"
          ref="pond"
          class-name="my-pond"
          label-idle="Загрузите превью, кликнув сюда, либо перетащив на данную область файлы. Если вы скопировали файл в буфер обмена, то нажмите ctrl-v"
          labelFileProcessing="Загрузка..."
          labelFileProcessingComplete="Загрузка завершена"
          labelTapToCancel="нажмите для отмены"
          allow-multiple="true"
          accepted-file-types="image/jpeg, image/png"
        />
        <!--<input
          type="file"
          name="preview"
          ref="preview"
          accept="image/jpeg,image/png,image/gif"
          multiple
          @change="uploadImage"
        /> -->

      </div>
      <div class="col-4">
        <div class="panel-tag border-primary">
          <span class="fw-500">Номер заказа:</span> {{orderInfo.number}}<br />
          <span class="fw-500">Клиент:</span> {{orderInfo.name}}<br />
          <span class="fw-500">Телефон:</span> {{orderInfo.phone}}<br />
          <span class="fw-500">Email:</span> {{orderInfo.email}}
        </div>
        <div v-html="product.comments" class="panel-tag"></div>

      </div>
    </div>
    <div class="float-footer">
      <div class="fixed-content d-flex align-items-center border-top" :style="hidePanel ? 'width: 100%' : ''">
        <div class="col-2">
          <label class="form-label">Количество</label>
          <input
            v-model.number="current_product.variant.price.quantity"
            @keyup="
              changeServicesQuantity;
              validateQuantity($event);
            "
            @change="
              changeServicesQuantity;
              validateQuantity($event);
            "
            v-if="product.runs !== 3"
            type="number"
            class="form-control"
            step="0.01"
            min="0"
            value="0"
          />

          <multiselect
            v-if="product.runs === 3"
            v-model="current_product.variant.price.price[0]"
            @select="changeServicesQuantity"
            track-by="quantity"
            label="quantity"
            :options="currentVariant.price"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            placeholder=""
          >
            <template slot-scope="{ option }">{{ option }} </template>
          </multiselect>
        </div>

        <!--        Блок кнопок выбора варианта со сроками-->
        <div class="col-7 d-flex">
          <div
            v-for="(item, urgency) in calculatedPrice"
            @click="selectTimelineToOrder(calculatedPrice[urgency])"
            :key="urgency"
            class="btn waves-effect waves-themed text-left p-2 mr-3 col-2"
            :class="selectedButton == item['type'] ? 'btn-primary' : 'btn-light'"
          >
            <span
              class="badge bg-transparent p-0"
              :class="
                selectedButton == item['type'] ? 'badge-primary' : 'badge-light'
              "
              >{{ calculatedPrice[item['type']]["date"][1] }}
              <span
                :class="
                  selectedButton == item['type'] ? 'color-white' : 'color-fusion-50'
                "
                >{{ product.deliver_after }}</span
              ></span
            >
            <span class="h4 d-block m-0"
              >{{
                !current_product.isManual ? Math.floor(calculatedPrice[item['type']]["price"]["total"]) : ''
              }}
              <input
                type="number"
                :class="selectedButton == urgency ? 'input_calculated-primary' : 'input_calculated-light'"
                v-if="current_product.isManual"
                v-model.number="manualValue[urgency]['price']['total']" />
              <i class="fal fa-ruble-sign"></i
            ></span>
          </div>

          <!--<div class="btn btn-primary waves-effect waves-themed text-left p-2 mr-3 col-2">
            <span class="badge badge-primary bg-transparent p-0">12.03.2020</span>
            <span class="h4 d-block m-0">630 <i class="fal fa-ruble-sign"></i></span>
          </div>-->
        </div>
        <div class="col-3 d-flex flex-row-reverse">
          <input
            type="button"
            @click="showProductHintForm"
            value="Добавить в заказ"
            class="btn btn-primary btn-md btn-block waves-effect waves-themed col-6"
          />
          <button
            name="reset"
            @click="backToOrder"
            class="btn btn-default btn-md btn-block waves-effect waves-themed col-6 mt-0 mr-3"
          >
            Отменить
          </button>
        </div>
      </div>
    </div>
    <div
      class="modal modal-alert fade show d-block"
      id="example-modal-alert"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      v-if="ifShowModal"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <i class="fal fa-bolt mr-3"> Примечания к продукту</i>
            </h5>
            <button
              type="button"
              @click="hideProductHintForm"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body mt-5">
            <ul>
              <li
                v-for="checkbox in this.product.additional_checkboxes"
                :key="checkbox"
              >
                {{ checkbox }}
              </li>
            </ul>
          </div>
          <div class="modal-footer mt-5">
            <button
              @click="storeProductOrder"
              type="button"
              class="btn btn-primary waves-effect waves-themed"
            >
              Продолжить
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" v-if="showBackdrop"></div>

    <div class="modal-backdrop fade show" v-if="showLoader"></div>
    <div class="modal fade show d-block" v-if="showLoader">
      <div
        class="fixed modal-dialog modal-dialog-centered justify-content-center"
      >
        <button
          class="btn btn-info waves-effect waves-themed"
          type="button"
          disabled=""
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Загрузка...
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import router from "@/router";
import store from "../../store";
import vueFilePond, { setOptions } from "vue-filepond";
// Import plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";


// Import styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
);

export default {
  name: "ProductAdd",
  components: {
    Multiselect,
    FilePond,
  },
  data() {
    return {
      showLoader: true,
      product: {
        parent_variants: [],
        variant: [],
        service: {},
        runs: 1,
        runs_range: { 0: 0 },
      },
      current_product: {
        id: 0,
        runs: 1,
        production: 1,
        preview: [],
        parent: {},
        variant: {
          price: {
            quantity: 0,
            price: [],
          },
        },
        countedPrice: {
          shipment_date: null,
          quantity: null,
          price: null,
        },
        totalPrice: {},
        services: [],
        file_url: null,
        design_price: null,
        comment: null,
        width: 0,
        height: 0,
        discount: 0,
        isManual: null,
      },
      manufactures: [],
      manufacture_id: 0,
      current_manufacture: null,
      calculatedPrice: [],
      variants: [],
      currentVariant: {},
      services: [],
      showParents: false,
      showVariants: true,
      showServices: true,
      showSquare: false,
      servicesExceptions: [],
      selectedButton: null,
      ifShowModal: false,
      showBackdrop: false,
      orderId: "",
      countOfNotPresets: 0,
      manualValue: [],
      orderInfo: {},
      hidePanel: store.state.hidePanel,
      serviceComments: [],
      errors: [],
      file_url: null,
    };
  },
  methods: {
    getManufactureId(){
      this.manufacture_id = this.current_manufacture.id;
    },
    async uploadImage() {
      let files = this.$refs.preview.files;

      for (let i = 0; i < files.length; i++) {
        let formData = new FormData();
        formData.append("filepond", files[i]);
        await axios
          .post(
            `api/auth/orders/order/save-media/${
              JSON.parse(localStorage.getItem("order")).id
            }`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            let url = res.data;
            let resArr = res.data.split("/");
            let id = resArr[resArr.length - 2];
            this.current_product.preview.push({
              id: id,
              url: url,
            });
          })
          .catch(function () {
            console.log("Ошибка загрузки!!");
          });
      }
      this.$refs.preview.value = "";
    },
    removeImage(id) {
      axios
        .delete(`api/auth/orders/order/delete-media/${id}`)
        .then(() => {
          let removeIndex = this.current_product.preview
            .map(function (item) {
              return item.id;
            })
            .indexOf(id);
          this.current_product.preview.splice(removeIndex, 1);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getVariants(parent) {
      this.variants = [];
      console.log(this.product.variant);
      console.log(parent);
      for (let k in this.product.variant) {
        if (this.product.variant[k].parent_variant_id === parent.id) {
          this.variants.push(this.product.variant[k]);
        }
      }
      let currentPrice = JSON.parse(
        JSON.stringify(this.current_product.variant.price)
      );
      if (currentPrice.price.length) {
        this.current_product.variant = JSON.parse(
          JSON.stringify(this.variants[0])
        );
        this.currentVariant = JSON.parse(JSON.stringify(this.variants[0]));
        this.current_product.variant.price = currentPrice;
        if (this.product.runs === 3) {
          for (let price in this.currentVariant.price) {
            if (
              currentPrice.price[0].quantity ===
              this.currentVariant.price[price].quantity
            ) {
              this.current_product.variant.price.price[0] = this.currentVariant.price[
                price
              ];
            }
          }
        } else {
          this.current_product.variant.price.price = this.currentVariant.price;
        }
      } else {
        console.log(999);
        this.current_product.variant = JSON.parse(
          JSON.stringify(this.variants[0])
        );
        this.currentVariant = JSON.parse(JSON.stringify(this.variants[0]));
        this.current_product.variant.price = {
          quantity: 0,
          price: [],
        };
        if (this.product.runs !== 3) {
          this.current_product.variant.price.quantity = Object.keys(
            this.product.runs_range
          )[0];
          this.current_product.variant.price.price = this.currentVariant.price;
        } else {
          this.current_product.variant.price.price[0] = this.currentVariant.price[0];
        }
      }
    },
    inExceptions(id) {
      return this.servicesExceptions.indexOf(id) !== -1;
    },
    switchServices() {
      this.showServices = !this.showServices;
    },
    cloneService(service) {
      let newService = JSON.parse(JSON.stringify(service));
      if (!newService.originalId) {
        newService.originalId = newService.id;
      }
      newService.id = new Date().getTime();
      this.product.service.push(newService);
    },
    validateQuantity(e) {
      if (+e.target.value < 0) {
        e.target.value = 0;
      }
    },
    changeServicesQuantity(e) {
      if (this.current_product.services.length) {
        for (let i in this.current_product.services) {
          if (this.current_product.services[i].price_type !== "3") {
            if (this.product.runs === 3) {
              this.current_product.services[i].price.quantity = e.quantity;
            } else {
              this.current_product.services[i].price.quantity = +e.target.value;
            }
          }
        }
      }
    },
    changePersonalServicePrice(service, event) {
      service.price.price[0].price = +event.target.value;
    },
    changeUnitServiceQuantity(service, event) {
      service.price.quantity = +event.target.value;
    },
    switchServiceInProduct(id, event) {
      let itemId = event.target.id;
      let element = document.getElementById(itemId);
      let service = this.product.service.filter(function (el) {
        return el.id === id;
      })[0];

      if (element.checked) {
        if (service.price_type !== "3") {
          if (this.product.runs === 3) {
            service.price.quantity = this.current_product.variant.price.price[0].quantity;
          } else {
            service.price.quantity = +this.current_product.variant.price
              .quantity;
          }
        }
      }

      for (let i in service.exceptions) {
        if (element.checked) {
          let removeIndex = this.current_product.services
            .map(function (item) {
              return item.id;
            })
            .indexOf(service.exceptions[i].id);
          if (removeIndex > -1) {
            let removeServiceExceptions = this.current_product.services[
              removeIndex
            ].exceptions;
            for (let e in removeServiceExceptions) {
              let removeExceptionIndex = this.servicesExceptions
                .map(function (id) {
                  return id;
                })
                .indexOf(removeServiceExceptions[e].id);
              this.servicesExceptions.splice(removeExceptionIndex, 1);
            }
            this.current_product.services.splice(removeIndex, 1);
          }
          this.servicesExceptions.push(service.exceptions[i].id);
        } else {
          let removeIndex = this.servicesExceptions
            .map(function (id) {
              return id;
            })
            .indexOf(service.exceptions[i].id);
          this.servicesExceptions.splice(removeIndex, 1);
        }
      }

      /*
      let service = this.product.service.filter(function (el) {
        return el.id === id;
      })[0]
      let checkServices = this.current_product.services.filter(function (el) {
        return el.id === id
      })

      if (checkServices.length) {
        let removeIndex = this.current_product.services.map(function (item) {
          return item.id;
        }).indexOf(id);
        this.current_product.services.splice(removeIndex, 1);
        for (let i in service.exceptions) {
          let exceptionIndex = this.servicesExceptions.indexOf(service.exceptions[i].id)
          this.servicesExceptions.splice(exceptionIndex, 1);
        }
      } else {
        for (let i in service.exceptions) {
          this.servicesExceptions.push(service.exceptions[i].id)
          let uncheckIndex = this.current_product.services.map(function (item) {
            return item.id;
          }).indexOf(service.exceptions[i].id);
          this.current_product.services.splice(uncheckIndex, 1);

          this.services['service' + service.exceptions[i].id] = false

          /!*let uncheckedService= this.product.service.filter(function(el) {
            return el.id === service.exceptions[i].id;
          })[0]
          console.log(uncheckedService);
          for(let i in uncheckedService.exceptions){
            let uncheckServiceIndex = this.current_product.services.map(function(item) { return item.id; }).indexOf(uncheckedService.exceptions[i].id);
            this.servicesExceptions.splice(uncheckServiceIndex, 1);
          }*!/
        }
        console.log(service);
        this.current_product.services.push(service)
      }*/
    },
    changePrice(e) {
      this.currentVariant = e;

      this.current_product.variant.id = e.id;
      this.current_product.variant.is_parent = e.is_parent;
      this.current_product.variant.name = e.name;
      this.current_product.variant.parent_variant_id = e.parent_variant_id;
      this.current_product.variant.product_id = e.product_id;
      this.current_product.variant.type = e.type;
      console.log("Current Product ", this.current_product);
      if (this.product.runs === 3) {
        if (this.current_product.variant.price.price[0]) {
          for (let price in this.currentVariant.price) {
            if (
              this.current_product.variant.price.price[0].quantity ===
              this.currentVariant.price[price].quantity
            ) {
              this.current_product.variant.price.price[0] = this.currentVariant.price[
                price
              ];
            }
          }
        } else {
          this.current_product.variant.price.price[0] = this.currentVariant.price[0];
        }
      } else {
        this.current_product.variant.price.price = this.currentVariant.price;
      }
    },
    calculateVariant(value, setDefault) {
      console.log(this.current_product);
      axios
        .post("api/auth/orders/calculate/variant", value)
        .then((res) => {
          if(this.calculatedPrice.length === 0) {
            for(let key in res.data) {
              this.manualValue[key] = res.data[key]
            }
          }
          console.log("Total ", res.data);
          this.calculatedPrice = res.data;
          console.log("calculated ", this.calculatedPrice);
          if(this.current_product.isManual) {
            this.calculatedPrice[this.selectedButton]['price']['total'] = this.current_product.totalPrice.price.total;
          }
          if (setDefault) {
            let defaultTiming = this.calculatedPrice
            .map(function (item) {
              return item.default;
            })
            .indexOf(true);
            if(defaultTiming == -1){
              defaultTiming = 0
            }
            let dafaultTimingType = this.calculatedPrice[defaultTiming].type
            this.selectTimelineToOrder(this.calculatedPrice[dafaultTimingType]);
            console.log("default");
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    selectTimelineToOrder(data) {
      // console.log('Выбранное значение цены и периода: ', data)
      console.log("timeline ", data);
      this.selectedButton = data.type;
      this.current_product.totalPrice = data;
    },
    storeProductOrder() {
      this.errors = [];
      this.current_product.file_url = this.file_url;
      this.manufacture_id === 0 && this.errors.push(['Необходимо выбрать место изготовления продукта'])
      !this.current_product.file_url && this.errors.push(['Поле макет обязательно для заполнения'])
      if (this.product.has_preview) {
        this.current_product.preview.length <= 0 && this.errors.push(['Поле превью обязательно для заполнения'])
      }


      if(this.errors.length){
        window.scrollTo(0,0);
        return false;
      }

      if(this.current_product.isManual) {
        let manualValue = this.manualValue[this.selectedButton]['price']['total'] || 0;
        this.current_product.totalPrice.price.total = manualValue
        this.calculatedPrice[this.selectedButton]["price"]["total"] = manualValue
      }
      this.serviceComments.forEach((comment, index) => {
        this.current_product.services.forEach(service => {
          if(service.id === index) service.comment = comment
        })
      });
      let timeline = this.selectedButton;
      if(!Number.isInteger(this.current_product.height) ||
         !Number.isInteger(this.current_product.width)) {
        alert('В поля высота и ширина можно вводить только целые значения')
        return false
      }
      const variant = {
        product_id: this.current_product.id,
        variant_id: this.current_product.variant.id,
        variant_price: 0,
        variant_quantity: this.current_product.variant.price.quantity,
        variant_cost: 0,
        variant_date: "",
        preview: this.current_product.preview,
        comment: this.current_product.comment,
        width: this.current_product.width,
        height: this.current_product.height,
        layout_url: this.current_product.file_url,
        design_price: this.current_product.design_price,
        services: JSON.stringify(this.current_product.services),
        current_product: JSON.stringify(this.current_product),
        discount: this.current_product.discount,
        is_manual: this.current_product.isManual,
        manufacture_id: this.manufacture_id
      };

      let orderData = JSON.parse(localStorage.getItem("order"));
      let order = {
        id: orderData.id,
        division_id: orderData.division != null ? orderData.division.id : "",
        customer_id: orderData.customer != null ? orderData.customer.id : "",
        // payment_type_id:
        //   orderData.payment_type != null ? orderData.payment_type.id : "",
        order_cost: 0,
        order_number: orderData.order_number,
      };
      // let a = this.current_product.totalPrice
      let ob = this.calculatedPrice;

      let a = timeline;
      console.log('tomeline ', timeline);
      console.log("CALC PRICE: ", this.calculatedPrice);
      console.log('cur ', ob[a]);

      console.log(a, " A");
      Object.keys(ob[a]).map(function (key) {
        let result = key.match(/discount/);
        let date = key.match(/date/);

        variant.timeline = timeline;
        console.log("Объект А ", ob[a]["price"]);
        if (result) {
          variant.variant_price = ob[a]["price"]["price"];
          // variant.variant_quantity = ob[a]["price"]["quantity"];
          variant.variant_cost = ob[a]["price"]["total"];
          order.order_cost = ob[a]["price"]["total"];
        }
        if (date) {
          //console.log('date ', '"'+ob[a][date.input][0]+'"')
          variant.variant_date = new Date(ob[a][date.input][0]) ?? new Date();
          //console.log('var date ', variant.variant_date)
          // order.shipment_date = new Date(ob[a][date.input]);
        }
      });

      axios
        .post(`api/auth/orders/order/edit/${order.id}`, {
          variant: variant,
          order: order,
        })
        .then((res) => {
          console.log(res.data);
          router.push({ name: "orderEdit", params: { id: res.data.id } });
        });
    },
    backToOrder() {
      router.push({
        name: "orderEdit",
        params: { id: JSON.parse(localStorage.getItem("order")).id },
      });
    },
    showProductHintForm() {
      if (this.product.additional_checkboxes.length) {
        this.ifShowModal = true;
        this.showBackdrop = true;
      } else {
        this.storeProductOrder();
      }
    },
    hideProductHintForm() {
      this.ifShowModal = false;
      this.showBackdrop = false;
    },
  },
  computed: {
    calculateProductVariant() {
      return this.current_product;
    },
    servicePreset() {
      let newService = [];
      for (let i in this.product.service) {
        let service = this.product.service[i];
        if(service.is_preset == true) {
          newService.push(service);
        }
      }
      return newService;
    },
    serviceNotPreset() {
      let newService = [];
      for (let i in this.product.service) {
        let service = this.product.service[i];
        if(service.is_preset != true) {
          newService.push(service);
        }
      }
      return newService;
    },
    getHidePanel() {
      return store.state.hidePanel;
    },
  },
  watch: {
    current_product: {
      deep: true,
      handler(oldValue, newValue) {
        console.log(newValue);
        console.log("WATCHED");
        if(!this.isManual) {
          this.calculateVariant(newValue);
        }
      },
    },
    getHidePanel(hidePanel) {
      this.hidePanel = hidePanel;
    }
  },
  mounted() {
    let vm = this;
    let orderData = JSON.parse(localStorage.getItem("order"));
    this.orderInfo = {
      number: orderData.custom_number ? orderData.custom_number : orderData.order_number,
      name: orderData.customer.name,
      phone: orderData.customer.phone,
      email: orderData.customer.email,
    }
    setOptions({
      allowRevert: false,
      allowRemove: false,
      labelFileLoading: 'Загрузка...',
      labelFileLoadError: 'Ошибка загрузки',
      maxParallelUploads: 1,
      server: {
        url: process.env.VUE_APP_BASE_URL,
        timeout: 7000,
        process: {
          url:
            "api/auth/orders/order/save-media/" +
            JSON.parse(localStorage.getItem("order")).id,
          method: "POST",
          headers: {
            Authorization: "Bearer " + store.state.auth.token,
            Accept: "application/json",
          },
          onload: (response) => {
            let url = response;
            let resArr = response.split("/");
            let id = resArr[resArr.length - 2];
            vm.current_product.preview.push({
              id: id,
              url: url,
            });
          },
          onerror: (response) => response.data,
          // ondata: (formData) => {
          //     formData.append('Hello', 'World');
          //     return formData;
          // }
        },
        revert: (uniqueFileId, load, error) => {
            console.log('revertId ', uniqueFileId)
            // Should remove the earlier created temp file here
            // ...

            // Can call the error method if something is wrong, should exit after
            error('oh my goodness');

            // Should call the load method when done, no parameters required
            load();
        }
        // revert: './revert',
        // restore: './restore/',
        // load: './load/',
        // fetch: './fetch/'
      },
    });

    this.orderId = JSON.parse(localStorage.getItem("order")).id;
    let promiseManufactures = axios.get(`api/auth/manufacture/list`);
    let promiseProduct = axios.get(`api/auth/orders/product/show/${this.$attrs.id}`);
    Promise.all([promiseManufactures, promiseProduct]).then(function(values) {
      const [manufactures, res] = values;
      vm.manufactures = manufactures.data;
      vm.product = res.data;
      vm.current_product.id = res.data.id;
      vm.current_product.runs = vm.product.runs;
      vm.current_product.production = vm.product.production;
      console.log("Product ", vm.product);
      if (vm.product.parent_variants.length) {
        vm.current_product.parent = vm.product.parent_variants[0];
        vm.getVariants(vm.current_product.parent);
        vm.showParents = true;

        //vm.servicesExceptions
      } else {
        vm.current_product.variant = JSON.parse(
          JSON.stringify(vm.product.variant[0])
        );
        vm.currentVariant = JSON.parse(
          JSON.stringify(vm.product.variant[0])
        );
        vm.variants = vm.product.variant;
      }
      if (vm.product.is_only_services) {
        vm.showParents = false;
        vm.showVariants = false;
      }

      for (let i in vm.product.service) {
        vm.services["service" + vm.product.service[i].id] = false;
        let price = JSON.parse(JSON.stringify(vm.product.service[i].price));
        vm.product.service[i].price = {
          quantity: 0,
          price: price,
        };
      }

      vm.product.runs_range = res.data.runs_range;
      vm.current_product.variant.price = {
        quantity: 0,
        price: [],
      };

      if (vm.product.runs !== 3) {
        vm.current_product.variant.price.quantity = vm.product.runs_range[0];
        vm.current_product.variant.price.price = vm.currentVariant.price;
      } else {
        vm.current_product.variant.price.price[0] = vm.currentVariant.price[0];
      }

      vm.calculateVariant(vm.current_product, "standard");

      vm.showLoader = false;
      vm.$nextTick(function() {
        for(let i = 0; i < vm.product.service.length; i++) {
          let service = vm.product.service[i];
          if(service.is_preset) {
            vm.$refs['check'+service.id][0].click();
            vm.$refs['check'+service.id][0].disabled = true;
          }
          else {
            vm.countOfNotPresets++;
          }
        }
      });

    });

  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.float-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;

  & .fixed-content {
    position: fixed;
    bottom: 0;
    width: calc(100% - 16.875rem);
    height: 100px;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.3);
  }
}

.pb-100 {
  padding-bottom: 100px;
}
.input-calculated {
  padding-left: 0;
  width: 50px;
  background: transparent;
  border: none;
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.57;
  border-bottom: 2px solid white;
}

.input_calculated-primary {
  @extend .input-calculated;
  color:white
}

.input_calculated-light {
  @extend .input-calculated;
  border-bottom: none;
  color:black
}
</style>
