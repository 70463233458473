<template>
  <main class="auth">
    <div class="blankpage-form-field">
      <div class="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">

        <span class="page-logo-text mr-1">OQplus</span>
      </div>
      <div class="card p-4 border-top-left-radius-0 border-top-right-radius-0">
        <form method="post" action="#" @submit.prevent="resetPassword">
          <div class="form-group">
            <input type="email" v-model="email" id="email" class="form-control" placeholder="Ваш логин"
                   value="">
          </div>
          <div class="form-group">
            <input type="password" v-model="password" id="password" class="form-control"
                   placeholder="Ваш пароль"
                   value="">
          </div>

          <div class="form-group">
            <input type="password" id="password_confirmation" class="form-control" placeholder="Повтор пароля" v-model="password_confirmation" required>
          </div>

          <div class="alert alert-danger" role="alert">
<!--            {{errorText}}-->
          </div>

          <button type="submit" class="btn btn-default float-right">Подтвердить</button>

        </form>
      </div>

      <div class="blankpage-footer text-center">
<!--        <a v-on:click="showAuthFormMethod" v-bind:class="{ 'd-none': showRequestForm}"-->
<!--           href="javascript:void(0)"><strong>Вернуться к авторизации</strong></a>-->
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios'

export default {
  name: "ResetPasswordForm",
  data() {
    return {
      token: null,
      email: null,
      password: null,
      password_confirmation: null,
      has_error: false
    }
  },
  methods: {
    resetPassword() {
      axios.post("api/auth/reset/password/", {
        token: this.$route.params.token,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation
      })
          .then(result => {
            console.log(result.data);
            this.$router.push({name: 'Home'})
          }, error => {
            console.error(error);
          });
    }
  }
}
</script>

<style scoped lang="scss">
body, html, #app{
  overflow: hidden;
  width: 100%;
  height: 100%;
}

main.auth {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  background: #ffffff;

.form-group {
  text-align: left;
}

.blankpage-form-field {
  width: 320px;
}

}
</style>