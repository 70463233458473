<template>
  <div class="wrapper wrapper-content animated fadeInRight article">
    <div class="subheader">
      <h1 class="display-4 mb-0">Скачать макет штендера</h1>
    </div>
    <div class="row">
       <div class="form-group mb-2">
          <p class="fw-700 mt-3 mb-1">Введите номер задания на изготовление</p>
          <input v-model="productId" type="text" class="form-control" />
          <button id="finish" @click="getPDF" class="btn btn-primary mt-3">
              Скачать
          </button>
        </div>
    </div>
    <div class="row justify-content-md-center d-none">
      <div class="col-lg-10">
        <div class="ibox">
          <div class="ibox-content">
            <iframe
              id="editorFrame"
              class="border-0"
              width="100%"
              height="1000px"
            ></iframe>
          </div>
          <div class="ibox-content text-right">
            
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" v-if="showLoader"></div>
    <div class="modal fade show d-block" v-if="showLoader">
      <div
        class="fixed modal-dialog modal-dialog-centered justify-content-center"
      >
        <button
          class="btn btn-info waves-effect waves-themed"
          type="button"
          disabled=""
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Загрузка...
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Constructor",
  components: {},
  data() {
    return {
      productId: 0,
      editor: null,
      template: [],
      userId: 0,
      stateId: 0,
      isVertical: 1,
      resultText: "",
      quatro: null,
      CustomersCanvas: window.CustomersCanvas,
      showLoader: false,
      printInfo: {
        order_number: "",
        date: "",
        price: 0,
        text: "",
        address: "",
        paymentType: "",
        division: 0,
        customer: {},
        postpaid: 0
      },
    };
  },
  mounted() {
    
  },
  methods: {
    getPDF(){
      this.showLoader = true;
      axios.get(`api/auth/polk/get/order/${this.productId}`).then((res) => {
      this.printInfo = {...res.data[0]}
      //console.log('printInfo', this.printInfo)
      this.printInfo.address =
        res.data[0].delivery_type.id > 0
          ? res.data[0].delivery_type.name +
            ": " +
            res.data[0].shipment_address.address[0].address
          : res.data[0].shipment_address.name;
      this.printInfo.paymentType = res.data[0].payment_type.name;
      this.template = res.data[1].file_url.split(":");
      this.stateId = this.template[0];
      this.userId = this.template[1];
      this.printInfo.text =
        res.data[1].name + ": " + res.data[1].tirazh + " шт.; ";
      for (let i in res.data[2]) {
        this.printInfo.text +=
          res.data[2][i].name + ": " + res.data[2][i].tirazh + " шт.; ";
      }

      let ccScript = document.createElement("script");
      ccScript.setAttribute(
        "src",
        "https://gen.copy.spb.ru/prod/DE/SimplePolygraphy/resources/generated/iframeapi.js"
      );
      ccScript.setAttribute("id", "CcIframeApiScript");
      document.head.appendChild(ccScript);
      ccScript.onload = () => {
        this.loadEditor();
      };
    });
    },
    getUrlVars() {
      var vars = [],
        hash;

      var hashes = window.location.href
        .slice(window.location.href.indexOf("?") + 1)
        .split("&");

      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");

        vars.push(hash[0]);

        vars[hash[0]] = hash[1];
      }

      return vars;
    },
    loadEditor() {
      let productDefinition = this.stateId;

      var iframe = document.getElementById("editorFrame");

      //var frameParent = iframe.parent();
      //Loading the product into the editor.
      //var editor = null;
      //frameParent.mask("Загрузка...");
      //Loading the editor.
      let vm = this;
      let userInfo = {
          num: vm.printInfo.order_number,
          date: vm.printInfo.shipment_date,
          price: vm.printInfo.postpaid+ ' р.',
          name: ' '+vm.printInfo.customer.name,
          text: vm.printInfo.text,
          phone: vm.printInfo.customer.phone,
          copycenter: vm.printInfo.address,
          priceType: vm.printInfo.paymentType,
      }
      if (vm.printInfo.division !== 51){
          userInfo.gift = ''
      }
      window.CustomersCanvas.IframeApi.loadEditor(iframe, productDefinition, {
        //autoLoadUserInfo: true,
        //userInfo: {"txt_brand": "OQ print"},
        userInfo: userInfo,
        defaultLanguage: "ru",
        rendering: {
          proofImageMockupEnabled: false,
          hiResOutputDpi: 200,
        },
        userId: vm.userId,
        initialMode: "Advanced",
        preloader: {
          firstTimeMessage: "Подготовка макета. Пожалуйста, подождите...",
        },
        widgets: {
          FinishButton: {
            mode: "Disabled",
          },
          LeftToolbar: {
            qrCodeButtonEnabled: false,
            linearBarcodeButtonEnabled: false,
          },
          TopToolbar: {
            opacitySliderEnabled: false,
            borderColorButtonEnabled: false,
            fillColorButtonEnabled: false,
            fontSize: {
              min: 1.5,
            },
          },
          BottomToolbar: {
            surfaceSwitch: {
              showThumbnails: false,
            },
          },
        },
      })
        //If the editor has been successfully loaded.
        .then(function (e) {
          vm.editor = e;
          vm.finishDesign()
          /* if (edit != 1) {
                        editor.loadUserInfo(userInfo);
                    }*/
        })
        //If there was an error thrown when loading the editor.
        .catch(function (error) {
          console.error("The editor failed to load with an exception: ", error);
        });

      /*document.getElementById('clear').addEventListener('click', function(e) {
                localStorage.removeItem("globalCart");
            })*/
    },
    finishDesign() {
      let vm = this;
      this.addPlaceholder().then(function(){
                vm.editor.finishProductDesign({
                    proofMaxHeight: 750,
                    proofMaxWidth: 750
                }).then(function(result) {    
                    vm.showLoader = false;
                    window.location.href=result.hiResOutputUrls[0];
                })
            });
    },
    async addPlaceholder() {
      let product = await this.editor.getProduct();
      let Model = window.CustomersCanvas.DesignAtoms.ObjectModel;
      let currentSurface = product.currentSurface;
      let image, text;

      var vertical = 1;
      if (currentSurface.width > currentSurface.height) {
        vertical = 0;
      }

      var coordX = vertical ? currentSurface.width - 105 : 5;
      image = new Model.BarcodeItem(
        "CODE_128",
        this.productId,
        null,
        new Model.RectangleF(coordX, 40, 90, 20)
      );
      image.manipulationPermissions.allowDelete = false;
      image.manipulationPermissions.allowMoveHorizontal = false;
      image.manipulationPermissions.allowMoveVertical = false;
      image.manipulationPermissions.allowRotate = false;
      image.manipulationPermissions.allowDragAndDrop = false;
      image.manipulationPermissions.allowResize = false;
      image.contentResizeMode = 1;
      image.manipulationPermissions.resizeGrips.corner = [];
      image.manipulationPermissions.resizeGrips.edge = false;
      image._transform._angle = vertical ? 0 : -90;
      image.name = "barcode_layer";

      text = new Model.PlainTextItem(
        this.productId,
        new Model.PointF(coordX + (vertical ? 20 : 55), 65  + (vertical ? 10 : 0)),
        'Roboto-Regular',
        12
      )
      text._transform._angle = vertical ? 0 : -90;
      text.name = "barcode_text";

      currentSurface.insertItem(image);
      currentSurface.insertItem(text);
      image = {};
      text = {};
    },
  },
};
</script>
<style>
.white {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: #ffffff;
  width: 100%;
  height: 100%;
}
.sk-spinner {
  top: 50%;
}
</style>
