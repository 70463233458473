<template>
  <div class="pb-100">
     <button
            @click="backToOrder"
            name="reset"
            class="btn btn-default btn-md btn-block waves-effect waves-themed col-2 mt-0 mr-3"
          >
            <i class="fal fa-arrow-alt-left mr-1"></i> К заказу
          </button>
    <div class="d-flex mt-5">
      <div class="col-6">
        <div class="form-group">
          <h6 class="mb-1 fw-500">
              Наименование
            </h6>
            <p class="mb-2">{{ product.name }} <sup>({{position_number}} из {{orderInfo.productsNum}})</sup></p>
            <h6 class="mb-1 fw-500">
              Количество
            </h6>
            <p class="mb-2" v-if="product.runs !== 3">{{current_product.variant.price.quantity}}</p>
            <p class="mb-2" v-if="product.runs === 3">{{current_product.variant.price.price[0].quantity}}</p>
          <div v-if="showParents">
            <h6 class="mb-1 fw-500">
              {{
                product.parent_title
                  ? product.parent_title
                  : "Родительский вариант продукта"
              }}
            </h6>
            <p class="mb-2">{{current_product.parent.name}}</p>
          </div>
          <div v-if="showVariants">
            <h6 class="mb-1 fw-500">
              {{
                product.variant_title
                  ? product.variant_title
                  : "Вариант продукта"
              }}
            </h6>
            <p class="mb-2">{{current_product.variant.name}}</p>
          </div>
          <div v-if="current_product.width">
            <h6 class="mb-1 fw-500">Ширина (мм)</h6>
            <p>{{current_product.width}}</p>
          </div>
          <div v-if="current_product.height">
            <h6 class="mb-1 fw-500">Высота (мм)</h6>
            <p>{{current_product.height}}</p>
          </div>
          <div class="mb-2" v-for="service in current_product.services" v-bind:key="service.id">
            <h6 class="mb-1 fw-500">{{service.name}}</h6>
            <p v-if="service.current_variant">{{service.current_variant}}</p>
            <p v-if="service.comment">{{service.comment}}</p>
            <p v-if="service.custom_quantity">Количество: {{service.custom_quantity}}</p>
          </div>
        </div>
      </div>
      <div class="col-6">
            <h6 class="mb-1 fw-500">
              Макет
            </h6>
            <p class="mb-2">{{current_product.file_url}}</p>
            <h6 class="mb-1 fw-500">
              Дата выдачи
            </h6>
            <p class="mb-2">{{current_product.shipment_date}}</p>
            <h6 class="mb-1 fw-500">
              Стоимость дизайна
            </h6>
            <p class="mb-2">{{current_product.design_price}} руб.</p>
            <h6 class="mb-1 fw-500">
              Общая стоимость
            </h6>
            <p class="mb-2">{{current_product.cost}} руб.</p>
            <h6 class="mb-1 fw-500">
              Комментарий
            </h6>
            <p class="mb-2">{{current_product.comment}}</p>
      </div>
    </div>

        <hr class="mt-4 mb-g" />
        <h5 class="mb-2">Превью</h5>
          <div v-if="current_product.preview.length" class="row m-0">
            <div
              v-for="(image, i) in current_product.preview"
              v-bind:key="i"
              class="col-4 position-relative m-2 p-0"
            >
              <img :src="image.url" class="w-100" alt="" />
            </div>
          </div>

    <div class="modal-backdrop fade show" v-if="showLoader"></div>
    <div class="modal fade show d-block" v-if="showLoader">
      <div
        class="fixed modal-dialog modal-dialog-centered justify-content-center"
      >
        <button
          class="btn btn-info waves-effect waves-themed"
          type="button"
          disabled=""
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Загрузка...
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import Multiselect from "vue-multiselect";
/*import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'*/
import router from "@/router";
import store from "../../store";


export default {
  name: "ProductView",
  components: {
 //   Multiselect
  },
  data() {
    return {
      showLoader: true,
      product: {
        parent_variants: [],
        variant: [],
        service: {},
        runs: 1,
        runs_range: { 0: 0 },
      },
      current_product: {
        id: null,
        runs: 1,
        production: 1,
        preview: [],
        parent: {},
        variant: {
          price: {
            quantity: 0,
            price: [],
          },
        },
        countedPrice: {
          shipment_date: null,
          quantity: null,
          price: null,
        },
        totalPrice: {},
        services: [],
        file_url: null,
        design_price: null,
        comment: null,
        width: 0,
        height: 0,
        discount: 0,
        cost: 0,
        shipment_date: '',
        current_product: ''
      },
      position_number: 0,
      calculatedPrice: [],
      variants: [],
      currentVariant: {},
      services: [],
      timings: {},
      showParents: false,
      showVariants: true,
      showServices: true,
      showSquare: false,
      servicesExceptions: [],
      selectedButton: null,
      countOfNotPresets: 0,
      manualValue: [],
      hidePanel: store.state.hidePanel,
      serviceComments: [],
    };
  },
  methods: {
    switchServices() {
      this.showServices = !this.showServices;
    },
    backToOrder() {
      router.push({
        name: "orderEdit",
        params: { id: JSON.parse(localStorage.getItem("order")).id },
      });
    },
    getVariants(parent) {
      this.variants = [];
      console.log(this.product.variant);
      console.log(parent);
      for (let k in this.product.variant) {
        if (this.product.variant[k].parent_variant_id === parent.id) {
          this.variants.push(this.product.variant[k]);
        }
      }
      let currentPrice = JSON.parse(
        JSON.stringify(this.current_product.variant.price)
      );
      if (currentPrice.price.length) {
        this.current_product.variant = JSON.parse(
          JSON.stringify(this.variants[0])
        );
        this.currentVariant = JSON.parse(JSON.stringify(this.variants[0]));
        this.current_product.variant.price = currentPrice;
        if (this.product.runs === 3) {
          for (let price in this.currentVariant.price) {
            if (
              currentPrice.price[0].quantity ===
              this.currentVariant.price[price].quantity
            ) {
              this.current_product.variant.price.price[0] = this.currentVariant.price[
                price
              ];
            }
          }
        } else {
          this.current_product.variant.price.price = this.currentVariant.price;
        }
      } else {
        this.current_product.variant = JSON.parse(
          JSON.stringify(this.variants[0])
        );
        this.currentVariant = JSON.parse(JSON.stringify(this.variants[0]));
        this.current_product.variant.price = {
          quantity: 0,
          price: [],
        };
        if (this.product.runs !== 3) {
          this.current_product.variant.price.quantity = Object.keys(
            this.product.runs_range
          )[0];
          this.current_product.variant.price.price = this.currentVariant.price;
        } else {
          this.current_product.variant.price.price[0] = this.currentVariant.price[0];
        }
      }
    },

    async getOrderProduct(loaded_product) {
      let productShow = await axios.get(`api/auth/product/show/${loaded_product}`);
      let current_product = productShow.data;
      let product_id = current_product.product_id;
      let res = await axios.get(`api/auth/orders/product/show/${product_id}`)
      this.product = res.data;
      this.current_product.id = res.data.id;
      this.current_product.runs = this.product.runs;
      this.current_product.production = this.product.production;
      this.current_product.preview = current_product.preview;
      this.current_product.width = this.product.width;
      this.current_product.height = this.product.height;

      this.position_number = current_product.position_number

      if (this.product.parent_variants.length) {
        this.current_product.parent = this.product.parent_variants[0];
        this.getVariants(this.current_product.parent);
        this.showParents = true;
      } else {
        this.current_product.variant = JSON.parse(
          JSON.stringify(this.product.variant[0])
        );
        this.currentVariant = JSON.parse(
          JSON.stringify(this.product.variant[0])
        );
        this.variants = this.product.variant;
      }
      if (this.product.is_only_services) {
        this.showParents = false;
        this.showVariants = false;
      }

      this.current_product.variant.price = {
        quantity: 0,
        price: [],
      };

      if (this.product.runs !== 3) {
        this.current_product.variant.price.quantity = Object.keys(
          this.product.runs_range
        )[0];
        this.current_product.variant.price.price = this.currentVariant.price;
      } else {
        this.current_product.variant.price.price[0] = this.currentVariant.price[0];
      }
      this.current_product = JSON.parse(
        JSON.stringify(current_product.current_product)
      );
      this.current_product.cost = current_product.variant_cost;
      this.current_product.shipment_date = current_product.shipment_date;

      this.current_product.services = [];
      this.showLoader = false;
      this.current_product.services = current_product.services
      return {
        current_product: current_product
      }
    }
  },
  computed: {
    getHidePanel() {
      return store.state.hidePanel;
    },
  },
  watch: {
    getHidePanel(hidePanel) {
      this.hidePanel = hidePanel;
    }
  },
  created() {

    let orderData = JSON.parse(localStorage.getItem("order"));
    this.orderInfo = {
      number: orderData.custom_number ? orderData.custom_number : orderData.order_number,
      name: orderData.customer.name,
      phone: orderData.customer.phone,
      email: orderData.customer.email,
      productsNum: orderData.product_orders.length
    }

    let loaded_product = this.$route.params.id;
    let orderProduct = this.getOrderProduct(loaded_product);
    let promises = [];
    promises.push(orderProduct)
    Promise.all(promises).then((res) => {
      let current_product = res[0].current_product;
      console.log('current_product: ', current_product)
    })
      
  }
};
</script>

