import DivisionList from "../components/Division/DivisionsList"
import Division from "../components/Division/Division"
import CreateDivision from "../components/Division/CreateDivision"
import ManageUsers from "../components/Division/ManageUsers";

import authMiddleware from './middleware/auth'
import divisionsMiddleware from './middleware/divisions'

export default [
    {
        path: '/divisions',
        name: 'divisions',
        component: Division,
        meta: {
          middleware: [
            authMiddleware, divisionsMiddleware
          ],
        },
        props: true,
        children: [
            {
                path: '/divisions/list',
                name: 'divisionsList',
                component: DivisionList,
                meta: {
                  middleware: [
                    authMiddleware, divisionsMiddleware
                  ],
                },
            },
            {
                path: '/divisions/create',
                name: 'divisionCreate',
                component: CreateDivision,
                meta: {
                  middleware: [
                    authMiddleware, divisionsMiddleware
                  ],
                },
            },
            {
                path: '/divisions/users/:id',
                name: 'manageUsers',
                component: ManageUsers,
                meta: {
                  middleware: [
                    authMiddleware, divisionsMiddleware
                  ],
                },
            },
            {
                path: '/divisions/edit/:id',
                name: 'editDivision',
                component: CreateDivision,
                meta: {
                  middleware: [
                    authMiddleware, divisionsMiddleware
                  ],
                },
            }
        ]
    }
]
