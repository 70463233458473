<template>
  <div>
    <h1 class="display-4 mb-3">Редактирование продукта "{{ product.name }}"</h1>
    <ul class="nav nav-tabs nav-tabs-clean" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link"
          @click.prevent="setActive('tab-params')"
          :class="{ active: isActive('tab-params') }"
          href="#tab-params"
          role="tab"
          >Параметры продукта</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          @click.prevent="setActive('tab-variants')"
          :class="{ active: isActive('tab-variants') }"
          href="#tab-variants"
          role="tab"
          >Варианты продукта</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          @click.prevent="setActive('tab-services')"
          :class="{ active: isActive('tab-services') }"
          href="#tab-services"
          role="tab"
          >Дополнительные услуги</a
        >
      </li>
    </ul>
    <div class="tab-content p-3">
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive('tab-params') }"
        id="tab-params"
        role="tabpanel"
      >
        <form
          action=""
          enctype="multipart/form-data"
          @submit.prevent="storeProduct"
          method="post"
        >
          <div class="form-group">
            <h3 class="mt-5">Категория</h3>
            <multiselect
              v-model="category"
              track-by="name"
              label="name"
              :options="allCategories"
              :searchable="true"
              :show-labels="false"
              :allow-empty="false"
              placeholder=""
            >
              <template slot="singleLabel" slot-scope="{ option }">{{
                option.name
              }}</template>
            </multiselect>
          </div>
          <!--    Наименование   -->
          <hr class="mt-4 mb-g" />
          <div class="form-group">
            <h3 for="" class="form-label">Наименование</h3>
            <input type="text" class="form-control" v-model="product.name" />
          </div>

          <!--    Опубликован -->
          <hr class="mt-4 mb-g" />
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                v-model="product.published"
                class="custom-control-input"
                id="productPublish"
              />
              <label class="custom-control-label" for="productPublish"
                >Опубликован</label
              >
            </div>
            <div class="custom-control custom-switch mt-3">
              <input
                type="checkbox"
                v-model="product.has_preview"
                class="custom-control-input"
                id="productPreview"
              />
              <label class="custom-control-label" for="productPreview"
                >Обязательное превью</label
              >
            </div>
          </div>

          <!-- Тип тиражности -->
          <hr class="mt-4 mb-g" />
          <div class="form-group">
            <h3>Тиражность</h3>
            <label for="" class="form-label"
              >Тип тиражности {{ product.runs }}</label
            >
            <multiselect
              v-model="runValue"
              @select="changeType"
              track-by="name"
              label="name"
              :options="runTypes"
              :show-labels="false"
              :searchable="false"
              :allow-empty="false"
              placeholder=""
            >
              <template slot-scope="{ option }">{{ option.name }}</template>
            </multiselect>
          </div>
          <div class="form-group" v-bind:class="{ 'd-none': hideRunsAdd }">
            <label for="" class="form-label">Добавление {{ typeText }}</label>
            <div class="row m-0">
              <input
                v-for="(value, index) in product.runs_range"
                v-bind:key="index"
                type="number"
                :data-index="index"
                :value="value"
                v-on:change="changeValue"
                class="form-control text-center col-1 mr-3"
              />
              <span
                v-on:click="addValue"
                title="Добавить"
                class="btn btn-primary btn-md btn-icon rounded-circle waves-effect waves-themed"
              >
                <i class="fal fa-plus"></i>
              </span>
              <span
                v-on:click="deleteValue"
                class="ml-3 btn btn-danger btn-md btn-icon rounded-circle waves-effect waves-themed"
                v-bind:class="{ 'd-none': hideDeleteValue }"
              >
                <i class="fal fa-minus"></i>
              </span>
            </div>
          </div>

          <!--  Минимальная стоимость  -->
          <hr class="mt-4 mb-g" />
          <div class="form-group">
            <h3>Минимальная стоимость</h3>
            <input
              type="number"
              class="form-control col-2"
              v-model="product.min_price"
            />
          </div>
          <hr class="mt-4 mb-g" />
          <div class="form-group">
            <h3>Сроки изготовления</h3>

            <div
              class="d-flex mt-5 mb-5"
              style="align-items: flex-end"
              v-for="(timing, key) in timings"
              :key="key"
            >
              <div class="col-2 pl-0">
                <label class="form-label">Наименование срока</label>
                <input type="text" class="form-control" v-model="timing.name" />
              </div>
              <div class="col-2 pl-0">
                <label class="form-label">Доплата / скидка, %</label>
                <input
                  type="number"
                  class="form-control"
                  v-model.number="timing.discount"
                />
              </div>
              <div class="col-2">
                <label class="form-label">Срок, дней</label>
                <input
                  type="number"
                  class="form-control"
                  v-model.number="timing.days"
                />
              </div>
              <div class="col-3">
                <multiselect
                  v-model="timing.manufacture"
                  track-by="name"
                  label="name"
                  :options="manufactureOptions"
                  :searchable="true"
                  :show-labels="false"
                  :allow-empty="false"
                  placeholder=""
                />
              </div>
              <div class="custom-control custom-radio col-2">
                <input
                  type="radio"
                  class="custom-control-input"
                  :name="timing_default"
                  :id="'timing_default_' + key"
                  v-model="timing_default"
                  :value="key"
                />
                <label
                  class="custom-control-label"
                  :for="'timing_default_' + key"
                  >По умолчанию</label
                >
              </div>
              <div class="mt-4">
                <span
                  v-on:click="deleteTiming(key, timing.id)"
                  class="ml-3 btn btn-danger btn-md btn-icon rounded-circle waves-effect waves-themed"
                >
                  <i class="fal fa-minus"></i>
                </span>
              </div>
            </div>
            <span
              @click="addTiming"
              title="Добавить"
              class="btn btn-primary btn-md btn-icon rounded-circle waves-effect waves-themed"
            >
              <i class="fal fa-plus"></i>
            </span>
          </div>

          <div class="form-group col-2 pl-0">
            <h4 class="mt-4 mb-3">Прием заказа (до)</h4>
            <input
              type="time"
              class="form-control"
              v-model="product.accept_before"
              min="09:00"
              max="21:00"
            />
          </div>
          <div class="form-group col-2 pl-0">
            <h4 class="mt-4 mb-3">Выдача заказа (с)</h4>
            <input
              type="time"
              id="avatar"
              class="form-control"
              v-model="product.deliver_after"
            />
          </div>

          <div class="form-group d-none">
            <h4 class="mt-4 mb-3">Изготовление</h4>
            <div class="d-flex">
              <div class="custom-control custom-radio col-2">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="producerTypeSelf"
                  name="defaultExampleRadios"
                  value="1"
                  v-model="product.production"
                />
                <label class="custom-control-label" for="producerTypeSelf"
                  >Собственное (КЦ или Производство)</label
                >
              </div>
              <div class="custom-control custom-radio col-2">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="producerType1"
                  name="defaultExampleRadios"
                  value="2"
                  v-model="product.production"
                />
                <label class="custom-control-label" for="producerType1"
                  >Подрядчик 1 (не выдает в вс)</label
                >
              </div>
              <div class="custom-control custom-radio col-2">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="producerType2"
                  name="defaultExampleRadios"
                  value="3"
                  v-model="product.production"
                />
                <label class="custom-control-label" for="producerType2"
                  >Подрядчик 2 (не выдает в сб и вс)</label
                >
              </div>
              <div class="custom-control custom-radio col-2">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="producerType3"
                  name="defaultExampleRadios"
                  value="4"
                  v-model="product.production"
                />
                <label class="custom-control-label" for="producerType3"
                  >Подрядчик 3 (не выдает и не изготавливает на выходных)</label
                >
              </div>
              <div class="custom-control custom-radio col-2">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="producerType4"
                  name="defaultExampleRadios"
                  value="5"
                  v-model="product.production"
                />
                <label class="custom-control-label" for="producerType4"
                  >Подрядчик 4 (не работает на выходных)</label
                >
              </div>
            </div>
          </div>
          <hr class="mt-4 mb-g" />
          <h3 class="mb-3">Параметры расчета</h3>
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                v-model="product.is_only_services"
                class="custom-control-input"
                id="onlyServices"
              />
              <label class="custom-control-label" for="onlyServices"
                >Состоит только из услуг</label
              >
            </div>
          </div>
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                v-model="product.is_diff_product_types"
                class="custom-control-input"
                id="diffProductTypes"
              />
              <label class="custom-control-label" for="diffProductTypes"
                >Тираж (разные варианты продукта)</label
              >
            </div>
          </div>
          <div class="form-group">
            <h4 class="mt-4 mb-3">Наименование родительских вариантов</h4>
            <input
              type="text"
              class="form-control"
              v-model="product.parent_title"
            />
          </div>
          <div class="form-group">
            <h4 class="mt-4 mb-3">Наименование вариантов</h4>
            <input
              type="text"
              class="form-control"
              v-model="product.variant_title"
            />
          </div>
          <hr class="mt-4 mb-g" />
          <h3 class="mb-3">Примечания</h3>
          <div class="form-group">
            <label class="form-label">Описание продукта</label>
            <ckeditor
              :editor="editor"
              v-model="product.comments"
              :config="editorConfig"
              id="productComment"
            ></ckeditor>
          </div>
          <div class="form-group">
            <label class="form-label">Важные примечания</label>
            <div v-if="product.additional_checkboxes.length">
              <input
                v-for="(value, index) in product.additional_checkboxes"
                v-bind:key="index"
                type="text"
                :data-index="index"
                :value="value"
                v-on:change="changeNote"
                class="form-control col-6 mb-3"
              />
            </div>
            <div class="mt-4">
              <span
                v-on:click="addNote"
                title="Добавить"
                class="btn btn-primary btn-md btn-icon rounded-circle waves-effect waves-themed"
              >
                <i class="fal fa-plus"></i>
              </span>
              <span
                v-on:click="deleteNote"
                class="ml-3 btn btn-danger btn-md btn-icon rounded-circle waves-effect waves-themed"
                v-bind:class="{ 'd-none': hideDeleteNote }"
              >
                <i class="fal fa-minus"></i>
              </span>
            </div>
          </div>
          <div v-if="!hideErrors" class="alert alert-danger" role="alert">
            <h1><strong>Пожалуйста, исправьте ошибки:</strong></h1>
            <ul>
              <li v-for="(error, index) in this.errors" :key="index">
                {{ error[0] }}
              </li>
            </ul>
          </div>
          <div class="d-flex flex-row-reverse">
            <input
              type="submit"
              class="btn btn-primary btn-lg btn-block waves-effect waves-themed col-2"
              value="Обновить"
            />
          </div>
          <div
            class="modal fade show"
            v-bind:class="{ 'd-block': showModal }"
            id="default-example-modal-center"
            tabindex="-1"
            role="dialog"
            aria-modal="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">
                    Продукт успешно {{ modalText }}!
                    <!--<small class="m-0 text-muted">
                  Below is a static modal example
              </small>-->
                  </h4>
                  <button
                    type="button"
                    @click.prevent="hideModal"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                  </button>
                </div>
                <!-- <div class="modal-body">

           </div>-->
                <div class="modal-footer">
                  <!--<button type="button" @click.prevent="hideModal" class="btn btn-secondary waves-effect waves-themed" data-dismiss="modal">Close</button>-->
                  <!-- <button type="button" @click.prevent="setActiveTab('tab-variants')"
                    class="btn btn-primary waves-effect waves-themed">Перейти к вариантам
            </button>
            <button type="button" @click.prevent="setActiveTab('tab-services')"
                    class="btn btn-primary waves-effect waves-themed">Перейти к дополнительным услугам
            </button> -->
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-backdrop fade show"
            v-bind:class="{ 'd-none': !showModal }"
          ></div>
        </form>
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive('tab-variants') }"
        id="tab-variants"
        role="tabpanel"
      >
        <div class="text-center">
          <button
            @click.prevent="addVariant"
            type="button"
            class="btn btn-md btn-info waves-effect waves-themed"
          >
            <span class="fal fa-plus mr-1"></span>
            Добавить вариант продукта
          </button>
        </div>
        <!-- Список вариантов -->

        <form action="" enctype="multipart/form-data" method="post">
          <h3 class="mb-5 mt-5">Список вариантов продукта</h3>
          <div
            class="alert alert-primary"
            role="alert"
            v-if="Object.keys(allVariants).length == 0"
          >
            Варианты продукта отсутствуют.
          </div>

          <div v-for="(parent, index) in allParent" :key="index" class="panel">
            <div class="panel-hdr">
              <h2>
                {{ parent.name }}
              </h2>
              <div class="panel-toolbar">
                <button
                  type="button"
                  @click.prevent="editVariant(parent.variant_id)"
                  class="btn btn-outline-primary btn-sm btn-icon waves-effect waves-themed mr-3"
                >
                  <span aria-hidden="true"><i class="fal fa-edit"></i></span>
                </button>
                <button
                  type="button"
                  @click.prevent="deleteVariant(parent.variant_id)"
                  class="btn btn-outline-danger btn-sm btn-icon waves-effect waves-themed"
                >
                  <span aria-hidden="true"
                    ><i class="fal fa-trash-alt"></i
                  ></span>
                </button>
              </div>
            </div>
            <div class="panel-content">
              <div class="frame-wrap p-3">
                <table class="table table-striped m-0">
                  <tbody>
                    <tr v-for="(variant, index) in allVariants" :key="index">
                      <td v-if="variant.parent_variant_id == parent.id">
                         {{ variant.name }} <span v-if="variant.sortable" class="text-muted">({{variant.sortable}})</span>
                      </td>
                      <td
                        class="text-right"
                        v-if="variant.parent_variant_id == parent.id"
                      >
                        <button
                          type="button"
                          @click.prevent="editVariant(variant.id)"
                          class="btn btn-sm btn-icon waves-effect waves-themed mr-3"
                        >
                          <span aria-hidden="true"
                            ><i class="fal fa-edit"></i
                          ></span>
                        </button>
                        <button
                          type="button"
                          @click.prevent="deleteVariant(variant.id)"
                          class="btn btn-sm btn-icon waves-effect waves-themed"
                        >
                          <span aria-hidden="true"
                            ><i class="fal fa-trash-alt"></i
                          ></span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div v-if="Object.keys(rootVariants).length" class="panel">
            <div class="panel-hdr">
              <h2>Варианты без указания родительских</h2>
            </div>
            <div class="panel-content">
              <div class="frame-wrap p-3">
                <table class="table table-striped m-0">
                  <tbody>
                    <tr v-for="(variant, index) in rootVariants" :key="index">
                      <td>{{ variant.name }} <span v-if="variant.sortable" class="text-muted">({{variant.sortable}})</span></td>
                      <td class="text-right">
                        <button
                          type="button"
                          @click.prevent="editVariant(variant.id)"
                          class="btn btn-sm btn-icon waves-effect waves-themed mr-3"
                        >
                          <span aria-hidden="true"
                            ><i class="fal fa-edit"></i
                          ></span>
                        </button>
                        <button
                          type="button"
                          @click.prevent="deleteVariant(variant.id)"
                          class="btn btn-sm btn-icon waves-effect waves-themed"
                        >
                          <span aria-hidden="true"
                            ><i class="fal fa-trash-alt"></i
                          ></span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div
        class="tab-pane fade"
        :class="{ 'active show': isActive('tab-services') }"
        id="tab-services"
        role="tabpanel"
      >
        <div class="text-center">
          <button
            @click.prevent="addService"
            type="button"
            class="btn btn-md btn-info waves-effect waves-themed"
          >
            <span class="fal fa-plus mr-1"></span>
            Добавить дополнительную услугу
          </button>
        </div>
        <!-- Список услуг -->
        <form action="" enctype="multipart/form-data" method="post">
          <h3 class="mb-5 mt-5">Список дополнительных услуг</h3>
          <div
            class="alert alert-primary"
            role="alert"
            v-if="Object.keys(services).length == 0"
          >
            Дополнительные услуги отсутствуют.
          </div>
          <div
            class="alert alert-secondary"
            v-for="service in services"
            :key="service.id"
          >
            <div class="d-flex align-items-center">
              <div class="flex-1">{{ service.name }}</div>
              <button
                type="button"
                @click.prevent="editService(service.id)"
                class="btn btn-primary mr-3"
              >
                <span aria-hidden="true"><i class="fal fa-edit"></i></span>
              </button>
              <button
                type="button"
                @click.prevent="deleteService(service.id)"
                class="btn btn-danger"
              >
                <span aria-hidden="true"><i class="fal fa-trash-alt"></i></span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/ru";

export default {
  name: "ProductEdit",
  components: { Multiselect },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        language: "ru",
      },
      runValue: {},
      category: null,
      allCategories: [],
      product: {
        runs_range: [],
        additional_checkboxes: [],
      },
      activeItem: "tab-params",
      typeText: "",
      hideRunsAdd: true,
      hideDeleteValue: true,
      hideDeleteNote: true,
      errors: [],
      manufactureOptions: [
        { name: "Производство OQ", id: 1 },
        { name: "Стороннее производство", id: 2 },
      ],
      hideErrors: true,
      isUpdate: false,
      isEdit: false,
      showModal: false,
      modalText: "сохранён",
      runTypes: [
        {
          name: "Тиражный",
          id: 3,
        },
        {
          name: "Интервальный",
          id: 2,
        },
        {
          name: "За единицу",
          id: 1,
        },
        {
          name: "За м2",
          id: 4,
        },
        {
          name: "Периметр",
          id: 5,
        },
      ],
      rootVariants: {},
      allVariants: {},
      allParent: {},
      services: {},
      timings: [
        {
          name: "",
          days: 0,
          discount: 0,
          manufacture: {
            name: "Производство OQ",
            id: 1,
          },
          manufacture_id: 1,
          default: false,
        },
      ],
      timing_default: 0,
    };
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    reciveProduct() {
      axios.get(`api/auth/product/${this.$route.params.id}`).then((res) => {
        axios.get(`api/auth/timings/${this.$route.params.id}`).then((res) => {
          this.timings = res.data;
          this.timing_default = this.timings
            .map(function (item) {
              return item.default;
            })
            .indexOf(true);
        });
        console.log("Product: ", res.data);
        let runIndex = this.runTypes
          .map(function (item) {
            return item.id;
          })
          .indexOf(res.data.runs);
        this.product = res.data;
        this.manufactures = this.product.manufactures ?? [];
        this.product.additional_checkboxes = res.data.additional_checkboxes;
        this.runValue = this.runTypes[runIndex];
        switch (res.data.runs) {
          case 1:
            this.hideRunsAdd = true;
            break;
          case 2:
            this.typeText = "интервалов";
            this.hideRunsAdd = false;
            break;
          case 3:
            this.typeText = "тиражей";
            this.hideRunsAdd = false;
            break;
          case 4:
            this.typeText = "интервалов";
            this.hideRunsAdd = false;
            break;
          case 5:
            this.typeText = "интервалов";
            this.hideRunsAdd = false;
            break;
        }
        this.category = res.data.category;
        this.allParent = res.data.parent_variants;
        this.allVariants = res.data.variant;
        let rootVariants = {};
        for (const [key, value] of Object.entries(this.allVariants)) {
          if (!value.is_parent && value.parent_variant_id == null) {
            rootVariants[key] = value;
          }
        }
        this.rootVariants = rootVariants;
        this.services = res.data.service;
        if (res.data.additional_checkboxes.length > 0) {
          this.hideDeleteNote = false;
        }

        localStorage.setItem("product", JSON.stringify(res.data));
      });
    },
    getVariants() {
      axios.get(`api/auth/product/${this.$route.params.id}`).then((res) => {
        this.allParent = res.data.parent_variants;
        this.allVariants = res.data.variant;
        let rootVariants = {};
        for (const [key, value] of Object.entries(this.allVariants)) {
          if (!value.is_parent && value.parent_variant_id == null) {
            rootVariants[key] = value;
          }
        }
        this.rootVariants = rootVariants;
      });
    },
    getServices() {
      axios.get(`api/auth/product/${this.$route.params.id}`).then((res) => {
        console.log(res.data.service);
        this.services = res.data.service;
      });
    },
    storeProduct() {
      this.product.runs = this.runValue.id;
      this.product.category = this.category;
      this.product.manufactures = this.manufactures;
      let promises = [];

      this.timings[this.timing_default].default = true
      this.timings.forEach((timing, key) => {
        if(!(this.timing_default === key)) timing.default = false
        timing.manufacture_id = timing.manufacture.id;
        promises.push(axios
          .put(`api/auth/timings/${timing.id}`, {
            timing: timing
          }))
      });
      promises.push(axios
        .put(`api/auth/product/${this.$route.params.id}`, this.product));

      Promise.all(promises).then(() => {
        alert('Продукт успешно обновлён!')
        this.hideErrors = true;
        this.errors = [];
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.hideErrors = false;
          this.errors = error.response.data.errors;
        }
      });
    },

    changeType(value) {
      this.product.runs = value.id;
      switch (this.product.runs) {
        case 1:
          this.product.runs_range = [1];
          this.hideRunsAdd = true;
          break;
        case 2:
          this.typeText = "интервалов";
          this.hideRunsAdd = false;
          this.product.runs_range = [];
          break;
        case 3:
          this.typeText = "тиражей";
          this.hideRunsAdd = false;
          this.product.runs_range = [];
          break;
        case 4:
          this.typeText = "интервалов";
          this.hideRunsAdd = false;
          this.product.runs_range = [];
          break;
        case 5:
          this.typeText = "интервалов";
          this.hideRunsAdd = false;
          this.product.runs_range = [];
          break;
      }
    },
    addVariant() {
      this.$router.push(`/products/variant/add`);
    },
    addService() {
      this.$router.push(`/products/service/add`);
    },
    editVariant(id) {
      this.$router.push(`/products/variant/${id}`);
    },
    editService(id) {
      this.$router.push(`/products/service/${id}`);
    },
    deleteVariant(id) {
      axios
        .delete(`api/auth/variants/${id}`)
        .then((res) => {
          console.log(res.data);
          this.getVariants();
          alert("Вариант успешно удален!");
        })
        .catch((error) => {
          console.log(error);
          alert("Вариант не удалось удалить!");
        });
    },
    deleteService(id) {
      axios
        .delete(`api/auth/services/${id}`)
        .then((res) => {
          console.log(res.data);
          this.getServices();
          alert("Услуга успешно удалена!");
        })
        .catch((error) => {
          console.log(error);
          alert("Услугу не удалось удалить!");
        });
    },
    addValue() {
      this.product.runs_range.push(0);
      this.hideDeleteValue = false;
    },
    changeValue(e) {
      this.product.runs_range[e.target.dataset.index] = e.target.value;
    },
    deleteValue() {
      this.product.runs_range.pop();
      if (this.product.runs_range < 1) {
        this.hideDeleteValue = true;
      }
    },
    addNote() {
      this.product.additional_checkboxes.push("");
      this.hideDeleteNote = true;
    },
    changeNote(e) {
      this.product.additional_checkboxes[e.target.dataset.index] =
        e.target.value;
    },
    deleteNote() {
      this.product.additional_checkboxes.pop();
      if (this.product.additional_checkboxes.length < 1) {
        this.hideDeleteNote = false;
      }
    },
    displayModal() {
      this.showModal = true;
    },
    hideModal() {
      this.showModal = false;
    },
    addTiming() {
      this.timings.push({
        name: "",
        discount: 0,
        days: 0,
        product_id: this.$route.params.id,
        manufacture: {
          name: "Производство OQ",
          id: 1,
        },
        manufacture_id: 1,
      })

      axios.post(`api/auth/timings`, {
        timing: this.timings[this.timings.length - 1]
      }).then((res) => {
        console.log(res)
        this.timings[this.timings.length - 1].id = res.data.id;
      })
    },
    deleteTiming(key, id) {
      axios.delete(`api/auth/timings/${id}`).then(() => {
        this.timings.splice(key, 1);
      })
    },
  },
  created() {
    if (this.$route.params.id) {
      this.reciveProduct();
    }
    axios.get("api/auth/category/list").then((res) => {
      this.allCategories = res.data;
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 14px;
}
</style>
