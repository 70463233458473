<template>
  <div>
    <h1 class="display-4 mb-3">Список подразделений</h1>
    <div class="panel-content">
      <div class="frame-wrap p-3">
        <table class="table table-striped m-0">
          <thead class="bg-primary-600">
          <tr role="row">
            <th class="sorting_asc">ID #</th>
            <th class="sorting_asc">Наименование</th>
            <th class="sorting_asc">Управление</th>
          </tr>
          </thead>
          <tbody>
          <tr class="odd" v-for="division in divisions" :key="division.id">
            <td>{{ division.id }}</td>
            <td>{{ division.name }}</td>
            <td>
              <button title="Просмотр подразделения" type="button"
                      @click.prevent="showDivision(division.id)"
                      class="btn  btn-md btn-icon waves-effect waves-themed mr-3">
                <span aria-hidden="true"><i class="fal fa-search"></i></span>
              </button>
              <button title="Редактировать подразделение" type="button"
                      @click.prevent="editDivision(division.id)"
                      class="btn  btn-md btn-icon waves-effect waves-themed mr-3">
                                    <span aria-hidden="true"><i
                                        class="fal fa-edit"></i></span>
              </button>
              <button title="Удалить подразделение" type="button" @click.prevent="deleteDivision(division.id)"
                      class="btn  btn-md btn-icon waves-effect waves-themed">
                <span aria-hidden="true"><i class="fal fa-trash-alt"></i></span>
              </button>

            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import router from "@/router";

export default {
  name: "DivisionsList",
  data() {
    return {
      divisions: []
    }
  },
  methods: {
    showDivision(id) {
      router.push({name: 'manageUsers', params: {id: id}})
    },
    editDivision(id) {
      router.push({name: 'editDivision', params: {id: id}})
    },
    deleteDivision(id) {
      // console.log(id)
      if (confirm("Удалить пользователя?")) {
        axios.delete(`api/auth/divisions/delete/${id}`)
            .then(res => {
              this.divisions = res.data
            })

      } else {
        return false
      }

    }
  },
  mounted() {
    axios.get('api/auth/divisions')
        .then(res => {
          console.log(res.data)
          this.divisions = res.data
        })
  }
}
</script>

<style scoped lang="scss">
.dlt {
  color: #2198F3;
  cursor: pointer;
}
</style>