<template>
  <div class="wrapper wrapper-content animated fadeInRight article">
    <div class="subheader">
      <h1 class="display-4 mb-0">Конструктор штендера</h1>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-lg-10">
        <div class="ibox">
          <div class="ibox-content">
            <iframe
              id="editorFrame"
              class="border-0"
              width="100%"
              height="1000px"
            ></iframe>
          </div>
          <div class="ibox-content text-right">
            <button id="finish" @click="finishDesign" class="btn btn-primary">
              Сохранить
            </button>
          </div>
          <div class="ibox-content text-center">
            <h3>ID макета</h3>
            <div id="number" @click="copyToClipboard(resultText)" class="alert alert-info">{{ resultText }}</div>
          </div>
          <div class="ibox-content alert alert-primary">
            <h3>Воинские звания</h3>
            <div class="row p-3">
                <ul class="col-4">
                  <li>Адмирал</li>
                  <li>Адмирал флота</li>
                  <li>Вице-адмирал</li>
                  <li>Генерал армии</li>
                  <li>Генерал-лейтенант</li>
                  <li>Генерал-майор</li>
                  <li>Генерал-полковник</li>
                  <li>Главный корабельный старшина</li>
                  <li>Главный старшина</li>
                  <li>Ефрейтор</li>
                  <li>Капитан</li>
                  <li>Капитан 1 ранга</li>
                  <li>Капитан 2 ранга</li>
                </ul>
                <ul class="col-4">
                  <li>Капитан 3 ранга</li>
                  <li>Капитан-лейтенант</li>
                  <li>Контр-адмирал</li>
                  <li>Лейтенант</li>
                  <li>Лейтенант</li>
                  <li>Майор</li>
                  <li>Маршал Российской Федерации</li>
                  <li>Матрос</li>
                  <li>Мичман</li>
                  <li>Младший лейтенант</li>
                  <li>Младший лейтенант</li>
                  <li>Младший сержант</li>
                  <li>Подполковник</li>
                </ul>
                <ul class="col-4">
                  <li>Полковник</li>
                  <li>Прапорщик</li>
                  <li>Рядовой</li>
                  <li>Сержант</li>
                  <li>Старший лейтенант</li>
                  <li>Старший лейтенант</li>
                  <li>Старший матрос</li>
                  <li>Старший мичман</li>
                  <li>Старший прапорщик</li>
                  <li>Старший сержант</li>
                  <li>Старшина</li>
                  <li>Старшина 1 статьи</li>
                  <li>Старшина 2 статьи</li>
                </ul>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Constructor",
  components: {},
  data() {
    return {
      editor: null,
      userId: this.generateUUID(),
      isVertical: 1,
      resultText: "",
      id: this.getUrlVars()["surface"],
      quatro: null,
      CustomersCanvas: window.CustomersCanvas,
      showFinishButton: false
    };
  },
  mounted() {
    let ccScript = document.createElement("script");
    ccScript.setAttribute(
      "src",
      "https://gen.copy.spb.ru/prod/DE/SimplePolygraphy/resources/generated/iframeapi.js"
    );
    ccScript.setAttribute("id", "CcIframeApiScript");
    document.head.appendChild(ccScript);
    let vm = this;
    ccScript.onload = () => {
      axios.get(`api/auth/polk/get/template/${this.id}`).then((res) => {
        if(res.data.file.indexOf('ec') >=0 ){
          vm.showFinishButton = true
        }
        if (res.data.orientation == "h") {
          this.isVertical = 0;
        }
        if (res.data.photo == 4) {
          this.quatro = 1;
        }
        this.loadEditor(res.data.file);
      });
    };
  },
  methods: {
    generateUUID() {
      var d = new Date().getTime();
      if (window.performance && typeof window.performance.now === "function") {
        d += performance.now(); //use high-precision timer if available
      }
      var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
      return uuid;
    },
    getUrlVars() {
      var vars = [],
        hash;

      var hashes = window.location.href
        .slice(window.location.href.indexOf("?") + 1)
        .split("&");

      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split("=");

        vars.push(hash[0]);

        vars[hash[0]] = hash[1];
      }

      return vars;
    },
    loadEditor(template) {
      template = decodeURIComponent(template);
      let productDefinition;
      var shpigelMockup = "";

      if (this.isVertical) {
        shpigelMockup = "mockup_shtender-vertical";
      } else {
        shpigelMockup = "mockup_shtender-horizont";
      }

      if (!this.quatro) {
        productDefinition = {
          surfaces: [
            {
              name: "Штендер",
              printAreas: [
                {
                  designFile: "shtender\\" + template,
                },
              ],
              mockup: {
                up: "polk\\" + shpigelMockup,
              },
            },
          ],
        };
      } else {
        productDefinition = {
          surfaces: [
            {
              name: "Штендер 1",
              printAreas: [
                {
                  designFile: "shtender\\" + template,
                },
              ],
              mockup: {
                up: "polk\\" + shpigelMockup,
              },
            },
            {
              name: "Штендер 2",
              printAreas: [
                {
                  designFile: "shtender\\" + template,
                },
              ],
              mockup: {
                up: "polk\\" + shpigelMockup,
              },
            },
          ],
        };
      }

      var iframe = document.getElementById("editorFrame");

      //var frameParent = iframe.parent();
      //Loading the product into the editor.
      //var editor = null;
      //frameParent.mask("Загрузка...");
      //Loading the editor.
      let vm = this;
      window.CustomersCanvas.IframeApi.loadEditor(iframe, productDefinition, {
        //autoLoadUserInfo: true,
        //userInfo: {"txt_brand": "OQ print"},
        defaultLanguage: "ru",
        rendering: {
          proofImageMockupEnabled: false,
          hiResOutputDpi: 200,
        },
        userId: this.userId,
        initialMode: "Advanced",
        preloader: {
          firstTimeMessage: "Подготовка макета. Пожалуйста, подождите...",
        },
        widgets: {
          FinishButton: {
            mode: vm.showFinishButton ? "Enabled" : "Disabled",
          },
          LeftToolbar: {
            qrCodeButtonEnabled: false,
            linearBarcodeButtonEnabled: false,
          },
          TopToolbar: {
            opacitySliderEnabled: false,
            borderColorButtonEnabled: false,
            fillColorButtonEnabled: false,
            fontSize: {
              min: 1.5,
            },
          },
          BottomToolbar: {
            surfaceSwitch: {
              showThumbnails: false,
            },
          },
        },
      })
        //If the editor has been successfully loaded.
        .then(function (e) {
          vm.editor = e;
          /* if (edit != 1) {
                        editor.loadUserInfo(userInfo);
                    }*/
        })
        //If there was an error thrown when loading the editor.
        .catch(function (error) {
          console.error("The editor failed to load with an exception: ", error);
        });

      /*document.getElementById('clear').addEventListener('click', function(e) {
                localStorage.removeItem("globalCart");
            })*/
    },
    finishDesign() {
    let vm = this;
      this.editor
        .finishProductDesign({
          proofMaxHeight: 750,
          proofMaxWidth: 750,
        })
        .then(function (result) {
          vm.resultText = result.stateId + ":" + result.userId;
        });
    },
    copyToClipboard(text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        alert('ID макета скопирован в буфер обмена.')
        return result;
    }
  },
};
</script>

