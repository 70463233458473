<template>
  <div>
    <h1 class="display-4 mb-3" v-if="$route.params.id != undefined">
      Редактирование подразделения
    </h1>
    <h1 class="display-4 mb-3" v-else>Создание подразделения</h1>
    <div
      class="alert alert-dismissible fade show"
      v-bind:class="{
        'd-none': alertTextHide,
        'alert-danger': alertDanger,
        'alert-success': alertSuccess,
      }"
      role="alert"
    >
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        @click="hideAlert"
        aria-label="Close"
      >
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
      </button>
      {{ alertText }}
    </div>
    <div v-if="!hideErrors" class="alert alert-danger" role="alert">
      <h1><strong>Пожалуйста, исправьте ошибки:</strong></h1>
      <ul>
        <li v-for="(error, index) in this.errors" :key="index">
          {{ error[0] }}
        </li>
      </ul>
    </div>
    <form
      action=""
      enctype="multipart/form-data"
      method="post"
      @submit.prevent="store"
    >
      <div class="form-group col-4 pl-0 mt-5">
        <h4 class="mt-4 mb-3">Наименование подразделения</h4>
        <input type="text" class="form-control" v-model="name" />
      </div>
      <div class="d-flex">
        <div class="custom-control custom-checkbox col-2">
          <input
            type="checkbox"
            class="custom-control-input"
            v-model="type"
            id="type"
            checked=""
            value="0"
          />
          <label class="custom-control-label" for="type"
            >Является копицентром</label
          >
        </div>
      </div>

      <div class="d-flex flex-wrap">
        <div class="form-group mt-5 col-2 pl-0">
          <h4 class="mt-4 mb-3">Код логистики</h4>
          <input type="text" class="form-control" v-model="codeLog" />
        </div>

        <div class="form-group mt-5 col-2 pl-0">
          <h4 class="mt-4 mb-3">Код на сайте</h4>
          <input type="text" class="form-control" v-model="codeSite" />
        </div>
      </div>

      <div class="form-group col-4 pl-0 mt-5">
        <h4 class="mt-4 mb-3">Координаты</h4>
        <input
          type="text"
          class="form-control"
          v-model="coords"
          readonly
        />
        <h4 class="mt-4 mb-3">Адрес</h4>
        <input type="text" class="form-control" v-model="mapAdress" />
      </div>
      <yandex-map
        :settings="settings"
        :coords="coords"
        zoom="13"
        @click="mapClick"
        class="mt-4"
        style="width: 60%; height: 400px"
      >
        <ymap-marker
          marker-id="123"
          :coords="coords"
          :balloon-template="balloonTemplate"
        />
      </yandex-map>

      <div class="form-group col-3 pl-0 mt-5">
        <h4 class="mt-4 mb-3">Тип подразделения</h4>
        <multiselect
          v-model="typeDiv"
          :options="options"
          :searchable="true"
          :show-labels="false"
          placeholder=""
        >
        </multiselect>
      </div>

      <div class="form-group col-3 pl-0 mt-5">
        <h4 class="mt-4 mb-3">Метро</h4>
        <multiselect
          v-model="tubeSelected"
          :options="tubes"
          :searchable="true"
          :show-labels="false"
          track-by="name"
          label="name"
          placeholder=""
        >
          <template slot="option" slot-scope="{ option }">
            <div class="d-flex align-items-center">
              <div :style="'border-radius: 50%; width:7px; height:7px;margin-right: 10px;background-color:' + option.line_type"></div>
              <div>{{ option.name }}</div>
            </div>
          </template>
          <template slot="singleLabel" slot-scope="{ option }">
            <div class="d-flex align-items-center">
              <div :style="'border-radius: 50%; width:7px; height:7px;margin-right: 10px;background-color:' + option.line_type"></div>
              <div>{{ option.name }}</div>
            </div>
          </template>
        </multiselect>
      </div>

      <h4 class="mt-4 mb-3">Режим работы</h4>
      <div class="d-flex flex-wrap align-items-center mt-5">
        <div class="mr-4 mb-4 col-2 pl-0">
          <label class="">Понедельник</label>
          <date-picker
            v-model="week.mon.time"
            format="HH:mm"
            type="time"
            :disabled-date="disabledBeforeToday"
            :lang="lang"
            valueType="HH:mm"
            :range="true"
          ></date-picker>

          <div class="mt-2 d-flex">
            <div class="custom-control custom-checkbox col-2">
              <input
                type="checkbox"
                class="custom-control-input"
                v-model="week.mon.isWork"
                id="week.mon.isWork"
                checked="true"
                value="0"
              />
              <label class="custom-control-label" for="week.mon.isWork"
                >Работает</label
              >
            </div>
          </div>
        </div>
        <div class="mr-4 mb-4 col-2 pl-0">
          <label>Вторник</label>
          <date-picker
            v-model="week.tue.time"
            format="HH:mm"
            type="time"
            :disabled-date="disabledBeforeToday"
            :lang="lang"
            valueType="HH:mm"
            :range="true"
          ></date-picker>

          <div class="mt-2 d-flex">
            <div class="custom-control custom-checkbox col-2">
              <input
                type="checkbox"
                class="custom-control-input"
                v-model="week.tue.isWork"
                id="week.tue.isWork"
                checked="true"
                value="0"
              />
              <label class="custom-control-label" for="week.tue.isWork"
                >Работает</label
              >
            </div>
          </div>
        </div>
        <div class="mr-4 mb-4 col-2 pl-0">
          <label>Среда</label>
          <date-picker
            v-model="week.wed.time"
            format="HH:mm"
            type="time"
            :disabled-date="disabledBeforeToday"
            :lang="lang"
            valueType="HH:mm"
            :range="true"
          ></date-picker>

          <div class="mt-2 d-flex">
            <div class="custom-control custom-checkbox col-2">
              <input
                type="checkbox"
                class="custom-control-input"
                v-model="week.wed.isWork"
                id="week.wed.isWork"
                checked="true"
                value="0"
              />
              <label class="custom-control-label" for="week.wed.isWork"
                >Работает</label
              >
            </div>
          </div>
        </div>
        <div class="mr-4 mb-4 col-2 pl-0">
          <label>Четверг</label>
          <date-picker
            v-model="week.thu.time"
            format="HH:mm"
            type="time"
            :disabled-date="disabledBeforeToday"
            :lang="lang"
            valueType="HH:mm"
            :range="true"
          ></date-picker>

          <div class="mt-2 d-flex">
            <div class="custom-control custom-checkbox col-2">
              <input
                type="checkbox"
                class="custom-control-input"
                v-model="week.thu.isWork"
                id="week.thu.isWork"
                checked="true"
                value="0"
              />
              <label class="custom-control-label" for="week.thu.isWork"
                >Работает</label
              >
            </div>
          </div>
        </div>
        <div class="mr-4 mb-4 col-2 pl-0">
          <label>Пятница</label>
          <date-picker
            v-model="week.fri.time"
            format="HH:mm"
            type="time"
            :disabled-date="disabledBeforeToday"
            :lang="lang"
            valueType="HH:mm"
            :range="true"
          ></date-picker>

          <div class="mt-2 d-flex">
            <div class="custom-control custom-checkbox col-2">
              <input
                type="checkbox"
                class="custom-control-input"
                v-model="week.fri.isWork"
                id="week.fri.isWork"
                checked="true"
                value="0"
              />
              <label class="custom-control-label" for="week.fri.isWork"
                >Работает</label
              >
            </div>
          </div>
        </div>
        <div class="mr-4 mb-4 col-2 pl-0">
          <label>Суббота</label>
          <date-picker
            v-model="week.sat.time"
            format="HH:mm"
            type="time"
            :disabled-date="disabledBeforeToday"
            :lang="lang"
            valueType="HH:mm"
            :range="true"
          ></date-picker>

          <div class="mt-2 d-flex">
            <div class="custom-control custom-checkbox col-2">
              <input
                type="checkbox"
                class="custom-control-input"
                v-model="week.sat.isWork"
                id="week.sat.isWork"
                checked="true"
                value="0"
              />
              <label class="custom-control-label" for="week.sat.isWork"
                >Работает</label
              >
            </div>
          </div>
        </div>
        <div class="mr-4 mb-4 col-2 pl-0">
          <label>Воскресенье</label>
          <date-picker
            v-model="week.sun.time"
            format="HH:mm"
            type="time"
            :disabled-date="disabledBeforeToday"
            :lang="lang"
            valueType="HH:mm"
            :range="true"
          ></date-picker>

          <div class="mt-2 d-flex">
            <div class="custom-control custom-checkbox col-2">
              <input
                type="checkbox"
                class="custom-control-input"
                v-model="week.sun.isWork"
                id="week.sun.isWork"
                checked="true"
                value="0"
              />
              <label class="custom-control-label" for="week.sun.isWork"
                >Работает</label
              >
            </div>
          </div>
        </div>
      </div>

      <h4 class="mt-4 mb-3">Нерабочие дни</h4>
      <div class="d-flex flex-wrap align-items-center mt-5">
        <div class="mr-4 mb-4 col-2 pl-0">
          <label class="">Введите дни</label>
          <date-picker
            v-model="holliday"
            format="YYYY-MM-DD HH:mm"
            type="datetime"
            :lang="lang"
            valueType="format"
            :range="true"
          ></date-picker>
        </div>
        <button class="btn btn-primary btn-block waves-effect waves-themed col-2" @click.prevent="addHolliday">Добавить день</button>
      </div>

      <div class="flex flex-wrap mt-2">
        <div class="d-inline panel mr-3 p-3" v-for="(holliday, index) in hollidays" :key="index">
          {{ holliday[0] === holliday[1] ? holliday[0] : holliday[0] + ' - ' + holliday[1] }}
        </div>
      </div>

      <div class="form-group col-4 pl-0 mt-5">
        <h4 class="mt-4 mb-3">Уведомление</h4>
        <input type="text" class="form-control" v-model="notification" />
      </div>

      <div class="d-flex flex-row-reverse" v-if="$route.params.id == undefined">
        <input
          type="submit"
          class="btn btn-primary btn-lg waves-effect waves-themed"
          value="Создать подразделение"
        />
      </div>
      <div class="d-flex flex-row-reverse" v-if="$route.params.id != undefined">
        <button
          @click.prevent="edit(id)"
          class="btn btn-primary btn-lg btn-block waves-effect waves-themed col-3"
        >
          Coхранить подразделение
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { yandexMap, ymapMarker } from "vue-yandex-maps";

export default {
  name: "CreateDivision",
  components: {
    Multiselect,
    DatePicker,
    yandexMap,
    ymapMarker,
  },
  data() {
    return {
      name: null,
      type: false,
      id: null,
      alertText: null,
      alertTextHide: true,
      alertDanger: false,
      alertSuccess: false,
      errors: [],
      hideErrors: true,
      holliday: '',
      hollidays: [],
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      week: {
        mon: { time: "", isWork: true },
        tue: { time: "", isWork: true },
        wed: { time: "", isWork: true },
        thu: { time: "", isWork: true },
        fri: { time: "", isWork: true },
        sat: { time: "", isWork: true },
        sun: { time: "", isWork: true },
      },
      tubes: [],
      tubeSelected: {},
      typeDiv: "",
      codeLog: "",
      codeSite: "",
      notification: "",
      value: "",
      options: [
        "Обычный",
        "Экспресс",
        "Служебный",
        "Онлайн",
        "Производство",
        "Логистика",
      ],
      settings: {
        apiKey: "3416a120-8c53-43db-b806-c2212c299771",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      coords: [59.931513, 30.360509],
      mapAdress: "",
    };
  },
  methods: {
    store() {
      axios
        .post("api/auth/divisions/store", {
          name: this.name,
          type: this.type,
          site_code: this.codeSite,
          map_address: this.mapAdress,
          logistic_code: this.codeLog,
          division_type: this.typeDiv,
          tube_station: this.tubeSelected.name,
          geolocation: JSON.stringify(this.coords),
          notification: this.notification,
          work_time: JSON.stringify(this.week),
        })
        .then((res) => {
          if (res.status == 201) {
            this.alertText = "Подразделение успешно сохранено";
            this.alertTextHide = false;
            setTimeout(this.hideAlert, 5000);
            this.alertDanger = false;
            this.alertSuccess = true;
            this.errors = [];
            this.hideErrors = true;
          } else {
            this.alertText = "Ошибка сохранения подразделения";
            this.alertTextHide = false;
            this.alertDanger = true;
            this.alertSuccess = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.hideErrors = false;
            this.errors = error.response.data.errors;
          }
        });
    },
    edit(id) {
      axios
        .put(`api/auth/divisions/edit/${id}`, {
          name: this.name,
          type: this.type,
          site_code: this.codeSite,
          logistic_code: this.codeLog,
          division_type: this.typeDiv,
          geolocation: this.coords,
          work_time: JSON.stringify(this.week),
          notification: this.notification,
          map_address: this.mapAdress,
          tube_station: this.tubeSelected.name,
        })
        .then((res) => {
          if (res.status == 200) {
            this.alertText = "Подразделение успешно обновлено";
            this.alertTextHide = false;
            setTimeout(this.hideAlert, 5000);
            this.alertDanger = false;
            this.alertSuccess = true;
            this.errors = [];
            this.hideErrors = true;
          } else {
            this.alertText = "Ошибка обновления подразделения";
            this.alertTextHide = false;
            this.alertDanger = true;
            this.alertSuccess = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.hideErrors = false;
            this.errors = error.response.data.errors;
          }
        });
    },
    hideAlert() {
      this.alertTextHide = true;
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    mapClick(e) {
      let geocode = Object.assign({}, e.get("coords"));
      geocode = [geocode[0], geocode[1]];
      this.coords = e.get("coords");
      axios
        .get(
          `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=3416a120-8c53-43db-b806-c2212c299771&geocode=${geocode
            .reverse()
            .join(",")}`
        )
        .then((res) => {
          this.mapAdress =
            res.data.response.GeoObjectCollection.featureMember[0].GeoObject.name;
        });
    },
    getTube(name) {
      return this.tubes.find(tube => tube.name === name)
    },
    addHolliday() {
      this.hollidays.push(this.holliday)
      this.holliday = null
    }
  },
  mounted() {
    axios.get('api/site/divisions/tubes').then(res => {
      Object.keys(res.data).forEach(key => {
        this.tubes.push({
          name: key,
          line_type: res.data[key].line_type,
          line_number: res.data[key].line_number,
        })
      })
    })
    if (this.$route.params.id != undefined) {
      this.id = this.$route.params.id;
      axios
        .get(`api/auth/divisions/show/${this.$route.params.id}`)
        .then((res) => {
          this.name = res.data.name;
          this.type = res.data.type;
          this.id = res.data.id;

          this.week =
            res.data.work_time !== null
              ? JSON.parse(res.data.work_time)
              : this.week;

          this.codeLog = res.data.logistic_code;
          this.notification = res.data.notification;
          this.tubeSelected = this.getTube(res.data.tube_station) ?? {};
          this.codeSite = res.data.site_code;
          this.typeDiv = res.data.division_type;
          let coords =
            res.data.geolocation !== null
              ? JSON.parse(res.data.geolocation)
              : null;
          let address = res.data.address[0]
            ? res.data.address[0].address
            : null;
          this.mapAdress =
            res.data.map_address === null ? address : res.data.map_address;
          if (coords === null) {
            axios
              .get(
                `https://geocode-maps.yandex.ru/1.x/?format=json&apikey=3416a120-8c53-43db-b806-c2212c299771&geocode=${address}`
              )
              .then((res) => {
                this.coords = res.data.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos
                  .split(" ")
                  .reverse();
              });
          } else {
            this.coords = coords;
          }
        });
    }
  },
  computed: {
    balloonTemplate() {
      return `
        <h1 class="red">Копицентр находится здесь!</h1>
        <p>${this.coords}</p>
      `;
    },
  },
};
</script>
