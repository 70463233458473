<template>
  <div>
    <div class="subheader">
      <h1 class="display-4">Выбор продукта</h1>
    </div>
    <div class="form-group mt-5">
      <h3 class="mt-5">Быстрый поиск по каталогу продуктов</h3>
      <multiselect
        v-model="product"
        track-by="name"
        label="name"
        :options="products"
        :show-labels="false"
        :searchable="true"
        :allow-empty="true"
        :loading="isLoading"
        placeholder="Введите наименование продукта"
        @search-change="searchProduct"
        @select="selectProduct"
      >
        <template slot-scope="{ option }">{{ option }} </template>
        <span slot="noResult">По вашему запросу ничего не найдено.</span>
      </multiselect>
    </div>
    <h3 class="mt-5">Каталог продуктов</h3>
    <div class="d-flex">
      <div class="col-4">
        <div>
          <vue-tree-list
            @click="onClick"
            :model="data"
            v-bind:default-expanded="false"
          >
            <template v-slot:leafNameDisplay="slotProps">
              <span
                :class="
                  slotProps.model.id === activeCategory ? 'activeCategory' : ''
                "
              >
                {{ slotProps.model.name }}
              </span>
            </template>
          </vue-tree-list>
          <!--<button @click="getNewTree">Get new tree</button>-->
          <pre>
      <!--{{newTree}}-->
    </pre>
        </div>
      </div>
      <div class="col-8">
        <table
          v-if="showTable"
          id="dt-basic-example"
          class="table table-bordered table-hover table-striped w-100 dataTable dtr-inline"
          role="grid"
          aria-describedby="dt-basic-example_info"
        >
          <thead class="bg-primary-600">
            <tr role="row">
              <th class="sorting_asc">ID #</th>
              <th class="sorting_asc">Наименование</th>
              <th class="sorting_asc">Управление</th>
            </tr>
          </thead>
          <tbody>
            <tr class="odd" v-for="(item, index) in productsList" :key="index">
              <td class="align-middle">{{ item.id }}</td>
              <td class="align-middle">{{ item.name }}</td>
              <td class="point align-middle">
                <button
                  type="button"
                  @click="selectProduct(item)"
                  class="btn btn-sm btn-outline-success waves-effect waves-themed"
                >
                  <span class="fal fa-plus mr-1"></span>
                  Добавить продукт в заказ
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="showLoader" class="fixed d-flex justify-content-center">
          <button
            class="btn btn-info waves-effect waves-themed"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Загрузка...
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import { VueTreeList, Tree } from "vue-tree-list";
import router from "@/router";

export default {
  name: "ProductSearch",
  components: {
    Multiselect,
    VueTreeList,
  },
  data() {
    return {
      isLoading: false,
      showLoader: false,
      showTable: false,
      searchName: null,
      products: [],
      product: {},
      categoryList: {},
      productsList: {},
      /*newTree: {},*/
      data: new Tree([]),
      activeCategory: null,
      currentNodeParent: null,
      order: {
        id: null,
        product_id: null,
        variant: {},
        service: [],
        preview: null,
      },
    };
  },
  methods: {
    searchProduct(text) {
      this.isLoading = true;
      if (text.length > 3) {
        axios
            .post(`api/auth/orders/product/search/`, {
              text: text,
            })
            .then((res) => {
              this.isLoading = false;
              console.log(res.data);
              this.products = res.data;
            });
      }
    },
    selectProduct(product) {
      router.replace(`/order/create/product/${product.id}`);
      /* axios.get(`api/auth/orders/product/show/${id}`)
           .then(res => {
               console.log(res.data)
               this.product = res.data
               this.searchName = null
               this.products = []
           })*/
    },
    onClick(params) {
      this.getCategoryProducts(params.id);
      this.activeCategory = params.id;
    },
    getCategoryProducts(id) {
      this.showLoader = true;
      this.showTable = false;
      axios.get(`api/auth/product/published/${id}`).then((res) => {
        if (res.status == 200) {
          this.productsList = res.data;
        } else {
          console.log(res);
        }
        this.showLoader = false;
        this.showTable = true;
      });
    },
    getCategoriesList() {
      axios.get("api/auth/category").then((res) => {
        if (res.status == 200) {
          this.categoryList = res.data;
          this.recursionSort(res.data);
          this.data = new Tree(res.data);
        } else {
          console.log(res);
        }
      });
    },
    recursionSort(obj) {
      obj.sort(this.compareValues("name"));
      for (let key in obj) {
        obj[key].dragDisabled = true;
        obj[key].addTreeNodeDisabled = true;
        obj[key].addLeafNodeDisabled = true;
        obj[key].editNodeDisabled = true;
        obj[key].delNodeDisabled = true;
        if (obj[key].children.length) {
          this.recursionSort(obj[key].children);
        }
      }
    },
    compareValues(key, order = "asc") {
      return function innerSort(a, b) {
        // eslint-disable-next-line no-prototype-builtins
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0;
        }

        const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return order === "desc" ? comparison * -1 : comparison;
      };
    },
  },
  mounted() {
    this.getCategoriesList();
  },
};
</script>

<style lang="less" rel="stylesheet/less">
.vtl {
  font-size: 16px;

  .vtl-icon {
    margin-right: 8px;
    cursor: pointer;
  }

  .vtl-icon-plus {
    display: none;
  }
  .vtl-node-content {
    cursor: pointer;
  }
}

.activeCategory {
  color: #2198f3;
}
</style>
