<template>
    <div>
        <form
            @submit.prevent="startSearch"
            class="input-group input-group-lg mb-5 shadow-1 rounded"
        >
            <input
                @change="startSearch"
                v-model="search"
                type="text"
                class="form-control shadow-inset-2"
                id="filter-icon"
                aria-label="type 2 or more letters"
                placeholder="Поиск задания на изготовление по номеру"
                required
                minlength="3"
                value
            />
            <div class="input-group-append">
                <button
                @click="startSearch"
                class="btn btn-primary hidden-sm-down waves-effect waves-themed"
                type="submit"
                >
                    <i class="fal fa-search mr-lg-2"></i>
                    <span class="hidden-md-down">Найти</span>
                </button>
            </div>
        </form>
        <div
        class="d-flex flex-wrap align-items-center pl-3 pr-1 px-sm-4 px-lg-5 border-faded border-top-0 border-left-0 border-right-0 mb-5"
        >
            <div class="flex-1 d-flex align-items-center">
                <ul
                id="demo_panel-tabs"
                class="nav nav-tabs border-bottom-0 nav-tabs-clean"
                role="tablist"
                >
                    <li class="nav-item">
                        <span
                        @click="setActiveTab('approved')"
                        class="nav-link cursor-pointer"
                        :class="activeTab == 'approved' ? 'active' : ''"
                        >Новые</span
                        >
                    </li>
                    <li class="nav-item">
                        <span
                        @click="setActiveTab('ready_to_handle')"
                        class="nav-link cursor-pointer"
                        :class="activeTab == 'ready_to_handle' ? 'active' : ''"
                        >В обработке</span
                        >
                    </li>
                    <li class="nav-item">
                        <span
                        @click="setActiveTab('pass_to_producing')"
                        class="nav-link cursor-pointer position-relative"
                        :class="activeTab == 'pass_to_producing' ? 'active' : ''"
                        >
                          В производстве
                        </span>
                    </li>
                    <li class="nav-item">
                        <span
                        @click="setActiveTab('burning')"
                        class="nav-link cursor-pointer position-relative"
                        :class="activeTab == 'burning' ? 'active' : ''"
                        >
                        Горящие задания
                        <span
                            v-if="statuses.fast > 0"
                            class="badge border border-light rounded-pill bg-danger-500 position-absolute pos-top pos-right"
                            >{{ statuses.fast }}</span
                        >
                        </span>
                    </li>
                </ul>
            </div>
            <div class="text-muted mr-1 mr-lg-3 ml-auto">
                {{ pageStart }} - {{ pageEnd }}
                <span class="hidden-lg-down">из {{ pagination.meta.total }}</span>
            </div>
            <div class="d-flex flex-wrap">
                <button
                v-if="pagination.links.prev"
                @click.prevent="getOrdersList(pagination.currentPage - 1)"
                class="btn btn-icon rounded-circle waves-effect waves-themed"
                >
                <i class="fal fa-chevron-left fs-md"></i>
                </button>
                <button
                v-if="pagination.links.next"
                @click.prevent="getOrdersList(pagination.currentPage + 1)"
                class="btn btn-icon rounded-circle waves-effect waves-themed"
                >
                <i class="fal fa-chevron-right fs-md"></i>
                </button>
            </div>
        </div>

        <table
            class="table table-bordered table-hover table-striped w-100 dataTable dtr-inline"
        >
            <thead class="bg-primary-600">
                <tr role="row">
                    <th class="sorting_asc">№ задания</th>
                    <th class="sorting_asc">Название продукта</th>
                    <th class="sorting_asc">Тираж</th>
                    <th class="sorting_asc">Дата выдачи</th>
                </tr>
            </thead>
            <tbody>
                <tr class="odd" v-for="(item, index) in ordersList" :key="index" @click.ctrl="showProductOrderNewWindow(item)">
                    <td class="align-middle">
                        <u @click="showProductOrder(item)">{{ item.custom_number || item.order_number }} ({{ item.position_number }} из {{ item.count_products }})</u>
                    </td>
                    <td class="align-middle">
                        {{ item.name }}
                    </td>
                    <td class="align-middle">
                        <span v-if="item.current_product.runs !== 3">{{ item.current_product.variant.price.quantity}}</span>
                        <span v-if="item.current_product.runs === 3">{{ item.current_product.variant.price.price[0].quantity}}</span>
                    </td>
                    <td class="align-middle">
                        {{ item.shipment_date}} 
                    </td>

                </tr>
            </tbody>
        </table>

        <div class="modal-backdrop fade show" v-if="showLoader"></div>
        <div class="modal fade show d-block" v-if="showLoader">
        <div class="fixed modal-dialog modal-dialog-centered justify-content-center">
            <button
            class="btn btn-info waves-effect waves-themed"
            type="button"
            disabled=""
            >
            <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
            ></span>
            Загрузка...
            </button>
        </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "ManufactureFind",
    data() {
        return {
            search: '',
            showSearchResults: true,
            searchResults: [],
            ordersList: [],
            showLoader: false,
            statuses: {},
            pagination: {
                links: {},
                meta: {},
                currentPage: 1,
            },
            pageStart: 1,
            pageEnd: 20,
            activeTab: "approved",
            division: {},
        }
    },
    components: {

    },
    methods: {
        setActiveTab(activeTab) {
            this.activeTab = activeTab;
            this.getOrdersList(1);
            if (activeTab === 'burning') {
                this.showName = true;
            }
        },
        startSearch() {
            let value = this.search
            if(value.length >= 3) {
                axios.post('api/auth/polk/manufacture/search', {status: this.activeTab, order_number: value}).then(res => {
                    this.ordersList = res.data
                })
            }
        },
        getOrdersList(page) {
            let currentPage = page ? page : 1;
            this.pagination.currentPage = currentPage;
            this.showLoader = true
            axios
                .get(
                `api/auth/polk/manufacture/find?page=${currentPage}&status=${this.activeTab}&division_id=${this.division.id}`
                )
                .then((res) => {
                if (res.status == 200) {

                    this.ordersList = '';
                    if(res.data) {
                        this.ordersList = res.data.data;
                        this.pagination.links = res.data.links;
                        this.pagination.meta = res.data.meta;
                        this.pageStart =
                        (res.data.meta.current_page - 1) * res.data.meta.per_page + 1;
                        this.pageEnd =
                        res.data.meta.current_page * res.data.meta.per_page <=
                        res.data.meta.total
                            ? res.data.meta.current_page * res.data.meta.per_page
                            : res.data.meta.total;
                    }
                } else {
                    console.log(res);
                }
                this.showLoader = false
            });
        },

        getFastOrdersCount() {
          axios.get('api/auth/polk/manufacture/fast/count').then((res) => {
            console.log(res)
            this.statuses.fast = res.data;
          })
        },

        toHandle(id) {
          axios.get(`api/auth/manufacture/change-status/ready-to-handle?id=${id}`)
          .then(res => {
            console.log(res.data);
          })
        },
        showProductOrder(item){
            
            if (item.status !== 'approved'){
                this.$router.push(`/manufacture/product/${item.id}`)

            }else{
                this.toHandle(item.id)
                this.$router.push(`/manufacture/product/${item.id}`)
                
            }
        },
        showProductOrderNewWindow(item){
            if (item.status !== 'approved'){
                window.open(`/manufacture/product/${item.id}`, '_blank');
            }else{
                this.toHandle(item.id)
                window.open(`/manufacture/product/${item.id}`, '_blank');
            }
        }
    },
    mounted() {
        this.division = JSON.parse(localStorage.getItem("user_division"));
        this.setActiveTab(this.activeTab);
        this.getFastOrdersCount();
    },
}
</script>

<style lang="scss" scoped>
    .odd {
      cursor: pointer;
    }
</style>
