<template>
    <div id="app">
        <div class="page-wrapper">
            <div class="page-inner">

                <Sidebar/>
                <div class="page-content-wrapper">
                <Header />

                    <main  id="main" class="page-content">
                        <breadcrumbs />
                        <div>
                            <NotificationCreate />
                        </div>

                    </main>
                </div>
            </div>
        </div>
        <Modals />
    </div>
</template>

<script>
    import Sidebar from '../components/Common/Sidebar'
    import Header from '../components/Common/Header'
    import Modals from '../components/Common/Modals'
    import NotificationCreate from '../components/Notifications/NotificationCreate.vue'

    export default {
        name: "Notifications",
        components: {
            Sidebar,
            Header,
            Modals,
            NotificationCreate,
        },
        data() {
            return {
                //
            }
        },
        mounted() {



        },
        methods: {

        }
    }
</script>

