<template>
  <div class="pb-100">
    <div class="col-12">
      <!-- <button
            @click="backToOrder"
            name="reset"
            class="btn btn-default btn-md btn-block waves-effect waves-themed col-2 mt-0 mr-3"
          >
            <i class="fal fa-arrow-alt-left mr-1"></i> К списку заказов
          </button> -->
    </div>
    <div class="col-12 mt-5">
      <button
          v-if="order.published"
          @click="getTZ(current_product.id, current_product)"
          type="button"
          class="btn btn-sm btn-default waves-effect waves-themed mr-3 mb-3"
        >
          <span class="fal fa-download mr-1"></span> Скачать ТЗ
        </button>
    </div>
     <div v-if="showOrderLink" class="col-12 mt-5">
       <a :href="'/order/edit/'+order.id">Перейти к заказу</a>
      </div>

          <div class="d-flex mt-5">
            <div class="col-12">
              <h6 class="mb-1 fw-500">
                            Статус задания
                          </h6>
              <p class="mb-2" :class="statusTranslate(current_product.status)[1]">{{statusTranslate(current_product.status)[0]}}</p>
            </div>

          </div>
     <div class="d-flex mt-3">

       <div class="col-3">
        <multiselect
            v-model="productOrderCurrentStatus"
            track-by="name"
            label="transcription"
            :options="productOrderStatuses"
            :show-labels="false"
            :searchable="false"
            :allow-empty="false"
            placeholder="Выберите новый статус"
        >
          <template slot-scope="{ option }">{{ option }} </template>
        </multiselect>
       </div>
       <div class="col-4" v-if="[3, 6, 7].includes(user.role)">
          <button
              @click="changeStatus()"
              type="button"
              class="btn btn-primary waves-effect waves-themed"
          >
            Изменить статус
          </button>
       </div>
       <div class="col-2"></div>
       </div>
    <div class="d-flex mt-5">
      <div class="col-4">
        <div class="form-group">
           <h6 class="mb-1 fw-500">
              Номер задания
            </h6>
            <p class="mb-2">{{ order.custom_number ? order.custom_number : order.order_number }} <sup>({{position_number}} из {{orderProductsCount}})</sup></p>

            <h6 class="mb-1 fw-500">
              ID задания
            </h6>
            <p class="mb-2">{{ this.$router.currentRoute.params.id }}</p>


          <h6 class="mb-1 fw-500">
              Наименование продукта
            </h6>
            <p class="mb-2">{{ product.name }}</p>
            <h6 class="mb-1 fw-500">
              Количество
            </h6>
            <p class="mb-2" v-if="product.runs !== 3">{{current_product.variant.price.quantity}}</p>
            <p class="mb-2" v-if="product.runs === 3">{{current_product.variant.price.price[0].quantity}}</p>
          <div v-if="showParents">
            <h6 class="mb-1 fw-500">
              {{
                product.parent_title
                  ? product.parent_title
                  : "Родительский вариант продукта"
              }}
            </h6>
            <p class="mb-2">{{current_product.parent.name}}</p>
          </div>
          <div v-if="showVariants">
            <h6 class="mb-1 fw-500">
              {{
                product.variant_title
                  ? product.variant_title
                  : "Вариант продукта"
              }}
            </h6>
            <p class="mb-2">{{current_product.variant.name}}</p>
          </div>
          <div v-if="current_product.width">
            <h6 class="mb-1 fw-500">Ширина (мм)</h6>
            <p>{{current_product.width}}</p>
          </div>
          <div v-if="current_product.height">
            <h6 class="mb-1 fw-500">Высота (мм)</h6>
            <p>{{current_product.height}}</p>
          </div>
          <div class="mb-2" v-for="service in current_product.services" v-bind:key="service.id">
            <h6 class="mb-1 fw-500">{{service.name}}</h6>
            <p v-if="service.current_variant">{{service.current_variant}}</p>
            <p v-if="service.comment">{{service.comment}}</p>
            <p v-if="service.custom_quantity">Количество: {{service.custom_quantity}}</p>
          </div>
        </div>
      </div>
      <div class="col-4">
            <h6 class="mb-1 fw-500">
              Макет
            </h6>
            <p class="mb-2">{{current_product.file_url}}</p>
            <h6 class="mb-1 fw-500">
              Дата выдачи
            </h6>
        <date-picker v-if="user.role===3 || user.role===7"
          v-model="current_product.changedDate"
          type="datetime"
          format="YYYY-MM-DD HH:mm"
          valueType="YYYY-MM-DD HH:mm"
          :placeholder="current_product.shipment_date.toString()"

        >
        </date-picker>
                    <p class="mb-2" v-else>{{current_product.shipment_date}}</p>
        <button v-if="user.role===3"
            @click="alertData"
                class="btn btn-primary waves-effect waves-themed"
        >
          Изменить
        </button>

            <h6 class="mb-1 fw-500">
              Место выдачи
            </h6>
            <p class="mb-2">{{order.delivery_type.id == 0 ? order.shipment_address.name : order.delivery_type.name + ': ' + order.shipment_address.address[0].address}}</p>
            <h6 class="mb-1 fw-500">
              Общая стоимость
            </h6>
            <p class="mb-2">{{current_product.cost}} руб.</p>
            <h6 class="mb-1 fw-500">
              Способ оплаты
            </h6>
            <p class="mb-2">{{order.payment_type.name}}</p>
            <h6 class="mb-1 fw-500">
              Комментарий
            </h6>
            <p class="mb-2">{{current_product.comment}}</p>
      </div>
      <div class="col-4">
          <h6 class="mb-1 fw-500">
              Имя
            </h6>
            <p class="mb-2">{{order.customer.name}}</p>
            <h6 class="mb-1 fw-500">
              Телефон
            </h6>
            <p class="mb-2">{{order.customer.phone}}</p>
            <h6 class="mb-1 fw-500">
              Email
            </h6>
            <p class="mb-2">{{order.customer.email}}</p>
      </div>
    </div>

        <hr class="mt-4 mb-g" />
        <h5 class="mb-2">Превью</h5>
          <div v-if="current_product.preview.length" class="row m-0">
            <div
              v-for="(image, i) in current_product.preview"
              v-bind:key="i"
              class="col-4 position-relative m-2 p-0"
            >
              <img :src="image.url" class="w-100" alt="" />
            </div>
          </div>
<hr class="mt-4 mb-g" />
          <div class="row mt-5">
          <div class="col-12">
            <div class="panel-content">
              <div class="accordion accordion-hover" id="js_demo_accordion-5">
                <div class="card">
                  <div class="card-header">
                    <p
                      class="card-title"
                      data-toggle="collapse"
                      data-target="#js_demo_accordion-5a"
                      :aria-expanded="isShowLogs"
                      :class="{ collapsed: !isShowLogs }"
                      @click="switchLogs"
                    >
                      История задания на изготовление
                      <span class="ml-auto">
                        <span class="collapsed-reveal" v-if="isShowLogs">
                          <i class="fal fa-chevron-up fs-xl"></i>
                        </span>
                        <span class="collapsed-hidden" v-else>
                          <i class="fal fa-chevron-down fs-xl"></i>
                        </span>
                      </span>
                    </p>
                  </div>
                  <div
                    id="js_demo_accordion-5a"
                    class="collapse"
                    data-parent="#js_demo_accordion-5"
                    style=""
                    :class="{ show: isShowLogs }"
                  >
                    <div class="card-body p-0">
                      <table v-if="this.logs.length !== 0" class="table m-0">
                        <thead>
                          <tr role="row">
                            <th class="sorting_asc">Дата</th>
                            <th class="sorting_asc">Пользователь</th>
                            <th width="60%" class="sorting_asc">Действие</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="odd"
                            v-for="(log, index) in this.logs"
                            :key="index"
                          >
                            <td class="align-middle">
                              <nobr>{{ log.created_at }}</nobr>
                            </td>
                            <td class="align-middle">
                              {{ log.user.name }}
                              <!-- ({{ log.user.email }}) -->
                            </td>
                            <td class="align-middle">
                              <span v-if="log.status_old === log.status_new"
                                >обновил заказ
                              </span>
                              <span v-else
                                >изменил статус задания с
                                <nobr class="font-weight-bold"
                                  >"{{
                                     statusTranslate(log.status_old)[0]
                                  }}"</nobr
                                >
                                на
                                <nobr class="font-weight-bold"
                                  >"{{
                                    statusTranslate(log.status_new)[0]
                                  }}"</nobr
                                ></span
                              >
                              <div v-if="log.comment">
                                <p class="font-weight-bold mb-1 mt-3">
                                  Комментарий:
                                </p>
                                <p>
                                  {{ log.comment }}
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-else>У данного заказа пустая история</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!--    Reject Order Form   -->
    <div
      class="modal fade show d-block"
      id="example-modal-alert"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      v-if="showBurningModal"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <i class="fal fa-bolt mr-3"></i> Укажите причину отклонения заказа
            </h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              @click="closeBurningModal"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <textarea cols="50" rows="10" v-model="log_comment">
            </textarea>
          </div>
          <div class="modal-footer mt-5">
            <button
              @click="changeStatus(true)"
              type="button"
              class="btn btn-primary waves-effect waves-themed"
            >
              Отклонить задание
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-backdrop fade show" v-if="showLoader"></div>
    <div class="modal fade show d-block" v-if="showLoader">
      <div
        class="fixed modal-dialog modal-dialog-centered justify-content-center"
      >
        <button
          class="btn btn-info waves-effect waves-themed"
          type="button"
          disabled=""
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Загрузка...
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
/*import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'*/
import fileDownload from "js-file-download";
import router from "@/router";
import store from "../../store";
import DatePicker from "vue2-datepicker";
import moment from "moment"


export default {
  name: "ManufactureProductView",
  components: {
   Multiselect,
    DatePicker
  },
  data() {
    return {
      showLoader: true,
      product: {
        parent_variants: [],
        variant: [],
        service: {},
        runs: 1,
        runs_range: { 0: 0 },
      },
      productOrderStatuses: [],
      productOrderCurrentStatus: '',
      current_product: {
        id: null,
        runs: 1,
        production: 1,
        preview: [],
        parent: {},
        variant: {
          price: {
            quantity: 0,
            price: [{}],
          },
        },
        countedPrice: {
          shipment_date: null,
          quantity: null,
          price: null,
        },
        totalPrice: {},
        services: [],
        file_url: null,
        design_price: null,
        comment: null,
        width: 0,
        height: 0,
        discount: 0,
        cost: 0,
        shipment_date: '',
        changedDate: '',
        status: 'new',
        current_product: ''
      },
      position_number: 0,
      calculatedPrice: [],
      variants: [],
      currentVariant: {},
      services: [],
      timings: {},
      showParents: false,
      showVariants: true,
      showServices: true,
      showSquare: false,
      servicesExceptions: [],
      selectedButton: null,
      countOfNotPresets: 0,
      manualValue: [],
      hidePanel: store.state.hidePanel,
      serviceComments: [],
      order:{
        product_orders: [],
        customer:{},
        delivery_type:{},
        shipment_address:{
          address:[{}]
        },
        payment_type:{}
      },
      orderProductsCount: 1,
      log_comment: '',
      showBurningModal: false,
      showBackdrop: false,
      isShowLogs: true,
      logs: [],
      showOrderLink: false,
      user: store.getters["auth/user"],
    };
  },
  methods: {
    alertData() {
      console.log(this.order.id)
      axios.post(`api/auth/manufacture/change-dates/`, {
        order_id: this.order.id,
        shipment_date: this.current_product.changedDate
      })
      .then(res => {
        console.log(res.data)
      })
    },
    getProductOrderStatuses() {
      axios.get(`api/auth/manufacture/change-status/get-product-order-statuses/${this.$router.currentRoute.params.id}`)
      .then(res => {
        console.log(res.data);
        this.productOrderStatuses = res.data;
      })
    },
    switchServices() {
      this.showServices = !this.showServices;
    },
    backToOrder() {
      router.push({
        name: "ManufactureFind",
      });
    },
    closeBurningModal() {
      this.showBackdrop = false;
      this.showBurningModal = false;
    },
    changeStatus(onlyChange = false) {
      // let f = {
      //   'id' : this.$router.currentRoute.params.id,
      //   'status' : this.productOrderCurrentStatus.name,
      // }
      //console.log(f)
      if(this.productOrderCurrentStatus.name == 'rejected' && !onlyChange){
        this.showBurningModal = true;
        this.showBackdrop = true;
      } else {
          axios.post('/api/auth/manufacture/change-status/change-product-order-status', {
            id : this.$router.currentRoute.params.id,
            status : this.productOrderCurrentStatus.name,
            comment: this.log_comment
          }).then(() => {
            if(this.productOrderCurrentStatus.name == 'order_edit'){
              alert('Заказ разблокирован для редактирования.');
              window.location.reload();
            }else{
              //alert('Статус заказа успешно изменен.');
              // router.push({
              //   name: "ManufactureFind",
              // });
              window.location.reload();
            }
         }).catch(err=>{
          console.log(err);
          alert('Произошла ошибка. Попробуте изменить статус ещё раз или обратитесь к разработчикам');
        })
      }

    },
    statusTranslate(status){
      const statusNames = {
        'new' : ['Подготавливается',  "color-primary-300"],
        'approved' : ['Отправлен в изготовление',  "color-primary-300"],
        'ready_to_handle': ['Принят в обработку', "color-info-300"],
        'pass_to_producing' : ['Производство', "color-info-300"],
        'ready' : ['Готов', "color-success-200"],
        'pass_to_delivery' : ['Передан в доставку', "color-info-300"],
        'accepted_by_delivery' : ['Принят в доставку', "color-info-300"],
        'delivered' : ['Ожидает выдачи', "color-info-300"],
        'denied': ['Отклонен клиентом', "color-info-300"],
        'await_for_customer' : ['Ожидает клиента', "color-info-300"],
        'pass_to_customer' : ['Выдан клиенту', "color-success-600"],
        'rejected': ['Отклонен производством', "color-danger-300"],
        'order_edit': ['Отклонен', "color-danger-300"],
      }
      return statusNames[status];
    },
    getTZ(product_id, product) {
      this.showLoader = true;
      let vm = this;
      axios
        .get(`api/auth/orders/create/techorder/${product_id}`, {
          responseType: "blob",
          headers: {
            Accept: "application/pdf",
          },
        })
        .then((res) => {
          this.showLoader = false;
          console.log(res.data);
          let fileName = vm.order.custom_number
            ? vm.order.custom_number
            : vm.order.order_number;
          let num = vm.order.product_orders.length;
          // let productIndex = this.products
          //   .map(function (item) {
          //     return item.id;
          //   })
          //   .indexOf(product_id);
          // productIndex++;

          fileDownload(
            res.data,
            fileName + "(" + product.position_number + " из " + num + ").pdf"
          );
        }).catch((err)=>{
          console.log(err);
        });
    },
    getVariants(parent) {
      this.variants = [];
      console.log(this.product.variant);
      console.log(parent);
      for (let k in this.product.variant) {
        if (this.product.variant[k].parent_variant_id === parent.id) {
          this.variants.push(this.product.variant[k]);
        }
      }
      let currentPrice = JSON.parse(
        JSON.stringify(this.current_product.variant.price)
      );
      if (currentPrice.price.length) {
        this.current_product.variant = JSON.parse(
          JSON.stringify(this.variants[0])
        );
        this.currentVariant = JSON.parse(JSON.stringify(this.variants[0]));
        this.current_product.variant.price = currentPrice;
        if (this.product.runs === 3) {
          for (let price in this.currentVariant.price) {
            if (
              currentPrice.price[0].quantity ===
              this.currentVariant.price[price].quantity
            ) {
              this.current_product.variant.price.price[0] = this.currentVariant.price[
                price
              ];
            }
          }
        } else {
          this.current_product.variant.price.price = this.currentVariant.price;
        }
      } else {
        this.current_product.variant = JSON.parse(
          JSON.stringify(this.variants[0])
        );
        this.currentVariant = JSON.parse(JSON.stringify(this.variants[0]));
        this.current_product.variant.price = {
          quantity: 0,
          price: [],
        };
        if (this.product.runs !== 3) {
          this.current_product.variant.price.quantity = Object.keys(
            this.product.runs_range
          )[0];
          this.current_product.variant.price.price = this.currentVariant.price;
        } else {
          this.current_product.variant.price.price[0] = this.currentVariant.price[0];
        }
      }
    },
    async getOrderProduct(loaded_product) {
      let productShow = await axios.get(`api/auth/product/show/${loaded_product}`);
      let current_product = productShow.data;
      let product_id = current_product.product_id;
      let res = await axios.get(`api/auth/orders/product/show/${product_id}`)
      let order = await axios.get(`api/auth/orders/${current_product.order_id}`);

      //let logs = await axios.get(`api/auth/order/log/show/${current_product.order_id}`);
      this.logs = current_product.log;
      console.log('logs: ', this.logs);
      console.log(order.data);
      this.order = order.data;
      this.order.shipment_address = JSON.parse(order.data.shipment_address);
      this.orderProductsCount = this.order.product_orders.length
      this.product = res.data;

      this.current_product.runs = this.product.runs;
      this.current_product.production = this.product.production;
      this.current_product.preview = current_product.preview;
      this.current_product.width = this.product.width;
      this.current_product.height = this.product.height;

      this.position_number = current_product.position_number

      if (this.product.parent_variants.length) {
        this.current_product.parent = this.product.parent_variants[0];
        this.getVariants(this.current_product.parent);
        this.showParents = true;
      } else {
        this.current_product.variant = JSON.parse(
          JSON.stringify(this.product.variant[0])
        );
        this.currentVariant = JSON.parse(
          JSON.stringify(this.product.variant[0])
        );
        this.variants = this.product.variant;
      }
      if (this.product.is_only_services) {
        this.showParents = false;
        this.showVariants = false;
      }

      this.current_product.variant.price = {
        quantity: 0,
        price: [{}],
      };

      if (this.product.runs !== 3) {
        this.current_product.variant.price.quantity = Object.keys(
          this.product.runs_range
        )[0];
        this.current_product.variant.price.price = this.currentVariant.price;
      } else {
        this.current_product.variant.price.price[0] = this.currentVariant.price[0];
      }
      this.current_product = JSON.parse(
        JSON.stringify(current_product.current_product)
      );
      this.current_product.cost = current_product.variant_cost;

      // this.current_product.shipment_date = current_product.shipment_date;
      console.log(current_product.shipment_date)
      let D = current_product.shipment_date.split(/[.]/g)
      let M = D[1].split(/[ ]/g)
      let H = M[1].split(/[:]/g)
      let min = H[1].split(/[:]/g)
      let selDate = new Date(2021, parseInt(M[0]) -1, parseInt(D[0]), parseInt(H[0]), parseInt(min[0]))
      console.log(D)
      console.log(M)
      console.log(H)
      console.log(min)
      console.log(selDate)

      let nD = new Date(selDate)
      console.log(moment(nD).format('YYYY-MM-DD HH:mm'))
      // this.current_product.shipment_date = selDate
      this.current_product.shipment_date = moment(nD).format('YYYY-MM-DD HH:mm')

      this.current_product.services = [];

      this.current_product.services = current_product.services
      this.current_product.id = current_product.id;
      this.current_product.status = current_product.status
      this.current_product.position_number = current_product.position_number
      if(this.current_product.status == 'order_edit'){
        this.showOrderLink = true;
      }
      return {
        current_product: current_product
      }
    },
    switchLogs() {
      this.isShowLogs = !this.isShowLogs;
    },
  },
  computed: {
    getHidePanel() {
      return store.state.hidePanel;
    },
  },
  watch: {
    getHidePanel(hidePanel) {
      this.hidePanel = hidePanel;
    }
  },
  created() {
    let loaded_product = this.$route.params.id;
    let orderProduct = this.getOrderProduct(loaded_product);
    let promises = [];
    promises.push(orderProduct)
    Promise.all(promises).then((res) => {
      let current_product = res[0].current_product;
      console.log('current_product: ', current_product)
      if(current_product.status === 'approved'){
        axios.get(`api/auth/manufacture/change-status/ready-to-handle?id=${current_product.id}`)
          .then(res => {
            console.log(res.data);
            //window.location.reload();
            this.current_product.status = 'ready_to_handle'
            this.getProductOrderStatuses()
            this.logs.push({
              comment: null,
              created_at: "Только что",
              status_new: "ready_to_handle",
              status_old: "approved",
              user: {name: this.$store.state.auth.user.name},
            })
            this.showLoader = false;
          })
      }else{
        this.showLoader = false;
      }
    });
    console.log(this.$router.currentRoute.params.id)
    this.getProductOrderStatuses();
    /* axios.get(`api/auth/manufacture/change-status/get-product-order-statuses/${this.$router.currentRoute.params.id}`)
        .then(res => {
          console.log(res.data);
          this.productOrderStatuses = res.data;
        }) */
  }
};
</script>

