<template>
    <div id="app">
        <div class="page-wrapper">
            <div class="page-inner">
                <Sidebar />
                <div class="page-content-wrapper">
                    <Header />
                    <main class="page-content">
                        <router-view />
                    </main>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Sidebar from "../Common/Sidebar";
    import Header from "../Common/Header";
    export default {
        name: "DivisionList",
        components:{
            Sidebar,
            Header
        },
        data() {
            return {

            }
        },
    }
</script>

