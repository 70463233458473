<template>
  <form
    action=""
    enctype="multipart/form-data"
    method="post"
    @submit.prevent="storeService"
  >
    <!--<input type="hidden" :value="product.id">-->
    <div class="form-group mt-5">
      <h4 class="mt-4 mb-3">Наименование дополнительной услуги</h4>
      <input type="text" class="form-control" required v-model="service.name" />
    </div>
    <div class="form-group">
      <h4 class="mt-4 mb-3">Множественная</h4>
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          v-model="service.is_multiple"
          class="custom-control-input"
          id="isMultiple"
        />
        <label class="custom-control-label" for="isMultiple"></label>
      </div>
    </div>
    <div class="form-group">
      <h4 class="mt-4 mb-3">Не учитывать в расчете скидки</h4>
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          v-model="service.is_block_sale"
          class="custom-control-input"
          id="isBlockSale"
        />
        <label class="custom-control-label" for="isBlockSale"></label>
      </div>
    </div>
    <div class="form-group">
      <h4 class="mt-4 mb-3">Варианты</h4>
      <multiselect
        v-model="service.variants"
        :multiple="true"
        :taggable="true"
        @tag="addTag"
        tag-position="bottom"
        tag-placeholder="Добавьте вариант услуги"
        :options="service.variants"
        :show-labels="false"
        :searchable="true"
        :allow-empty="true"
        placeholder="Выберите или добавьте вариант услуги"
      >
        <template slot-scope="{ option }">{{ option }}</template>
      </multiselect>
    </div>
    <div class="form-group">
      <h4 class="mt-4 mb-3">Тип цены услуги</h4>
      <multiselect
        v-model="service.runType"
        @select="changeType"
        track-by="name"
        label="name"
        :options="runTypes"
        :show-labels="false"
        :searchable="false"
        :allow-empty="false"
        placeholder=""
      >
        <template slot-scope="{ option }">{{ option }}</template>
      </multiselect>
    </div>
    <div class="form-group" v-bind:class="{ 'd-none': hideServicePrice }">
      <h4 class="mt-4 mb-3">Цена за услугу ({{ priceTypeText }})</h4>

      <div class="d-flex">
        <div
          class="col-1 mr-3 pl-0"
          v-for="(value, index) in service.price"
          v-bind:key="index"
        >
          <label
            class="form-label text-center d-block"
            :for="'priceInput' + index"
            >{{ service.price[index].quantity }}</label
          >
          <input
            :data-index="index"
            v-model="service.price[index].price"
            value="0"
            :id="'priceInput' + index"
            type="number"
            step="0.01"
            class="form-control text-center"
          />
        </div>
      </div>
    </div>
    <div class="form-group">
      <h4 class="mt-4 mb-3">
        Исключения
        <button
          @click.prevent="exceptAll"
          type="button"
          class="ml-5 btn btn-xs btn-outline-secondary waves-effect waves-themed"
        >
          Исключить все
        </button>
      </h4>
      <multiselect
        v-model="service.exceptions"
        :multiple="true"
        :taggable="true"
        track-by="name"
        label="name"
        tag-position="bottom"
        tag-placeholder="Выберите исключения"
        :options="services"
        :show-labels="false"
        :searchable="true"
        :allow-empty="true"
        placeholder="Выберите исключения"
      >
        <template slot-scope="{ option }">{{ option }}</template>
      </multiselect>
    </div>
    <div class="form-group d-none">
      <label class="form-label">Комментарий к услуге</label>
      <ckeditor
          :editor="editor"
          v-model="product.comments"
          :config="editorConfig"
          id="productComment"
      ></ckeditor>
    </div>
    <div class="d-flex">
      <div class="custom-control custom-checkbox col-2">
        <input
          type="checkbox"
          class="custom-control-input"
          v-model="service.is_preset"
          id="preset"
          value="0"
        />
        <label class="custom-control-label" for="preset"
          >Услуга по умолчанию</label
        >
      </div>
    </div>
    <div class="d-flex">
      <div class="custom-control custom-checkbox col-3">
        <input
          type="checkbox"
          class="custom-control-input"
          v-model="service.variant_output"
          id="variantOutput"
          value="0"
        />
        <label class="custom-control-label" for="variantOutput"
          >Выводить в вариант продукта</label
        >
      </div>
    </div>
    <div class="d-flex">
      <div class="custom-control custom-checkbox col-3">
        <input
          type="checkbox"
          class="custom-control-input"
          v-model="service.is_counted"
          id="isCounted"
          value="0"
        />
        <label class="custom-control-label" for="isCounted"
          >Указывать количество</label
        >
      </div>
    </div>
    <div class="d-flex flex-row-reverse">
      <input
        type="submit"
        class="btn btn-primary btn-md btn-block waves-effect waves-themed col-1"
        value="Сохранить"
      />
      <input
        type="submit"
        @click.prevent="backToProduct"
        class="btn btn-default btn-md btn-block waves-effect waves-themed col-1 mt-0 mr-3"
        value="Отменить"
      />
    </div>
  </form>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import product from "../../store/products";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/ru";

export default {
  name: "CreateProductServices",
  components: {
    Multiselect,
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        language: "ru",
      },
      service: {
        name: null,
        is_multiple: false,
        is_block_sale: false,
        is_preset: false,
        variants: [],
        price: {},
        exceptions: [],
        runType: null,
        product_id: null,
        variant_output: false,
      },
      services: [],
      product: {},
      runTypes: [
        {
          name: "Общий",
          id: 1,
        },
        {
          name: "За единицу к единице продукта",
          id: 2,
        },
        {
          name: "За единицу",
          id: 3,
        },
        {
          name: "Индивидуальный",
          id: 4,
        },
        {
          name: "Периметр",
          id: 5,
        },
      ],
      priceTypeText: "не определено",
      hideServicePrice: true,
      isEdit: false,
    };
  },
  methods: {
    addTag(newTag) {
      this.service.variants.push(newTag);
    },
    changeType(value) {
      this.service.runType = value.id;
      this.hideServicePrice = false;
      let runs = this.product.runs_range;
      console.log(runs);
      switch (this.service.runType) {
        case 1:
          this.service.price = [];
          for (let i in runs) {
            let item = runs[i];
            this.service.price.push({
              quantity: item,
              price: 0,
            });
          }
          switch (this.product.runs) {
            case 1:
              this.priceTypeText = "единицы";
              break;
            case 2:
              this.priceTypeText = "интервалы";
              break;
            case 3:
              this.priceTypeText = "тиражи";
              break;
            case 4:
              this.priceTypeText = "интервалы для м2";
              break;
          }
          break;
        case 2:
          this.priceTypeText = "за единицу к единице продукта";
          this.service.price = [
            {
              quantity: 1,
              price: 0,
            },
          ];
          break;
        case 3:
          this.priceTypeText = "за единицу";
          this.service.price = [
            {
              quantity: 1,
              price: 0,
            },
          ];
          break;
        case 4:
          this.priceTypeText = "Индивидуальный";
          this.service.price = [
            {
              quantity: 1,
              price: 0,
            },
          ];
          this.hideServicePrice = true;
          break;
      }
    },
    storeService() {
      console.log(this.service);
      axios
        .put(`api/auth/services/${this.service.id}`, this.service)
        .then(() => {
          console.log(this.service);
          alert("Услуга успешно  обновлена!");
          this.backToProduct();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    },
    getServices() {
      this.fetchServices(product.state.product.id);
    },
    exceptAll() {
      console.log(this.service);
      this.service.exceptions = this.services.slice();
    },
    backToProduct() {
      this.$router.push(`/products/product/${this.service.product_id}`);
    },
  },
  created() {
    let vm = this;
    let productData = JSON.parse(localStorage.getItem("product"));
    this.product = productData;
    this.service.product_id = productData.id;
    axios.get(`api/auth/services/${productData.id}`).then((res) => {
      vm.services = [];
      let services = res.data;
      let service_id = +this.$route.params.id;
      console.log(services);
      for (let k in services) {
        if (service_id !== services[k].id) {
          let item = {
            id: services[k].id,
            name: services[k].name,
          };
          vm.services.push(item);
        }
      }
    });
    axios.get(`api/auth/service/${this.$route.params.id}`).then((res) => {
      vm.service = res.data[0];
      console.log(vm.service);
      let runTypeIndex = vm.runTypes
        .map(function (run) {
          return run.id;
        })
        .indexOf(+vm.service.price_type);
      console.log(runTypeIndex);
      vm.service.runType = vm.runTypes[runTypeIndex];
      if (vm.service.price_type !== "4") {
        vm.hideServicePrice = false;
      }
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 14px;
}
</style>

