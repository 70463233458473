<template>
  <div :key="componentKey" class="pb-100">
    <div class="subheader">
      <h1 class="display-4 mb-0">Редактирование заказа</h1>
    </div>
    <div class="row">
      <div class="col-7">
        <div
          v-if="this.errors.length !== 0"
          class="alert alert-danger"
          role="alert"
        >
          <h1><strong>Пожалуйста, исправьте ошибки:</strong></h1>
          <ul>
            <li v-for="error in this.errors" :key="error[0]">{{ error[0] }}</li>
          </ul>
        </div>
        <div class="row">
          <div class="col-6">
            <h3 class="border-bottom mb-3">Информация о заказе</h3>
            <div class="form-group mb-2">
              <p class="fw-700 mt-1 mb-1">Номер заказа</p>
              <input
              v-if="order.order_type !== 'fast'"
                v-model="order.custom_number"
                type="text"
                class="form-control"
                :disabled="(!order.published || order.status == 'order_edit' || order.status == 'rejected' || !blockOrder) ? false : true "
              />
              <input
              v-else
                :value="order.custom_number ? order.custom_number : order.order_number"
                disabled
                type="text"
                class="form-control"
              />
            </div>
            <form action="" enctype="multipart/form-data" method="post">
              <div class="form-group mb-2">
                <p class="fw-700 mt-1 mb-1">Дата выдачи</p>
                <date-picker
                  v-model="order.shipment_date"
                  format="DD-MM-YYYY HH:mm"
                  type="datetime"
                  :disabled-date="disabledBeforeToday"
                  :lang="lang"
                  :disabled="(!order.published || order.status == 'order_edit' || order.status == 'rejected' || !blockOrder) ? false : true "
                  valueType="format"
                ></date-picker>
              </div>
              <div v-if="order.order_type != 'fast'" class="form-group mb-2">
                <p class="fw-700 mt-1 mb-1">Место выдачи</p>
                <multiselect
                  v-model="order.delivery_type"
                  :options="delivery_types"
                  track-by="name"
                  label="name"
                  :show-labels="false"
                  :searchable="true"
                  :allow-empty="false"
                  placeholder=""
                  :disabled="(!order.published || order.status == 'order_edit' || order.status == 'rejected' || !blockOrder) ? false : true "
                  @select="clearAddress"
                >
                  <template slot-scope="{ option }">{{ option }}</template>
                </multiselect>
                <div v-if="order.delivery_type.id === 0">
                  <p class="fw-700 mt-1 mb-1">Выберите копицентр</p>
                  <multiselect
                    v-model="order.delivery_division"
                    track-by="name"
                    label="name"
                    :options="divisions"
                    :show-labels="false"
                    :searchable="true"
                    :allow-empty="true"
                    placeholder=""
                    :disabled="(!order.published || order.status == 'order_edit' || order.status == 'rejected' || !blockOrder) ? false : true "
                    @select="setOrderAdress"
                  >
                    <template slot-scope="{ option }">{{ option }}</template>
                  </multiselect>
                </div>
                <div v-else>
                  <p class="fw-700 mt-1 mb-1">Введите адрес доставки</p>
                  <input
                    type="text"
                    v-model="order.shipment_address.address[0].address"
                    :disabled="(!order.published || order.status == 'order_edit' || order.status == 'rejected' || !blockOrder) ? false : true "
                    class="form-control"
                  />
                </div>
              </div>
              <div v-else>
                <div class="form-group mb-2">
                  <p class="fw-700 mt-1 mb-2">Копицентр выдачи</p>
                  <p>{{order.division ? order.division.name : ''}}</p>
                </div>
                <div class="form-group mb-2">
                  <p class="fw-700 mt-1 mb-2">Адрес выдачи</p>
                  <p>{{order.address ? order.address : ''}}</p>
                </div>

              </div>
              <div class="form-group mb-2">
                <p class="fw-700 mt-1 mb-1">Способ оплаты</p>
                <multiselect
                  v-model="order.payment_type"
                  track-by="name"
                  label="name"
                  :options="payment_types"
                  :show-labels="false"
                  :searchable="true"
                  :allow-empty="false"
                  :disabled="(!order.published || order.status == 'order_edit' || order.status == 'rejected' || !blockOrder) ? false : true "
                  placeholder=""
                >
                  <template slot-scope="{ option }">{{ option }}</template>
                </multiselect>
              </div>
              <div class="form-group mb-2">
                <p class="fw-700 mt-1 mb-1">Комментарий к заказу</p>
                <textarea
                  v-model="order.comment"
                  type="text"
                  class="form-control"
                  :disabled="(!order.published || order.status == 'order_edit' || order.status == 'rejected' || !blockOrder) ? false : true "
                ></textarea>
              </div>
            </form>
          </div>
          <div class="col-6">
            <h3 class="border-bottom mb-3">Информация о клиенте</h3>
            <form action="" enctype="multipart/form-data" method="post">
              <!--                    <div class="form-group mt-5">
                                  <input type="text" class="form-control" @input="searchCustomer" v-model="customer.search"
                                         placeholder="Поиск ...">
                                  <ul v-if="Object.keys(searchResult).length > 0">
                                      <li v-for="item in searchResult" :key="item.id">
                                          {{ item.name }}
                                      </li>
                                  </ul>
                              </div>-->
              <div class="form-group mb-2">
                <p class="fw-700 mt-1 mb-1">Фамилия Имя Отчество</p>
                <input
                  type="text"
                  class="form-control"
                  v-model="order.customer.name"
                  :disabled="(!order.published || order.status == 'order_edit' || order.status == 'rejected' || !blockOrder) ? false : true "
                />
              </div>
              <div class="form-group mb-2">
                <p class="fw-700 mt-1 mb-1">Email</p>
                <input
                  type="email"
                  class="form-control"
                  placeholder="mail@mail.ru"
                  v-model="order.customer.email"
                  :disabled="(!order.published || order.status == 'order_edit' || order.status == 'rejected' || !blockOrder) ? false : true "
                />
              </div>
              <div class="form-group mb-2">
                <p class="fw-700 mt-1 mb-1">Телефон</p>
                <masked-input
                  v-model="order.customer.phone"
                  class="form-control"
                  mask="\+1 (111) 111-11-11"
                  placeholder="+7 (999) 555-55-55"
                  :disabled="(!order.published || order.status == 'order_edit' || order.status == 'rejected' || !blockOrder) ? false : true "
                />
              </div>
            </form>
          </div>
          <div class="col-12 mt-5">
            <h3 class="border-bottom mb-3">Стоимость заказа</h3>
            <div class="row">
              <div class="col-6">
                <div class="form-group mb-2">
                  <p class="fw-700 mt-1 mb-1">Промокод</p>
                  <input
                    v-model="order.promocode"
                    type="text"
                    class="form-control"
                    :disabled="(!order.published || order.status == 'order_edit' || order.status == 'rejected' || !blockOrder) ? false : true "
                  />
                </div>
                <div class="form-group mb-2">
                  <p class="fw-700 mt-1 mb-1">Предоплата</p>
                  <input
                    v-model.number="order.prepaid"
                    type="number"
                    step="0.01"
                    class="form-control"
                    @change="calculateOrderTotal"
                    :disabled="(!order.published || order.status == 'order_edit' || order.status == 'rejected' || !blockOrder) ? false : true "
                  />
                </div>
                <div class="form-group mb-2">
                  <p class="fw-700 mt-1 mb-1">Стоимость Доставки</p>
                  <input
                    v-model.number="order.delivery_cost"
                    type="number"
                    step="0.01"
                    class="form-control"
                    @change="calculateOrderTotal"
                    :disabled="(!order.published || order.status == 'order_edit' || order.status == 'rejected' || !blockOrder) ? false : true "
                  />
                </div>
                <div class="form-group mb-2">
                  <p class="fw-700 mt-1 mb-1">Стоимость дизайна</p>
                  <input
                    v-model="order.design_cost"
                    type="number"
                    step="0.01"
                    disabled
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group mb-2">
                  <p class="fw-700 mt-1 mb-1">Стоимость изготовления</p>
                  <input
                    v-model="order.production_cost"
                    type="number"
                    step="0.01"
                    disabled
                    class="form-control"
                  />
                </div>

                <div class="form-group mb-2">
                  <p class="fw-700 mt-1 mb-1">Доплата</p>
                  <input
                    v-model="order.postpaid"
                    type="number"
                    step="0.01"
                    disabled
                    class="form-control"
                  />
                </div>
                <div class="form-group mb-2">
                  <p class="fw-700 mt-1 mb-1">Скидка/Наценка (руб.)</p>
                  <input
                    v-model="order.discount_markup"
                    type="number"
                    step="1"
                    class="form-control"
                    @change="calculateOrderTotal"
                    :disabled="(!order.published || order.status == 'order_edit' || order.status == 'rejected' || !blockOrder) ? false : true "
                  />
                </div>
                <div class="form-group mb-2">
                  <p class="fw-700 mt-1 mb-1">Итоговая стоимость</p>
                  <input
                    v-model="order.order_cost"
                    type="number"
                    step="0.01"
                    disabled
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-5 text-center">
        <div class="row">
          <div class="col-12">
            <button
              v-if="(order.order_type !== 'fast' && !order.published) || order.status == 'order_edit'|| order.status == 'rejected' || !blockOrder"
              class="btn btn-md btn-info waves-effect waves-themed mb-lg-5"
              @click="addProduct"
            >
              <span class="fal fa-plus mr-1"></span>
              Добавить продукт в заказ
            </button>
            <div v-if="order.order_type == 'fast'">
              <button
                v-if="order.published"
                @click="getFastOrderTZ(order.id)"
                type="button"
                class="btn btn-md btn-primary waves-effect waves-themed mb-5"
              >
                <span class="fal fa-download mr-1"></span> Скачать ТЗ
              </button>
              <p class="fw-700">{{order.name}}</p>
              <table
                v-if="Object.keys(params).length > 0"
                class="table m-0 table-bordered table-hover"
              >
                <thead>
                  <tr>
                    <th>Параметр</th>
                    <th>Значение</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, key) in params" :key="key">
                    <td>{{ item.text }}</td>
                    <td>{{ item.userVal }}</td>
                  </tr>
                </tbody>
              </table>
              <div v-if="hi_res.length" class="mt-5">
                <a
                  :href="hi_res[0]"
                  target="_blank"
                  class="btn btn-md btn-primary waves-effect waves-themed"
                  ><span class="fal fa-download mr-1"></span> Скачать макет</a
                >
              </div>
              <div v-if="archive !== ''" class="mt-5 mb-5">
                <a
                  :href="archive"
                  target="_blank"
                  class="btn btn-md btn-primary waves-effect waves-themed"
                  ><span class="fal fa-download mr-1"></span> Скачать архив с
                  файлами</a
                >
              </div>
              <table
                v-if="files.length > 0"
                class="table m-0 table-bordered table-hover"
              >
                <thead>
                  <tr>
                    <th>Список файлов</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in files" :key="item">
                    <td>
                      <a target="_blank" :href="item.split(';')[0]">{{
                        item.split(";")[0]
                      }}</a
                      ><br />{{ item.split(";")[1] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div
              v-for="product in products"
              :key="product.id"
              class="panel mb-lg-5"
            >
              <div class="panel-hdr">
                <h5 class="p-3 d-block w-100 text-left">
                 {{ product.name }}  <sup>({{product.position_number}} из {{products.length}})</sup> <span :class="statusTranslate(product.status)[1]">{{statusTranslate(product.status)[0]}}</span>
                </h5>

                <div class="panel-toolbar">
                  <button
                      title="Выдать клиенту"
                      @click="passToCustomer(product.id)"
                      type="button"
                      class="btn btn-md btn-icon waves-effect waves-themed mr-3"
                      v-if="product.status == 'delivered'"
                  >
                    <span aria-hidden="true"><i class="fal fa-hand-holding-box"></i></span>
                  </button>
                  <button
                      title="Ожидает выдачи"
                      @click="deliveredToPoint(product.id)"
                      type="button"
                      class="btn btn-md btn-icon waves-effect waves-themed mr-3"
                      v-if="product.status == 'ready'"
                  >
                    <span aria-hidden="true"><i class="fal fa-box-check"></i></span>
                  </button>
                  <button
                    title="Редактировать продукт"
                    @click="editProduct(product.id)"
                    type="button"
                    class="btn btn-md btn-icon waves-effect waves-themed mr-3"
                    v-if="!order.published || product.status == 'order_edit'|| product.status == 'rejected'  || !blockOrder"
                  >
                    <span aria-hidden="true"><i class="fal fa-edit"></i></span>
                  </button>
                  <button
                    title="Просмотреть продукт"
                    @click="viewProduct(product.id)"
                    type="button"
                    class="btn btn-md btn-icon waves-effect waves-themed mr-3"
                    v-if="order.published"
                  >
                    <span aria-hidden="true"><i class="fal fa-eye"></i></span>
                  </button>

                  <button
                    @click="copyProduct(product.id)"
                    title="Копировать продукт"
                    type="button"
                    class="btn btn-md btn-icon waves-effect waves-themed mr-3"
                    v-if="!order.published || product.status == 'order_edit'|| product.status == 'rejected' || !blockOrder"
                  >
                    <span aria-hidden="true"><i class="fal fa-copy"></i></span>
                  </button>
                  <button
                    title="Удалить продукт"
                    @click="removeProduct(product.id)"
                    type="button"
                    class="btn btn-md btn-icon waves-effect waves-themed mr-3"
                    v-if="!order.published || product.status == 'order_edit'|| product.status == 'rejected' || !blockOrder"
                  >
                    <span aria-hidden="true"
                      ><i class="fal fa-trash-alt"></i
                    ></span>
                  </button>
                </div>
              </div>
              <div class="panel-content text-left">
                <div class="frame-wrap p-3">
                  <div class="d-flex mb-5">
                    <div class="col-4">
                      <b
                        >{{
                          product.current_product.runs == 3
                            ? "Тираж"
                            : "Количество"
                        }}:</b
                      ><br />{{
                        product.current_product.runs !== 3
                          ? product.current_product.variant.price.quantity
                          : product.current_product.variant.price.price[0]
                              .quantity
                      }}
                      шт.
                    </div>
                    <div class="col-4">
                      <b>Цена:</b><br />{{ product.variant_cost }} руб.
                    </div>
                    <!-- <div class="col-4">
                      <b>Дата выдачи:</b><br />{{ product.variant_date }}
                    </div> -->
                  </div>

                  <button
                    v-if="order.published"
                    @click="getTZ(product.id, product)"
                    type="button"
                    class="btn btn-sm btn-default waves-effect waves-themed mr-3 mb-3"
                  >
                    <span class="fal fa-download mr-1"></span> Скачать ТЗ
                  </button>
                  <button
                    v-if="order.published"
                    @click="getContractorTZ(product.id, product)"
                    type="button"
                    class="btn btn-sm btn-default waves-effect waves-themed mr-3 mb-3"
                  >
                    <span class="fal fa-download mr-1"></span> Скачать ТЗ для
                    подрядчика
                  </button>
                  <button
                    v-if="order.published"
                    @click="getCustomerTZ(product.id, product)"
                    type="button"
                    class="btn btn-sm btn-default waves-effect waves-themed mb-3"
                  >
                    <span class="fal fa-download mr-1"></span> Скачать ТЗ для
                    клиента
                  </button>
                  <div>ID задания: {{product.id}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" v-show="storeOrderButton">
            <button class="btn btn-outline-success" @click="orderPassToCustomer(Number.parseInt(order.id))">Выдать заказ клиенту</button>
          </div>
          <div class="col-12" v-show="deliveredOrderButton">
            <button class="btn btn-outline-success" @click="orderDelivered(Number.parseInt(order.id))">Заказ ожидает выдачи</button>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12">
            <div class="panel-content">
              <div class="accordion accordion-hover" id="js_demo_accordion-5">
                <div class="card">
                  <div class="card-header">
                    <p
                      class="card-title"
                      data-toggle="collapse"
                      data-target="#js_demo_accordion-5a"
                      :aria-expanded="isShowLogs"
                      :class="{ collapsed: !isShowLogs }"
                      @click="switchLogs"
                    >
                      История заказа
                      <span class="ml-auto">
                        <span class="collapsed-reveal" v-if="isShowLogs">
                          <i class="fal fa-chevron-up fs-xl"></i>
                        </span>
                        <span class="collapsed-hidden" v-else>
                          <i class="fal fa-chevron-down fs-xl"></i>
                        </span>
                      </span>
                    </p>
                  </div>
                  <div
                    id="js_demo_accordion-5a"
                    class="collapse"
                    data-parent="#js_demo_accordion-5"
                    style=""
                    :class="{ show: isShowLogs }"
                  >
                    <div class="card-body p-0">
                      <table v-if="this.logs.length !== 0" class="table m-0">
                        <thead>
                          <tr role="row">
                            <th class="sorting_asc">Дата</th>
                            <th class="sorting_asc">Пользователь</th>
                            <th width="60%" class="sorting_asc">Действие</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="odd"
                            v-for="(log, index) in this.logs"
                            :key="index"
                          >
                            <td class="align-middle">
                              <nobr>{{ log.created_at }}</nobr>
                            </td>
                            <td class="align-middle">
                              {{ log.user.name }}
                              <!-- ({{ log.user.email }}) -->
                            </td>
                            <td class="align-middle">
                              <span v-if="log.status_old === log.status_new"
                                >обновил заказ
                              </span>
                              <span v-else
                                >изменил статус заказа с
                                <nobr class="font-weight-bold"
                                  >"{{
                                    status_list.find(
                                      (element) =>
                                        element.value === log.status_old
                                    ).text
                                  }}"</nobr
                                >
                                на
                                <nobr class="font-weight-bold"
                                  >"{{
                                    status_list.find(
                                      (element) =>
                                        element.value === log.status_new
                                    ).text
                                  }}"</nobr
                                ></span
                              >
                              <div v-if="log.comment">
                                <p class="font-weight-bold mb-1 mt-3">
                                  Комментарий:
                                </p>
                                <p>
                                  {{ log.comment }}
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-else>У данного заказа пустая история</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div  v-if="(!order.published || order.status == 'order_edit' || order.order_type == 'fast' || order.status == 'rejected' || !blockOrder) && [1, 4, 3, 5].includes(user.role)" class="float-footer">
      <div class="fixed-content d-flex align-items-center border-top" :style="hidePanel ? 'width: 100%' : ''">
        <div class="col-xl-1 col-md-2"></div>
        <div class="col-xl-6 d-flex col-md-2"></div>
        <div v-if="!order.published" class="col-xl-5 col-md-8 d-flex flex-row-reverse">
          <input
            @click="publishOrder"
            type="button"
            value="В производство"
            class="btn btn-primary btn-md btn-block waves-effect waves-themed col-6"
          />
          <button
            @click="saveOrder(false)"
            name="reset"
            class="btn btn-default btn-md btn-block waves-effect waves-themed col-6 mt-0 mr-3"
          >
            Сохранить как черновик
          </button>
        </div>
        <div v-else class="col-xl-5 col-md-8 d-flex flex-row-reverse align-items-end">
          <input
            @click="updateOrder(false)"
            type="button"
            value="Обновить заказ"
            class="btn btn-primary btn-md btn-block waves-effect waves-themed col-6"
            v-if="order.status !== 'rejected' && order.status !== 'order_edit'"
          />
          <input
            @click="updateOrder(false, false, true)"
            type="button"
            value="Вернуть в изготовление"
            class="btn btn-primary btn-md btn-block waves-effect waves-themed col-6"
            v-else
          />

          <div v-if="order.status !== 'rejected' && order.status !== 'order_edit'"  class="mr-5 col-6">
            <label class="form-label">Статус</label>
            <multiselect
              v-model="status_model"
              track-by="value"
              label="text"
              :options="status_list"
              :show-labels="false"
              :searchable="false"
              :allow-empty="false"
              placeholder=""
            >
              <template slot-scope="{ option }">{{ option }} </template>
            </multiselect>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade show"
      v-bind:class="{ 'd-block': showModal }"
      id="default-example-modal-center"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">
              {{modalText}}
              <!--<small class="m-0 text-muted">
                  Below is a static modal example
              </small>-->
            </h4>
            <button
              type="button"
              @click.prevent="hideModal"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
          </div>
          <!-- <div class="modal-body">

           </div>-->
          <div class="modal-footer">
            <!--<button type="button" @click.prevent="hideModal" class="btn btn-secondary waves-effect waves-themed" data-dismiss="modal">Close</button>-->
            <button
              type="button"
              @click.prevent="toOrderList"
              class="btn btn-primary waves-effect waves-themed"
            >
              К списку заказов
            </button>
            <button
              type="button"
              @click.prevent="hideModal"
              class="btn btn-primary waves-effect waves-themed"
            >
              Продолжить редактирование
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal-backdrop fade show"
      v-bind:class="{ 'd-none': !showModal }"
    ></div>

    <!--    Reject Order Form   -->
    <div
      class="modal fade show d-block"
      id="example-modal-alert"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      v-if="showBurningModal"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <i class="fal fa-bolt mr-3"></i> Укажите причину отклонения заказа
            </h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              @click="closeBurningModal"
              aria-label="Close"
            >
              <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body">
            <textarea cols="50" rows="10" v-model="order.log_comment">
            </textarea>
          </div>
          <div class="modal-footer mt-5">
            <button
              @click="setRejectData"
              type="button"
              class="btn btn-primary waves-effect waves-themed"
            >
              Отклонить заявку
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" v-if="showLoader"></div>
    <div class="modal fade show d-block" v-if="showLoader">
      <div
        class="fixed modal-dialog modal-dialog-centered justify-content-center"
      >
        <button
          class="btn btn-info waves-effect waves-themed"
          type="button"
          disabled=""
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Загрузка...
        </button>
      </div>
    </div>
    <!-- <alert-toast v-bind:show="this.storageErrors.show" v-bind:alertText="this.storageErrors.alertText"></alert-toast> -->
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import MaskedInput from "vue-masked-input";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";
import router from "@/router";
import fileDownload from "js-file-download";
import main from "@/store";
import store from "../../store";
//import AlertToast from "../Common/Toast/ErrorToast.vue";

export default {
  name: "OrderEdit",
  data() {
    return {
      user: store.getters["auth/user"],
      blockOrder: false,
      componentKey:0,
      showLoader: true,
      errors: [],
      storageErrors: {
        show: false,
        alertText: ''
      },
      storeOrderButton: false,
      deliveredOrderButton:false,
      products: [],
      current_status: '',
      statuses: {
        new: "Новый",
        approved: "В работе",
        ready: "Готов",
        delivered: "Ожидает выдачи",
        rejected: "Отклонен",
        "not needed": "Не востребован",
        pass_to_customer: 'Выдан',
        denied: "Отказ",
        //order_edit: "Корректировка заказа"
      },
      order: {
        id: this.$route.params.id,
        customer: {
          // name: null,
          // email: null,
          // phone: null,
          // search: null,
          // id: null,
        },
        // products: [],
        user_id: this.$store.getters["auth/user"].id,
        division_id: JSON.parse(localStorage.getItem("user_division")).id,
        date: null,
        delivery_type: {
          id: 0,
          name: "Копицентр",
        },
        //delivery_division: {},
        shipment_address: {
          id: 0,
          address: [
            {
              address: "",
            },
          ],
        },
        payment_type: {},
        comment: "",
        promocode: "",
        postpaid: 0,
        prepaid: 0,
        design_price: 0,
        production_price: 0,
        discount_markup: 0,
        delivery_cost: 0,
        order_cost: 0,
        order_type: "",
        status: "",
      },
      status_list: [
        {
          value: "new",
          text: "Новый",
        },
        {
          value: "approved",
          text: "В работе",
        },
        {
          value: "ready",
          text: "Готов",
        },
        {
          value: "delivered",
          text: "Ожидает выдачи",
        },
        {
          value: "pass_to_customer",
          text: "Выдан",
        },
        {
          value: "rejected",
          text: "Отклонен",
        },
        {
          value: "not needed",
          text: "Не востребован",
        },
        {
          value: "denied",
          text: "Отказ",
        },
        {
          value: "order_edit",
          text: "Отклонен",
        },
      ],
      status_model: {},
      params: {},
      hi_res: [],
      archive: "",
      files: [],
      searchResult: {},
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
      showBurningModal: false,
      showBackdrop: false,
      divisions: [],
      delivery_types: [
        {
          id: 0,
          name: "Копицентр",
        },
        {
          id: 1,
          name: "Адрес",
        },
        {
          id: 2,
          name: "Главпункт",
        },
        {
          id: 3,
          name: "Pickpoint",
        },
        {
          id: 4,
          name: "Boxberry",
        },
      ],
      payment_types: [
        {
          id: 0,
          name: "Наличными",
        },
        {
          id: 1,
          name: "Онлайн",
        },
        {
          id: 2,
          name: "Безналичный",
        },
        {
          id: 3,
          name: "Депозит",
        },
        {
          id: 4,
          name: "Постоплата",
        },
        {
          id: 5,
          name: "Постоплата ЦКО",
        },
        {
          id: 6,
          name: "Банковская карта",
        },
        {
          id: 7,
          name: "Электронные деньги",
        },
      ],
      showModal: false,
      modalText: '',
      isShowLogs: false,
      logs: [],
      // logText: ''
      hidePanel: store.state.hidePanel,
    }
  },
  components: {
    MaskedInput,
    DatePicker,
    Multiselect,
    //AlertToast,
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    statusTranslate(status){
      const statusNames = {
        'new' : ['Подготавливается',  "color-primary-300"],
        'approved' : ['Отправлен в изготовление',  "color-primary-300"],
        'ready_to_handle': ['Принят в обработку', "color-info-300"],
        'pass_to_producing' : ['Производство', "color-info-300"],
        'ready' : ['Готов', "color-success-200"],
        'pass_to_delivery' : ['Передан в доставку', "color-info-300"],
        'accepted_by_delivery' : ['Принят в доставку', "color-info-300"],
        'delivered' : ['Ожидает выдачи', "color-info-300"],
        'denied': ['Отклонен клиентом', "color-info-300"],
        'await_for_customer' : ['Ожидает клиента', "color-info-300"],
        'pass_to_customer' : ['Выдан клиенту', "color-success-600"],
        'rejected': ['Отклонен производством', "color-danger-300"],
        'order_edit': ['Отклонен', "color-danger-300"]
      }
      return statusNames[status];
    },
    getTZ(product_id, product) {
      this.showLoader = true;
      let vm = this;
      axios
        .get(`api/auth/orders/create/techorder/${product_id}`, {
          responseType: "blob",
          headers: {
            Accept: "application/pdf",
          },
        })
        .then((res) => {
          this.showLoader = false;
          console.log(res.data);
          let fileName = vm.order.custom_number
            ? vm.order.custom_number
            : vm.order.order_number;
          let num = vm.products.length;
          // let productIndex = this.products
          //   .map(function (item) {
          //     return item.id;
          //   })
          //   .indexOf(product_id);
          // productIndex++;

          fileDownload(
            res.data,
            fileName + "(" + product.position_number + " из " + num + ").pdf"
          );
        }).catch((err)=>{
          console.log(err);
        });
    },
    getContractorTZ(product_id, product) {
      this.showLoader = true;
      let vm = this;
      axios
        .get(`api/auth/orders/create/techorder/contractor/${product_id}`, {
          responseType: "blob",
          headers: {
            Accept: "application/pdf",
          },
        })
        .then((res) => {
          this.showLoader = false;
          console.log(res.data);
          let fileName = vm.order.custom_number
            ? vm.order.custom_number
            : vm.order.order_number;

          let num = vm.products.length;
          // let productIndex = this.products
          //   .map(function (item) {
          //     return item.id;
          //   })
          //   .indexOf(product_id);
          // productIndex++;
          fileDownload(
            res.data,
            "$" + fileName + "(" + product.position_number + " из " + num + ").pdf"
          );
        });
    },
    getCustomerTZ(product_id, product) {
      this.showLoader = true;
      let vm = this;
      axios
        .get(`api/auth/orders/create/techorder/customer/${product_id}`, {
          responseType: "blob",
          headers: {
            Accept: "application/pdf",
          },
        })
        .then((res) => {
          this.showLoader = false;
          console.log(res.data);
          let fileName = vm.order.custom_number
            ? vm.order.custom_number
            : vm.order.order_number;
          let num = vm.products.length;
          // let productIndex = this.products
          //   .map(function (item) {
          //     return item.id;
          //   })
          //   .indexOf(product_id);
          // productIndex++;
          fileDownload(
            res.data,
            fileName + "(" + product.position_number + " из " + num + ")-customer.pdf"
          );
        });
    },
    getFastOrderTZ(order_id) {
      let vm = this;
      axios
        .get(`api/auth/orders/create/techorder/fast/${order_id}`, {
          responseType: "blob",
          headers: {
            Accept: "application/pdf",
          },
        })
        .then((res) => {
          let fileName = vm.order.custom_number
            ? vm.order.custom_number
            : vm.order.order_number;
          fileDownload(res.data, fileName + ".pdf");
        });
    },
    closeBurningModal() {
      this.showBackdrop = false;
      this.showBurningModal = false;
    },
    toBurningList() {
      this.showBackdrop = false;
      this.showBurningModal = false;
      if (this.$router.currentRoute.name !== "orderList") {
        router.push({ name: "orderList", params: { activeTab: "burning" } });
      } else {
        main.state.push.orderListTab = "";
        main.state.push.orderListTab = "burning";
      }
    },
    setRejectData() {
      if (this.order.log_comment && this.order.log_comment != "") {
        this.showBurningModal = false;
        axios
          .post(`api/auth/orders/order/store/${this.order.id}`, this.order)
          .then(() => {
            this.showModal = true;
            this.modalText = 'Заказ успешно сохранён!'
          });
      }
    },
    publishOrder() {
      this.order.published = true;
      this.order.status = "approved";
      this.status_model = {
        value: "approved",
        text: "В работе",
      };
      axios
        .post(`api/auth/orders/order/store/${this.order.id}`, this.order)
        .then(() => {
          this.errors = [];
          this.showModal = true;
          this.modalText = 'Успешно! Все продукты в заказе запущены в производство.'
          axios.get(`api/auth/order/log/show/${this.order.id}`).then((res) => {
            this.logs = res.data;
          });
          this.getProducts();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
            this.order.published = false;
            this.order.status = "new";
            this.status_model = {
              value: "new",
              text: "Новый",
            };
          }
        });
    },
    updateOrder(goEdit = true, editProduct = false, returnOrder = false) {
      //параметр для переадресации
      if(!returnOrder){
        this.order.status = this.status_model.value;
      }else{
        this.order.status = "approved"
      }

      if (this.status_model.value === "rejected" && this.order.order_type == "fast") {
        this.showBurningModal = true;
        this.showBackdrop = true;
      } else {
        axios
          .post(`api/auth/orders/order/store/${this.order.id}`, this.order)
          .then(() => {
            this.errors = [];
            this.showModal = true;
            this.modalText = 'Заказ успешно обновлён!'
            axios
              .get(`api/auth/order/log/show/${this.order.id}`)
              .then((res) => {
                this.logs = res.data;
              });

            if (goEdit) {
              this.$router.push(
                `/order/orders/order-product/edit/${
                  JSON.parse(localStorage.getItem("order")).id
                }`
              );
              //this.forceRerender()

            }
            if (editProduct) {
              router.push({ name: "orderProductEdit", params: { id: editProduct } });

            }

            this.getProducts();

          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
            }
          });
      }
    },
    saveOrder(goEdit = true, editProduct = false) {
      axios
        .post(`api/auth/orders/order/store/draft/${this.order.id}`, this.order)
        .then((res) => {
          console.log(res)
          this.showModal = true;
          this.modalText = 'Заказ успешно сохранён!'
          axios.get(`api/auth/order/log/show/${this.order.id}`).then((res) => {
            this.logs = res.data;
          });
          if (goEdit) {
            this.$router.push(
              `/order/orders/order-product/edit/${
                JSON.parse(localStorage.getItem("order")).id
              }`
            );
             //this.forceRerender()
          }

          if (editProduct) {
            router.push({ name: "orderProductEdit", params: { id: editProduct } });
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    toOrderList() {
      router.push({ name: "orderList" });
    },
    hideModal() {
      this.showModal = false;
    },
    addProduct() {
      if (!this.order.published) {
        this.saveOrder();
      } else {
        this.updateOrder();
      }
    },
    searchCustomer() {
      axios
        .get(`api/auth/customer/search/${this.customer.search}`)
        .then((res) => {
          console.log(res.data);
          this.searchResult = res.data;
        });
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    editProduct(id) {
      if (!this.order.published) {
        this.saveOrder(false, id);
      } else {
        this.updateOrder(false, id);
      }
    },
    passToCustomer(id) {
      let vm = this
      //TODO Добавить медод для смены статуса на "выдан клиенту"
      axios.post(`api/auth/manufacture/change-status/product-pass-to-customer`, {'id': id})
      .then(res => {
        console.log(res.data)
        vm.getProducts()
      })
    },
     deliveredToPoint(id) {
      let vm = this
      //TODO Добавить медод для смены статуса на "выдан клиенту"
      axios.post(`api/auth/manufacture/change-status/product-delivered`, {'id': id})
      .then(res => {
        console.log(res.data)
        vm.getProducts()
      })
    },
    orderPassToCustomer(id){
      let vm = this
      if(confirm('Вы действительно хотите выдать все продукты по заказу?')){

        axios.post('api/auth/manufacture/change-status/order-pass-to-customer', {'id': id})
        .then(()=>{
          vm.getProducts()
        })
        .catch(error => {
          this.storageErrors.show = true
          this.storageErrors.alertText = error.response.data
          console.log(error.response)
        })
      }
    },
    orderDelivered(id){
       let vm = this
      if(confirm('Вы действительно получили все продукты из заказа?')){

        axios.post('api/auth/manufacture/change-status/order-delivered', {'id': id})
        .then(()=>{
          vm.getProducts()
        })
        .catch(error => {
          this.storageErrors.show = true
          this.storageErrors.alertText = error.response.data
          console.log(error.response)
        })
      }
    },
    viewProduct(id) {
      router.push({ name: "orderProductView", params: { id: id } });
    },
    removeProduct(id) {
      if (confirm("Удалить продукт из заказа?")) {
        axios.delete(`api/auth/orders/order/product/destroy/${id}`).then(res => {
          this.getProducts();
          this.order = res.data
        });
        // window.location.reload()
      } else {
        return false;
      }
    },
    copyProduct(id) {
      axios.post(`api/auth/orders/order/product/copy/${id}`).then(() => {
        this.getProducts();
      });
    },
    getProducts() {
      let orderId = this.$route.params.id;
      let vm = this;
      axios.get(`api/auth/orders/${orderId}`).then((res) => {
        console.log(res.data);
        //this.customer = res.data.customer
        this.products = res.data.product_orders;
        vm.changeStatusForButton()
        vm.blockOrderButtons()
        vm.calculateOrderTotal()
      });
    },
    calculateOrderTotal() {
      let vm = this;
      axios
        .post(`api/auth/orders/order/calculate/${this.order.id}`, {
          orderParams: {
            prepaid: this.order.prepaid,
            discount: this.order.discount_markup,
            delivery_cost: this.order.delivery_cost,
          },
          products: this.products,
        })
        .then((res) => {
          // vm.order = res.data
          vm.order.postpaid = res.data.postpaid;
          vm.order.design_cost = res.data.design_cost;
          vm.order.production_cost = res.data.production_cost;
          vm.order.order_cost = res.data.order_cost;
        });
         this.changeStatusForButton();
    },
    setOrderAdress(data) {
      console.log(data);
      this.order.shipment_address = data;
    },
    clearAddress() {
      this.order.shipment_address = {
        id: 0,
        address: [
          {
            address: "",
          },
        ],
      };
    },
    switchLogs() {
      this.isShowLogs = !this.isShowLogs;
    },
    changeStatusForButton(){
      let not_delivered = 0
      let not_ready = 0
      let statuses_for_ready = ['ready', 'delivered' ,'pass_to_customer']
      let statuses_for_deliv = [ 'delivered' ,'pass_to_customer']

      for (let i = 0; i < this.products.length; i++) {
        if (statuses_for_deliv.indexOf(this.products[i].status) === -1) {
          not_delivered++
        }
        if (statuses_for_ready.indexOf(this.products[i].status) === -1) {
          not_ready++
        }
      }
      if (not_delivered != 0) {
        this.storeOrderButton = false
      } else {
        this.storeOrderButton = true
        not_ready = 1
      }
       if (not_ready != 0) {
        this.deliveredOrderButton = false
      } else {
        this.deliveredOrderButton = true
      }
      // console.log("Статусы ", err)
    },
    blockOrderButtons(){
      let err = 0;
      let status_arr = ['ready_to_handle', 'pass_to_producing', 'ready', 'pass_to_delivery', 'accepted_by_delivery', 'delivered', 'denied', 'await_for_customer', 'pass_to_customer']
      for (let i = 0; i < this.products.length; i++) {
        if (status_arr.indexOf(this.products[i].status) !== -1) {
          err++
        }
      }
      console.log(this.products.length);
      if (err !== 0) {
        this.blockOrder = true
        console.log('Blocked')
      } else {
        this.blockOrder = false
        console.log('Unblocked')
      }
      // console.log("Статусы ", err)
    },
  },
  computed:{
     getHidePanel() {
      return store.state.hidePanel;
    },
    // getHidePanel(hidePanel) {
    //   this.hidePanel = hidePanel;
    // },
  },
  watch: {

  },

  mounted() {
    axios.get("api/auth/divisions").then((res) => {
      for (let k in res.data) {
        if (
          res.data[k].type === true &&
          (res.data[k].division_type == "Экспресс" ||
            res.data[k].division_type == "Обычный")
        ) {
          this.divisions.push(res.data[k]);
        }
        if (res.data[k].name == "ЦКО" || res.data[k].name == "Производство") {
          this.divisions.push(res.data[k]);
        }
      }
      let orderId = this.$route.params.id;
      axios.get("api/auth/paymenttypes/payment-types").then((res) => {
        this.payment_types = res.data;
        axios.get(`api/auth/orders/${orderId}`).then((res) => {
          // console.log('ORDER ', res.data)
          localStorage.setItem("order", JSON.stringify(res.data));
          // this.order.customer.name = res.data.customer
          //   ? res.data.customer.name
          //   : "";
          // this.order.customer.email = res.data.customer
          //   ? res.data.customer.email
          //   : "";
          // this.order.customer.phone = res.data.customer
          //   ? res.data.customer.phone
          //   : "";
          this.order = res.data;
          this.current_status = this.order.status;
          console.log("Order data", res.data);
          this.order.shipment_address = JSON.parse(res.data.shipment_address);
          if (
            this.order.delivery_type.id == 0 &&
            this.order.shipment_address.division_type
          ) {
            this.order.delivery_division = this.order.shipment_address;
          }

          // this.order.customer.id = res.data.customer ? res.data.customer.id : "";
          // this.order.custom_number = res.data.custom_number;
          // this.order.user_id = res.data.user_id;
          // this.order.division_id = res.data.division_id;
          // this.order.payment_type = res.data.payment_type;
          // this.order.shipment_address = res.data.shipment_address;
          // this.order.discount_markup = res.data.discount_markup;
          // this.order.date = res.data.shipment_date;
          // this.order.comment = res.data.comment;
          // this.order.promocode = res.data.promocode;
          // this.order.prepaid = res.data.prepaid;
          // this.order.postpaid = res.data.postpaid;
          // this.order.published = res.data.published;
          // this.order.products = res.data.product_orders;
          // this.order.order_type = res.data.order_type;

          for (let i in this.status_list) {
            if (this.status_list[i].value == res.data.status) {
              this.status_model = this.status_list[i];
            }
          }
          console.log(this.status_model);
          if (this.order.order_type == "fast" && res.data.params) {
            console.log(JSON.parse(res.data.params));
            let params = JSON.parse(res.data.params);
            for (let i in params) {
              if (i == "hiRes" && params[i].userVal) {
                this.hi_res = params[i].userVal;
              }
              if (i == "archive") {
                this.archive = params[i];
              }
              if (i == "files") {
                this.files = params[i];
              }
              if (
                !params[i].userVal ||
                i == "proof" ||
                i == "hiRes" ||
                i == "numSale"
              ) {
                delete params[i];
              }
            }
            this.params = params;
          }

          /*
                this.order.design_price = res.data.design_price
                this.order.production_price = res.data.production_price
                this.order.post_payment = res.data.post_payment
                this.order.total = res.data.total*/
          //this.customer = res.data.customer
          this.products = res.data.product_orders;
          this.order.id = orderId;
          this.showLoader = false;
          this.blockOrderButtons();
          this.changeStatusForButton();
          axios.get(`api/auth/order/log/show/${this.order.id}`).then((res) => {
            this.logs = res.data;
            this.calculateOrderTotal();
          });
        });
      });
    });

  },

   /* created() {
    // const orderId = this.$route.params.id
    axios.get(`api/auth/orders/${this.order.id}`).then((res) => {
       console.log(res.data);
      this.products = res.data.product_orders;
      console.log(this.products);
      this.calculateOrderTotal();
      axios.get(`api/auth/order/log/show/${this.order.id}`).then((res) => {
        this.logs = res.data;
      });
    });
  }, */
};
</script>
<style lang="scss" rel="stylesheet/scss">
.float-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;

  & .fixed-content {
    position: fixed;
    bottom: 0;
    width: calc(100% - 16.875rem);
    height: 100px;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.3);
  }
}

.mx-datepicker {
  width: 100%;
}

.pb-100 {
  padding-bottom: 100px;
}
</style>
