import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Register from '../views/Register.vue'
// import RegisterSuccess from '../views/RegisterSuccess.vue'
import Dashboard from "../views/Dashboard"
import Polk from "../views/Polk"
import PolkStandard from "../components/Polk/PolkStandard"
import PolkEco from "../components/Polk/PolkEco"
import PolkOrders from "../components/Polk/PolkOrders"
import PolkManufacture from "../components/Polk/PolkManufacture"
import MyOrders from "../components/Polk/MyOrders"
import Constructor from "../components/Polk/Constructor"
import Download from "../components/Polk/Download"
import PolkEdit from "../components/Polk/PolkEdit"
import Notifications from "../views/Notifications"
import Manufactures from "../views/Manufactures"
import Mobile from "../views/Mobile"
import ManufactureSearch from "../components/Mobile/ManufactureSearch"
import ManufactureSearchAndOpen from "../components/Mobile/ManufactureSearchAndOpen"
import ManufactureInfo from "../components/Mobile/ManufactureInfo"
import Settings from "../components/Settings/Settings"
import Users from "../views/Users"
import UsersList from "../components/User/UsersList"
import UserEdit from "../components/User/UserEdit"
import CreateUser from "../components/User/CreateUser"
import Reports from "../components/Reports"
// import Product from "../views/Product"
// import CreateProduct from "../components/Products/CreateProduct"
import store from '../store'
// import CreateCategory from "../components/Category/CreateCategory"
// import CatalogProducts from "../components/Products/CatalogProducts"
// import CategoryView from "../components/Products/CategoryView"
// import CategoryList from "../components/Category/CategoryList"
import ManufactureFind from "@/components/Manufactures/ManufactureFind";
import ManufactureProductView from "@/components/Manufactures/ManufactureProductView";
import division from "./division"
import order from "./order"
import VueBodyClass from 'vue-body-class'
import ResetPasswordForm from "@/components/User/ResetPasswordForm";
import product from "@/router/product";
import TestManufactureFind from "@/components/Manufactures/TestManufactureFind";
import Calculators from "../views/Calculators";
import MaketCalculator from "@/components/Calculators/MaketCalculator";

import authMiddleware from './middleware/auth'
import notificationsMiddleware from './middleware/notifications'
import manufactureMiddleware from './middleware/manufacture'
import manufactureStatusMiddleware from './middleware/manufacture-status'
import polkMiddleware from './middleware/polk'
import usersMiddleware from './middleware/users'
import statisticMiddleware from './middleware/statistic'
//import fileDownload from 'js-file-download'


Vue.use(VueRouter)

const baseRoutes = [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
          bodyClass: 'home',
      },
      beforeEnter: (to, from, next) => {
        if (store.getters['auth/authenticated']) {
          return next({
              name: 'dashboard'
          })
        }
        next()
      }
    },
    {
        path: '/api/auth/reset/password/:token',
        name: 'reset-password-form',
        component: ResetPasswordForm,
        meta: {
            auth: false
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            breadcrumb: 'Dashboard',
            middleware: [
              authMiddleware
            ]
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
            requiresAuth: true,
            breadcrumb: 'Настройки',
            middleware: [
              authMiddleware
            ],
        },
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: Notifications,
        meta: {
            requiresAuth: true,
            breadcrumb: 'Уведомления',
            middleware: [
              authMiddleware, notificationsMiddleware
            ]
        },
        children: [
            {
                path: 'create',
                meta: {
                    breadcrumb: 'Создать уведомление',
                    middleware: [
                      authMiddleware, notificationsMiddleware
                    ]
                }
            },
        ]
    },
    {
        path: '/manufacture',
        name: 'manufacture',
        redirect: '/manufacture/find',
        component: Manufactures,
        meta: {
            requiresAuth: true,
            breadcrumb: 'Задания на изготовление',
            middleware: [
              authMiddleware, manufactureMiddleware
            ]
        },
        children: [
            {
                path: 'find',
                name: 'ManufactureFind',
                component: ManufactureFind,
                meta: {
                    breadcrumb: 'Поиск',
                    middleware: [
                      authMiddleware, manufactureMiddleware
                    ]
                }
            },
            {
                path: 'test/find',
                name: 'TestManufactureFind',
                component: TestManufactureFind,
                meta: {
                    breadcrumb: 'ПОиск',
                    middleware: [
                      authMiddleware, manufactureMiddleware
                    ]
                }
            },
            {
                path: '/manufacture/product/:id',
                name: 'ManufactureProductView',
                component: ManufactureProductView,
                props: true,
                meta: {
                    breadcrumb: 'Просмотр задания на изготовление',
                    middleware: [
                      authMiddleware, manufactureStatusMiddleware
                    ]
                }
            },
        ]
    },
    {
      path: '/mobile',
      name: 'mobile',
      component: Mobile,
      meta: {
          requiresAuth: true,
          breadcrumb: 'Производство',
          middleware: [
            authMiddleware
          ]
      },
      children: [
          {
            path: 'manufacture/search',
            component: ManufactureSearch,
            meta: {
              middleware: [
                authMiddleware
              ]
            }
          },
          {
            path: 'manufacture/info',
            component: ManufactureInfo,
            meta: {
              middleware: [
                authMiddleware
              ]
            }
          },
          {
            path: 'manufacture/open',
            component: ManufactureSearchAndOpen,
            meta: {
              middleware: [
                authMiddleware
              ]
            }
          },
      ]
    },
    {
        path: '/reports',
        name: 'reports',
        component: Reports,
        meta: {
            requiresAuth: true,
            breadcrumb: 'Отчётность',
            middleware: [
              authMiddleware, statisticMiddleware
            ]
        },
    },
    {
         path: '/users',
        name: 'users',
        component: Users,
        meta: {
            requiresAuth: true,
            breadcrumb: 'Пользователи',
            middleware: [
              authMiddleware, usersMiddleware
            ]
        },
        children: [
            {
                path: 'list',
                component: UsersList,
                meta: {
                    breadcrumb: 'Список пользователей',
                    middleware: [
                      authMiddleware, usersMiddleware
                    ]
                }
            },
            {
                path: 'edit/:id',
                name: 'userEdit',
                component: UserEdit,
                props: true,
                meta: {
                    breadcrumb: 'Редактирование пользователя',
                    middleware: [
                      authMiddleware, usersMiddleware
                    ]
                }
            },
            {
                path: 'create',
                name: 'createUser',
                component: CreateUser,
                meta: {
                    breadcrumb: 'Создание пользователя',
                    middleware: [
                      authMiddleware, usersMiddleware
                    ]
                }
            }
        ]
    },
    {
      path: '/polk',
      name: 'polk',
      component: Polk,
      meta: {
          requiresAuth: true,
          breadcrumb: 'Бессмертный полк',
      },
      children: [
          {
            path: 'list/standard',
            component: PolkStandard,
            meta: {
                breadcrumb: 'Штендеры стандарт',
                middleware: [
                  authMiddleware, polkMiddleware
                ]
            },
          },
          {
            path: 'list/eco',
            component: PolkEco,
            meta: {
                breadcrumb: 'Штендеры эконом',
                middleware: [
                  authMiddleware, polkMiddleware
                ]
            },
          },
          {
            path: 'orders',
            component: PolkOrders,
            meta: {
                breadcrumb: 'Список заказов',
                middleware: [
                  authMiddleware, polkMiddleware
                ]
            },
          },
          {
            path: 'manufacture',
            component: PolkManufacture,
            meta: {
                breadcrumb: 'Список заданий на изготовление',
                middleware: [
                  authMiddleware, manufactureMiddleware
                ]
            },
          },
          {
            path: 'my-orders',
            component: MyOrders,
            meta: {
                breadcrumb: 'Мои заказы',
                middleware: [
                  authMiddleware, polkMiddleware
                ]
            },
          },
          {
            path: 'constructor',
            component: Constructor,
            meta: {
                breadcrumb: 'Конструтор штендера',
                middleware: [
                  authMiddleware, polkMiddleware
                ]
            },
          },
          {
            path: 'download',
            component: Download,
            meta: {
                breadcrumb: 'Скачать макет штендера',
                middleware: [
                  authMiddleware, polkMiddleware
                ]
            },
          },
          {
            path: 'edit',
            component: PolkEdit,
            meta: {
                breadcrumb: 'Редактировать макет штендера',
                middleware: [
                  authMiddleware, polkMiddleware
                ]
            },
          },
      ]
    },
    {
        path: '/calculators',
        name: 'calculators',
        component: Calculators,
        meta: {
            requiresAuth: true,
            breadcrumb: 'Калькуляторы',
            middleware: [
              authMiddleware
            ]
        },
        children: [
            {
              path: 'maket',
              component: MaketCalculator,
              meta: {
                  breadcrumb: 'Макеты',
              },
            },
        ]
      },

]
const routes = baseRoutes.concat(division, order, product)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
const vueBodyClass = new VueBodyClass(routes);

router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) });
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.breadcrumb ? 'OQplus. ' + to.meta.breadcrumb : 'OQplus';
    });
})
// router.beforeEach(((to, from, next) => {
//     if (to.name !== 'Home' && !store.getters['auth/authenticated']) {
//         next({name: 'Home'})
//     } else {
//         next()
//     }
//     if (to.name == 'Home' && store.getters['auth/authenticated']) {
//         next({name: 'dashboard'})
//     }
// }))

export default router
