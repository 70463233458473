<template>
    <div id="app">
        <div class="page-wrapper">
            <div class="page-inner">
                <Sidebar />
                <div class="page-content-wrapper">
                    <Header />
                    <main  id="main" class="page-content">
                        <router-view/>
                    </main>
                </div>
            </div>
        </div>
        <Modals />
    </div>
</template>

<script>
    import Sidebar from "../components/Common/Sidebar";
    import Header from '../components/Common/Header';
    import Modals from '../components/Common/Modals';

    export default {
        name: "Users",
        data() {
            return {
            }
        },
        components:{
            Sidebar,
            Header,
            Modals
        }
    }
</script>
