<template>
  <div>
    <h1 class="display-4 mb-3">Создание продукта</h1>
    <form
      action=""
      enctype="multipart/form-data"
      @submit.prevent="storeProduct"
      method="post"
    >
      <div class="form-group">
        <h3 class="mt-5">Категория</h3>
        <multiselect
          v-model="category"
          track-by="name"
          label="name"
          :options="allCategories"
          :searchable="true"
          :show-labels="false"
          :allow-empty="false"
          placeholder=""
        >
          <template slot="singleLabel" slot-scope="{ option }">{{
            option.name
          }}</template>
        </multiselect>
      </div>
      <!--    Наименование   -->
      <hr class="mt-4 mb-g" />
      <div class="form-group">
        <h3 for="" class="form-label">Наименование</h3>
        <input type="text" class="form-control" v-model="product.name" />
      </div>

      <!--    Опубликован -->
      <hr class="mt-4 mb-g" />
      <div class="form-group">
        <div class="custom-control custom-switch">
          <input
            type="checkbox"
            v-model="product.published"
            class="custom-control-input"
            id="productPublish"
          />
          <label class="custom-control-label" for="productPublish"
            >Опубликован</label
          >
        </div>
        <div class="custom-control custom-switch mt-3">
          <input
            type="checkbox"
            v-model="product.has_preview"
            class="custom-control-input"
            id="productPreview"
          />
          <label class="custom-control-label" for="productPreview"
            >Обязательное превью</label
          >
        </div>
      </div>

      <!-- Тип тиражности -->
      <hr class="mt-4 mb-g" />
      <div class="form-group">
        <h3>Тиражность</h3>
        <label for="" class="form-label"
          >Тип тиражности {{ product.runs }}</label
        >
        <multiselect
          v-model="runValue"
          @select="changeType"
          track-by="name"
          label="name"
          :options="runTypes"
          :show-labels="false"
          :searchable="false"
          :allow-empty="false"
          placeholder=""
        >
          <template slot-scope="{ option }">{{ option.name }}</template>
        </multiselect>
      </div>
      <div class="form-group" v-bind:class="{ 'd-none': hideRunsAdd }">
        <label for="" class="form-label">Добавление {{ typeText }}</label>
        <div class="row m-0">
          <input
            v-for="(value, index) in product.runs_range"
            v-bind:key="index"
            type="number"
            :data-index="index"
            :value="value"
            v-on:change="changeValue"
            class="form-control text-center col-1 mr-3"
          />
          <span
            v-on:click="addValue"
            title="Добавить"
            class="btn btn-primary btn-md btn-icon rounded-circle waves-effect waves-themed"
          >
            <i class="fal fa-plus"></i>
          </span>
          <span
            v-on:click="deleteValue"
            class="ml-3 btn btn-danger btn-md btn-icon rounded-circle waves-effect waves-themed"
            v-bind:class="{ 'd-none': hideDeleteValue }"
          >
            <i class="fal fa-minus"></i>
          </span>
        </div>
      </div>

      <!--  Минимальная стоимость  -->
      <hr class="mt-4 mb-g" />
      <div class="form-group">
        <h3>Минимальная стоимость</h3>
        <input
          type="number"
          class="form-control col-2"
          v-model="product.min_price"
        />
      </div>
      <hr class="mt-4 mb-g" />
      <div class="form-group">
        <h3>Сроки изготовления</h3>

        <div class="d-flex mt-5 mb-5" style="align-items: center" v-for="(timing, key) in timings" :key="key">
          <div class="col-2 pl-0">
            <label class="form-label">Наименование срока</label>
            <input
              type="text"
              class="form-control"
              v-model="timing.name"
            />
          </div>
          <div class="col-2 pl-0">
            <label class="form-label">Доплата / скидка, %</label>
            <input
              type="number"
              class="form-control"
              v-model.number="timing.discount"
            />
          </div>
          <div class="col-2">
            <label class="form-label">Срок, дней</label>
            <input
              type="number"
              class="form-control"
              v-model.number="timing.days"
            />
          </div>
          <div class="col-3">
            <label class="form-label">Производитель</label>
            <multiselect
              v-model="timing.manufacture"
              track-by="name"
              label="name"
              :options="manufactureOptions"
              :searchable="true"
              :show-labels="false"
              :allow-empty="false"
              placeholder=""
            />
          </div>
          <div class="custom-control custom-radio col-2">
            <input
              type="radio"
              class="custom-control-input"
              :name="timing_default"
              :id="'timing_default_'+key"
              v-model="timing_default"
              :value="key"
            />
            <label class="custom-control-label" :for="'timing_default_'+key"
              >По умолчанию</label
            >
          </div>
          <div class="mt-4">
            <span
              v-on:click="deleteTiming(key)"
              class="ml-3 btn btn-danger btn-md btn-icon rounded-circle waves-effect waves-themed"
            >
              <i class="fal fa-minus"></i>
            </span>
          </div>
        </div>
        <span
            @click="addTiming"
            title="Добавить"
            class="btn btn-primary btn-md btn-icon rounded-circle waves-effect waves-themed"
          >
            <i class="fal fa-plus"></i>
        </span>
      </div>

      <div class="form-group col-2 pl-0">
        <h4 class="mt-4 mb-3">Прием заказа (до)</h4>
        <input
          type="time"
          class="form-control"
          v-model="product.accept_before"
        />
        <!-- min="09:00"
          max="21:00" -->
      </div>
      <div class="form-group col-2 pl-0">
        <h4 class="mt-4 mb-3">Выдача заказа (с)</h4>
        <input
          type="time"
          id="avatar"
          class="form-control"
          v-model="product.deliver_after"
        />
      </div>

      <div class="form-group d-none">
        <h4 class="mt-4 mb-3">Изготовление</h4>
        <div class="d-flex">
          <div class="custom-control custom-radio col-2">
            <input
              type="radio"
              class="custom-control-input"
              id="producerTypeSelf"
              name="defaultExampleRadios"
              value="1"
              v-model="product.production"

            />
            <label class="custom-control-label" for="producerTypeSelf"
              >Собственное (КЦ или Производство)</label
            >
          </div>
          <div class="custom-control custom-radio col-2">
            <input
              type="radio"
              class="custom-control-input"
              id="producerType1"
              name="defaultExampleRadios"
              value="2"
              v-model="product.production"

            />
            <label class="custom-control-label" for="producerType1"
              >Подрядчик 1 (не выдает в вс)</label
            >
          </div>
          <div class="custom-control custom-radio col-2">
            <input
              type="radio"
              class="custom-control-input"
              id="producerType2"
              name="defaultExampleRadios"
              value="3"
              v-model="product.production"

            />
            <label class="custom-control-label" for="producerType2"
              >Подрядчик 2 (не выдает в сб и вс)</label
            >
          </div>
          <div class="custom-control custom-radio col-2">
            <input
              type="radio"
              class="custom-control-input"
              id="producerType3"
              name="defaultExampleRadios"
              value="4"
              v-model="product.production"

            />
            <label class="custom-control-label" for="producerType3"
              >Подрядчик 3 (не выдает и не изготавливает на выходных)</label
            >
          </div>
          <div class="custom-control custom-radio col-2">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="producerType4"
                  name="defaultExampleRadios"
                  value="5"
                  v-model="product.production"

                />
                <label class="custom-control-label" for="producerType4"
                  >Подрядчик 4 (не работает на выходных)</label
                >
              </div>
        </div>
      </div>
      <hr class="mt-4 mb-g" />
      <h3 class="mb-3">Параметры расчета</h3>
      <div class="form-group">
        <div class="custom-control custom-switch">
          <input
            type="checkbox"
            v-model="product.is_only_services"
            class="custom-control-input"
            id="onlyServices"
          />
          <label class="custom-control-label" for="onlyServices"
            >Состоит только из услуг</label
          >
        </div>
      </div>
      <div class="form-group">
        <div class="custom-control custom-switch">
          <input
            type="checkbox"
            v-model="product.is_diff_product_types"
            class="custom-control-input"
            id="diffProductTypes"
          />
          <label class="custom-control-label" for="diffProductTypes"
            >Тираж (разные варианты продукта)</label
          >
        </div>
      </div>
      <div class="form-group">
        <h4 class="mt-4 mb-3">Наименование родительских вариантов</h4>
        <input
          type="text"
          class="form-control"
          v-model="product.parent_title"
        />
      </div>
      <div class="form-group">
        <h4 class="mt-4 mb-3">Наименование вариантов</h4>
        <input
          type="text"
          class="form-control"
          v-model="product.variant_title"
        />
      </div>
      <hr class="mt-4 mb-g" />
      <h3 class="mb-3">Примечания</h3>
      <div class="form-group">
        <label class="form-label">Описание продукта</label>
        <ckeditor
          :editor="editor"
          v-model="product.comments"
          :config="editorConfig"
          id="productComment"
        ></ckeditor>
      </div>
      <div class="form-group">
        <label class="form-label">Важные примечания</label>
        <input
          v-for="(value, index) in product.additional_checkboxes"
          v-bind:key="index"
          type="text"
          :data-index="index"
          :value="value"
          v-on:change="changeNote"
          class="form-control col-6 mb-3"
        />
        <div class="mt-4">
          <span
            v-on:click="addNote"
            title="Добавить"
            class="btn btn-primary btn-md btn-icon rounded-circle waves-effect waves-themed"
          >
            <i class="fal fa-plus"></i>
          </span>
          <span
            v-on:click="deleteNote"
            class="ml-3 btn btn-danger btn-md btn-icon rounded-circle waves-effect waves-themed"
            v-bind:class="{ 'd-none': hideDeleteNote }"
          >
            <i class="fal fa-minus"></i>
          </span>
        </div>
      </div>
      <div v-if="!hideErrors" class="alert alert-danger" role="alert">
        <h1><strong>Пожалуйста, исправьте ошибки:</strong></h1>
        <ul>
          <li v-for="(error, index) in this.errors" :key="index">
            {{ error[0] }}
          </li>
        </ul>
      </div>
      <div class="d-flex flex-row-reverse">
        <input
          type="submit"
          class="btn btn-primary btn-lg btn-block waves-effect waves-themed col-2"
          value="Сохранить"
        />
      </div>
      <div
        class="modal fade show"
        v-bind:class="{ 'd-block': showModal }"
        id="default-example-modal-center"
        tabindex="-1"
        role="dialog"
        aria-modal="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Продукт успешно сохранён!</h4>
              <button
                type="button"
                @click.prevent="hideModal"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
            </div>
            <div class="modal-footer"></div>
          </div>
        </div>
      </div>
      <div
        class="modal-backdrop fade show"
        v-bind:class="{ 'd-none': !showModal }"
      ></div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/ru";

export default {
  name: "ProductCreate",
  components: { Multiselect },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        language: "ru",
      },
      runValue: {},
      category: null,
      allCategories: [],
      product: {
        runs_range: [],
        additional_checkboxes: [],
        production: 1,
        has_preview: true,
        id: 0
      },
      typeText: "",
      hideRunsAdd: true,
      hideDeleteValue: true,
      hideDeleteNote: true,
      errors: [],
      hideErrors: true,
      manufactureOptions: [
        {name: 'Производство OQ', id: 1},
        {name: 'Стороннее производство', id: 2}
      ],
      showModal: false,
      timings: [{
        name: '',
        days: 0,
        discount: 0,
        manufacture: {
          name: 'Производство OQ',
          id: 1,
        },
        manufacture_id: 1,
        default: false
      }],
      timing_default: 0,
      runTypes: [
        {
          name: "Тиражный",
          id: 3,
        },
        {
          name: "Интервальный",
          id: 2,
        },
        {
          name: "За единицу",
          id: 1,
        },
        {
          name: "За м2",
          id: 4,
        },
        {
          name: "Периметр",
          id: 5,
        },
      ],
    };
  },
  methods: {
    storeProduct() {
      this.product.runs = this.runValue.id;
      this.product.category = this.category;
      this.product.hideRunsAdd = this.hideRunsAdd;
      this.product.typeText = this.typeText;
      let promises = [];

      promises.push(axios.post(`api/auth/product/store`, this.product).then(res => {
        this.product.id = res.data.id;
        this.timings[this.timing_default].default = true
        this.timings.forEach(timing => {
          timing.product_id = this.product.id
          timing.manufacture_id = timing.manufacture.id;
          promises.push(
            axios.post(`api/auth/timings`, {
              timing: timing
            }))
        });
      }))

      Promise.all(promises)
      .then(() => {
        alert("Продукт успешно создан!");
        this.$router.push(`/products/product/${this.product.id}`);
      })
      .catch((error) => {
        console.log(error)
        if (error.response.status === 422) {
          this.hideErrors = false;
          this.errors = error.response.data.errors;
        }
      });
    },
    changeType(value) {
      this.product.runs = value.id;
      switch (this.product.runs) {
        case 1:
          this.product.runs_range = [1];
          this.hideRunsAdd = true;
          break;
        case 2:
          this.typeText = "интервалов";
          this.hideRunsAdd = false;
          this.product.runs_range = [];
          break;
        case 3:
          this.typeText = "тиражей";
          this.hideRunsAdd = false;
          this.product.runs_range = [];
          break;
        case 4:
          this.typeText = "интервалов";
          this.hideRunsAdd = false;
          this.product.runs_range = [];
          break;
        case 5:
          this.typeText = "интервалов";
          this.hideRunsAdd = false;
          this.product.runs_range = ["1"];
          break;
      }
    },
    addValue() {
      this.product.runs_range.push(0);
      this.hideDeleteValue = false;
    },
    changeValue(e) {
      this.product.runs_range[e.target.dataset.index] = e.target.value;
    },
    deleteValue() {
      this.product.runs_range.pop();
      if (this.product.runs_range.length < 1) {
        this.hideDeleteValue = true;
      }
    },
    addNote() {
      this.product.additional_checkboxes.push("");
      this.hideDeleteNote = true;
    },
    changeNote(e) {
      this.product.additional_checkboxes[e.target.dataset.index] =
        e.target.value;
    },
    deleteNote() {
      this.product.additional_checkboxes.pop();
      if (this.product.additional_checkboxes.length < 1) {
        this.hideDeleteNote = false;
      }
    },
    addTiming() {
      this.timings.push({
        name: '',
        discount: 0,
        days: 0,
        manufacture: {
          name: 'Производство OQ',
          id: 1,
        },
        manufacture_id: 1,
        default:false
      })
    },
    deleteTiming(key) {
      this.timings.splice(key, 1);
    },
  },
  created() {
    axios.get("api/auth/category/list").then((res) => {
      this.allCategories = res.data;
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 14px;
}
</style>
