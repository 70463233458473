import Product from "@/views/Product";
import ProductCreate from "@/components/Products/ProductCreate";
import ProductEdit from "@/components/Products/ProductEdit";
import VariantAdd from "@/components/Products/VariantAdd";
import VariantEdit from "@/components/Products/VariantEdit";
import ServiceAdd from "@/components/Products/ServiceAdd";
import ServiceEdit from "@/components/Products/ServiceEdit";
import CreateCategory from "@/components/Category/CreateCategory";
import CatalogProducts from "@/components/Products/CatalogProducts";
import CategoryView from "@/components/Products/CategoryView";
import CategoryList from "@/components/Category/CategoryList";

import authMiddleware from './middleware/auth'
import productsMiddleware from './middleware/products'

export default [
    {
        path: '/products',
        name: 'products',
        redirect: '/products/list',
        component: Product,
        meta: {
            requiresAuth: true,
            breadcrumb: 'Продукты',
            middleware: [
              authMiddleware, productsMiddleware
            ],
        },
        children: [
            {
                path: 'create',
                name: 'ProductCreate',
                component: ProductCreate,
                meta: {
                    breadcrumb: 'Создание продукта',
                    middleware: [
                      authMiddleware, productsMiddleware
                    ],
                }
            },
            {
                path: 'product/:id',
                name: 'ProductEdit',
                component: ProductEdit,
                props: true,
                meta: {
                    breadcrumb: 'Редактирование продукта',
                    middleware: [
                      authMiddleware, productsMiddleware
                    ],
                }
            },
            {
                path: 'variant/add',
                name: 'VariantAdd',
                component: VariantAdd,
                meta: {
                    breadcrumb: 'Создание варианта',
                    middleware: [
                      authMiddleware, productsMiddleware
                    ],
                }
            },
            {
                path: 'variant/:id',
                name: 'VariantEdit',
                component: VariantEdit,
                meta: {
                    breadcrumb: 'Редактирование варианта',
                    middleware: [
                      authMiddleware, productsMiddleware
                    ],
                }
            },
            {
                path: 'service/add',
                name: 'ServiceAdd',
                component: ServiceAdd,
                meta: {
                    breadcrumb: 'Создание услуги',
                    middleware: [
                      authMiddleware, productsMiddleware
                    ],
                }
            },
            {
                path: 'service/:id',
                name: 'ServiceEdit',
                component: ServiceEdit,
                meta: {
                    breadcrumb: 'Редактирование услуги',
                    middleware: [
                      authMiddleware, productsMiddleware
                    ],
                }
            },
            {
                path: 'category/create',
                name: 'createCategory',
                component: CreateCategory,
                meta: {
                    breadcrumb: 'Создание категории',
                    middleware: [
                      authMiddleware, productsMiddleware
                    ],
                }
            },
            {
                path: 'list',
                name: 'catalogProducts',
                component: CatalogProducts,
                meta: {
                    breadcrumb: 'Каталог продуктов',
                    middleware: [
                      authMiddleware, productsMiddleware
                    ],
                }
            },
            {
                path: 'category/:id',
                name: 'categoryView',
                component: CategoryView,
                props: true,
                meta: {
                    breadcrumb: 'Просмотр категории',
                    middleware: [
                      authMiddleware, productsMiddleware
                    ],
                }
            },
            {
                path: 'categories',
                name: 'categories',
                component: CategoryList,
                props: true,
                meta: {
                    breadcrumb: 'Список категорий',
                    middleware: [
                      authMiddleware, productsMiddleware
                    ],
                }
            },
            // {
            //     path: 'test-create/:id',
            //     name: 'test-create',
            //     component: TestCreateProductMain,
            //     props: true,
            //     meta: {
            //         breadcrumb: 'Тест Создание продукта'
            //     }
            // }
        ]
    }
]
