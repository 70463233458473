<template>
  <div>
    <h1 class="display-4 mb-3">Пользователи и роли</h1>
    <div class="subheader">
      <router-link to="/create"></router-link>
    </div>

    <div class="panel-container show">
      <table id="dt-basic-example" class="table table-bordered table-hover table-striped w-100 dataTable dtr-inline"
             role="grid" aria-describedby="dt-basic-example_info" style="width: 1475px;">
        <thead class="bg-primary-600">
        <tr role="row">
          <th class="sorting_asc">ID #</th>
          <th class="sorting_asc">Имя</th>
          <th class="sorting_asc">Email</th>
          <th class="sorting_asc">Роль</th>
          <th class="sorting_asc">Управление</th>
        </tr>
        </thead>
        <tbody>
        <tr class="odd" v-for="(user, ind) in users" v-bind:key="ind">
          <td>{{ user.id }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.role }}</td>
          <td>
            <button title="Редактировать пользователя" type="button"
                    @click.prevent="editUser(user.id)"
                    class="btn  btn-md btn-icon waves-effect waves-themed mr-3">
                                    <span aria-hidden="true"><i
                                        class="fal fa-edit"></i></span>
            </button>
            <button title="Удалить пользователя" type="button" @click.prevent="deleteUser(user.id)"
                    class="btn  btn-md btn-icon waves-effect waves-themed">
              <span aria-hidden="true"><i class="fal fa-trash-alt"></i></span>
            </button>
          </td>

        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";

export default {
  name: "UsersList",
  data() {
    return {
      users: null
    }
  },
  methods: {
    getUsers() {
      axios.get('api/auth/users')
          .then(res => {
            console.log(res.data)
            this.users = res.data
          })
    },
    editUser(id) {
      router.push({name: 'userEdit', params: {id: id}})
    },
    deleteUser(id) {
      if (confirm("Удалить пользователя?")) {
        axios.delete('api/auth/user/delete/' + id)
            .then(res => {
              console.log(res.data)
              if (res.data.user) {
                this.getUsers()
              }
            })
      } else {
        return false;
      }
    }
  },
  beforeMount() {
    this.getUsers()
  }
}
</script>

<style scoped>

</style>
