<template>
  <div>
    <h1 class="display-4 mb-3">Редактирование пользователя</h1>
    <div class="alert alert-dismissible fade show"
         v-bind:class="{ 'd-none': alertTextHide, 'alert-danger': alertDanger, 'alert-success': alertSuccess}"
         role="alert">
      <button type="button" class="close" data-dismiss="alert" @click="hideAlert" aria-label="Close">
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
      </button>
      {{ alertText }}
    </div>

    <form action="" @submit.prevent="storeUser">
      <div class="form-group" :class="{ 'form-group--error': $v.user.name.$error }">
        <label for="" class="form-label">Имя</label>
        <input type="text" v-model.trim="$v.user.name.$model" class="form-control">
      </div>
      <div class="error" v-if="!$v.user.name.required">Field is required</div>
      <div class="error" v-if="!$v.user.name.minLength">Name must have at least {{ $v.user.name.$params.minLength }}
        letters.
      </div>
      <!--                                <tree-view :data="$v.user.name" :options="{rootObjectKey: '$v.user.name', maxDepth: 2}"></tree-view>-->
      <div class="form-group">
        <label for="" class="form-label">Email</label>
        <input type="email" v-model="user.email" class="form-control">
      </div>
      <div class="form-group">
        <label for="" class="form-label">Пароль</label>
        <input type="password" v-model="user.password" class="form-control">
      </div>
      <div class="form-group">
        <label for="" class="form-label">Повтор пароля</label>
        <input type="password" v-model="user.confirmPassword" class="form-control">
      </div>
      <div class="form-group" v-if="user.avatar">
        <img :src="user.avatar" alt="" width="200px" height="200px">
      </div>
      <div class="form-group">
        <label for="" class="form-label">Аватар пользователя</label>
        <input type="file" class="form-control" @change="changeImg">
      </div>
      <div class="form-group">
        <label for="" class="form-label">Роль</label>
        <select type="text" class="form-control" v-model="user.role" required>
          <option v-for="(role, index) in roles" v-bind:key="index" :selected="role.name == 'user.role ? true : false'"
                  :value="role.id">{{ role.name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="" class="form-label">Подразделение к которому относится сотрудник</label>
        <select type="text" class="form-control" v-model="user.division" required>
          <option v-for="item in divisions" v-bind:key="item.id" :selected="item.id == 'user.division ? true : false'"
                  :value="item.id">{{ item.name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <input type="submit" class="btn btn-outline-info mb-g waves-effect waves-themed" value="Сохранить">
      </div>
    </form>

  </div>
</template>

<script>
import axios from 'axios'
import {required, minLength} from 'vuelidate/lib/validators'

export default {
  name: "UserEdit",
  data() {
    return {
      id: this.$attrs.id,
      user: {
        id: null,
        name: null,
        email: null,
        password: null,
        confirmPassword: null,
        role: null,
        avatar: null,
        division: null
      },
      roles: {},
      avatar: null,
      data: null,
      divisions: {},
      alertText: null,
      alertTextHide: true,
      alertDanger: false,
      alertSuccess: false,
    }
  },
  validations: {
    user: {
      name: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    getUser() {
      axios.get('api/auth/user/' + this.id)
          .then(res => {
            console.log(res.data)
            this.user.id = res.data.id
            this.user.name = res.data.name
            this.user.email = res.data.email
            this.user.avatar = res.data.avatar
            this.user.role = res.data.role
            this.user.division = res.data.division
          })
    },
    getRoles() {
      axios.get('api/auth/user/roles')
          .then(res => {
            console.log(res.data)
            this.roles = res.data.roles
          })
    },
    changeImg(event) {
      this.data = event.target.files[0]
      this.user.avatar = URL.createObjectURL(this.data)
    },
    storeUser() {
      console.log(this.data)
      const fd = new FormData()
      this.data != undefined ? fd.append('avatar', this.data) : ''
      fd.append('name', this.user.name)
      fd.append('email', this.user.email)
      fd.append('division_id', this.user.division)
      if (this.user.password != null) {
        fd.append('password', this.user.password)
      }
      if (this.user.role != null) {
        fd.append('role', this.user.role)
      }
      axios.post('api/auth/user/update/' + this.user.id, fd)
          .then(res => {
            console.log(res)
            if (res.status == 200) {
              this.alertText = 'Пользователь успешно обновлён';
              this.alertTextHide = false;
              setTimeout(this.hideAlert, 5000);
              this.alertDanger = false;
              this.alertSuccess = true;
            } else {
              this.alertText = 'Ошибка сохранения пользователя';
              this.alertTextHide = false;
              this.alertDanger = true;
              this.alertSuccess = false;
            }
          })
    },
    hideAlert() {
      this.alertTextHide = true;
    }
  },
  beforeMount() {
    this.getUser()
    this.getRoles()
  },
  mounted() {
    axios.get('api/auth/divisions')
        .then(res => {
          console.log(res.data)
          this.divisions = res.data
        })
  }
}
</script>

<style scoped>

</style>