<template>
    <div class="page-wrapper">
      <div class="page-inner align-items-center">
        <form
            @submit.prevent="goSearch"
            class="input-group input-group-lg mx-6 shadow-1 rounded"
        >
            <input
                @change="goSearch"
                v-model.number="search"
                type="text"
                class="form-control shadow-inset-2"
                id="filter-icon"
                aria-label="type 2 or more letters"
                placeholder="Печать стикера по штрих-коду"
                required
                minlength="3"
                ref="barcode"
                value
            />
            <div class="input-group-append">
              <button
                class="btn btn-primary waves-effect waves-themed"
                type="submit"
              >
                <i class="fal fa-search mr-2"></i>
                <span>Найти</span>
              </button>
            </div>
        </form>
      </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: "ManufactureSearch",
    data() {
        return {
          search: null,
        }
    },
    components: {

    },
    methods: {
        ...mapActions(['setManufactureProduct']),

        goSearch() {
          this.setManufactureProduct(this.search)
          this.$router.push('/mobile/manufacture/info')
        },
    },
    mounted() {
      this.$refs.barcode.focus();
    },
}
</script>

<style lang="scss" scoped>
    //
</style>
