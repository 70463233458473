<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: "Mobile",
        components: {
            //
        },
        data() {
            return {
                //
            }
        },
        mounted() {



        },
        methods: {

        }
    }
</script>

